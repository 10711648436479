import { TimeService } from '../shared/time.service';
var DateComponent = /** @class */ (function () {
    function DateComponent() {
    }
    DateComponent.prototype.getYear = function () {
        return TimeService.now().year();
    };
    DateComponent.prototype.getDate = function () {
        return TimeService.now().format('D. MMMM');
    };
    DateComponent.prototype.getWeekday = function () {
        return TimeService.now().format('dddd');
    };
    return DateComponent;
}());
export { DateComponent };
