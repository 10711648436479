import { Routes } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { IntroPageComponent } from './platforms/intro-page.component';
import { PlaylistPlayerComponent } from './player/playlist-player.component';
import { SyncLoaderPageComponent } from './sync/sync-loader-page.component.lite';
var appRoutes = [
    { path: 'init/:secret', component: IntroPageComponent },
    { path: 'sync', component: SyncLoaderPageComponent },
    { path: 'player', component: PlaylistPlayerComponent },
    { path: 'setup', redirectTo: 'sync' },
    { path: '', component: IntroPageComponent },
    { path: '*', redirectTo: '' },
];
var ɵ0 = Sentry.createErrorHandler();
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
