import { bufferCount, filter, map } from 'rxjs/operators';
import { OvenConnectionManagerService } from '../connect/oven-connection-manager.service';
var BakeEndConditionService = /** @class */ (function () {
    function BakeEndConditionService(ovenManager) {
        this.ovenManager = ovenManager;
    }
    BakeEndConditionService.prototype.isAssociatedCondition = function (condition) {
        return condition.deck_id !== undefined; // With deck_id it is a bake end condition.
    };
    BakeEndConditionService.prototype.getConditionMatch$ = function (condition) {
        return this.ovenManager.getDeckStatus$(condition.deck_id).pipe(bufferCount(2, 1), filter(function (deckStatus) { return BakeEndConditionService.checkDeck(condition, deckStatus[1], deckStatus[0]); }), map(function () { return undefined; }));
    };
    BakeEndConditionService.checkDeck = function (condition, deckStatus, lastDeckStatus) {
        // Program is correct and program number didn't change.
        return condition.programs.includes(deckStatus.program)
            && deckStatus.program === lastDeckStatus.program
            // remaining bake time >= offset before, and < offset now
            && lastDeckStatus.remainingBakingTime > condition.time_offset
            && condition.time_offset >= deckStatus.remainingBakingTime;
    };
    return BakeEndConditionService;
}());
export { BakeEndConditionService };
