
        <span style="display: inline-block; height: 100%; vertical-align: middle"></span>
        <span style="display: inline-block; vertical-align: middle">
            <span *ngIf="(status$ | async) === 'busy'">
                <span>FREI IN</span>
                <br>
                <span>
                    {{ timeMinutes$ | async | number:'2.0-0' }}:{{ (remainingTime | async) % 60 | number:'2.0-0' }}
                    <span class="unit">min</span>
                </span>
            </span>
            <span *ngIf="(status$ | async) === 'free'">JETZT FREI</span>
            <span *ngIf="(status$ | async) === 'connecting'">Verbinde...</span>
            <span *ngIf="(status$ | async) === 'connection_lost'">
                <i class="fal fa-exclamation-triangle"></i>
            </span>
        </span>
    