/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./day-weather-portrait.component.ngfactory";
import * as i2 from "./day-weather-portrait.component";
import * as i3 from "@angular/common";
import * as i4 from "./weather-forecast-landscape.component";
import * as i5 from "../sync/settings.service";
var styles_WeatherForecastLandscapeComponent = ["[_nghost-%COMP%] {\n            display: block;\n            height: inherit;\n        }"];
var RenderType_WeatherForecastLandscapeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_WeatherForecastLandscapeComponent, data: {} });
export { RenderType_WeatherForecastLandscapeComponent as RenderType_WeatherForecastLandscapeComponent };
function View_WeatherForecastLandscapeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "siq-day-weather-portrait", [], null, null, null, i1.View_DayWeatherPortraitComponent_0, i1.RenderType_DayWeatherPortraitComponent)), i0.ɵdid(2, 49152, null, 0, i2.DayWeatherPortraitComponent, [], { weatherData: [0, "weatherData"], shortTemperatureFormat: [1, "shortTemperatureFormat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.shortTemperatureFormat; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_WeatherForecastLandscapeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "table", [["style", "width: 100%; height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_WeatherForecastLandscapeComponent_1)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.days$)); var currVal_1 = _co.dayTrackByFn; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_WeatherForecastLandscapeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-weather-forecast-landscape", [], null, null, null, View_WeatherForecastLandscapeComponent_0, RenderType_WeatherForecastLandscapeComponent)), i0.ɵdid(1, 180224, null, 0, i4.WeatherForecastLandscapeComponent, [i5.SettingsService], null, null)], null, null); }
var WeatherForecastLandscapeComponentNgFactory = i0.ɵccf("siq-weather-forecast-landscape", i4.WeatherForecastLandscapeComponent, View_WeatherForecastLandscapeComponent_Host_0, { shortTemperatureFormat: "shortTemperatureFormat" }, {}, []);
export { WeatherForecastLandscapeComponentNgFactory as WeatherForecastLandscapeComponentNgFactory };
