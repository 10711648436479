/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../weather-forecast/weather-forecast-landscape.component.ngfactory";
import * as i3 from "../../weather-forecast/weather-forecast-landscape.component";
import * as i4 from "../../sync/settings.service";
import * as i5 from "../full-hd-iframe.component.ngfactory";
import * as i6 from "../full-hd-iframe.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../video-player.component.ngfactory";
import * as i9 from "../video-player.component";
import * as i10 from "../image-player.component.ngfactory";
import * as i11 from "../image-player.component";
import * as i12 from "./weather-news-landscape-layout.component";
import * as i13 from "../../sync/file-sync.service.base";
var styles_WeatherNewsLandscapeLayoutComponent = [];
var RenderType_WeatherNewsLandscapeLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WeatherNewsLandscapeLayoutComponent, data: {} });
export { RenderType_WeatherNewsLandscapeLayoutComponent as RenderType_WeatherNewsLandscapeLayoutComponent };
export function View_WeatherNewsLandscapeLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 1, "siq-weather-forecast-landscape", [["style", "position: absolute; left: 1458px; top: 0; width: 462px;"]], null, null, null, i2.View_WeatherForecastLandscapeComponent_0, i2.RenderType_WeatherForecastLandscapeComponent)), i0.ɵdid(9, 180224, null, 0, i3.WeatherForecastLandscapeComponent, [i4.SettingsService], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 9, "div", [["style", "width: 1920px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "siq-full-hd-iframe", [["style", "position: relative; float: left; width: 1458px; height: 820px"]], null, null, null, i5.View_FullHdIframeComponent_0, i5.RenderType_FullHdIframeComponent)), i0.ɵdid(12, 245760, null, 0, i6.FullHdIframeComponent, [i0.ElementRef, i7.DomSanitizer], { sourceUrl: [0, "sourceUrl"], reloadInterval: [1, "reloadInterval"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["style", "position: relative; float: left; width: 462px; height: 820px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_VideoPlayerComponent_0, i8.RenderType_VideoPlayerComponent)), i0.ɵdid(17, 49152, [[1, 4], ["videoPlayer", 4]], 0, i9.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(18, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ImagePlayerComponent_0, i10.RenderType_ImagePlayerComponent)), i0.ɵdid(19, 49152, [[2, 4], ["imagePlayer", 4]], 0, i11.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.styleTop$)); _ck(_v, 4, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.iframeUrl$)); var currVal_3 = i0.ɵunv(_v, 12, 1, i0.ɵnov(_v, 14).transform(_co.iframeReloadInterval$)); _ck(_v, 12, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.imageTop$)); _ck(_v, 6, 0, currVal_1); }); }
export function View_WeatherNewsLandscapeLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-weather-news-landscape-layout", [], null, null, null, View_WeatherNewsLandscapeLayoutComponent_0, RenderType_WeatherNewsLandscapeLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i12.WeatherNewsLandscapeLayoutComponent, [i4.SettingsService, i13.FileSyncServiceBase], null, null)], null, null); }
var WeatherNewsLandscapeLayoutComponentNgFactory = i0.ɵccf("siq-weather-news-landscape-layout", i12.WeatherNewsLandscapeLayoutComponent, View_WeatherNewsLandscapeLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { WeatherNewsLandscapeLayoutComponentNgFactory as WeatherNewsLandscapeLayoutComponentNgFactory };
