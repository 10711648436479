import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import { AbstractPlatform } from '../platforms/platform';
import { SettingKeys, SettingsService } from '../sync/settings.service';
import { ContentType } from './scheduler.service';
var logger = Logger.get('event-scheduler');
/**
 * The event scheduler service receives events and plays them on the local {@link EventPlayer}.
 * To send events use the {@link EventTriggerService}.
 */
var EventSchedulerService = /** @class */ (function () {
    function EventSchedulerService(platform, settings) {
        this.platform = platform;
        this.settings = settings;
    }
    EventSchedulerService.prototype.start = function (player) {
        if (this.player !== undefined) {
            logger.warn('Event scheduler started twice!');
            return;
        }
        this.player = player;
    };
    EventSchedulerService.prototype.stop = function () {
        if (this.player === undefined) {
            logger.warn('Event scheduler stopped without previous start()!');
            return;
        }
        this.player = undefined;
        if (this.currentSyncEvent !== undefined) {
            this.stopCurrentSyncEvent();
        }
    };
    /**
     * Play the provided event with the currently set {@link EventPlayer}.
     * If syncToken is provided we will wait for {@link syncEvent}
     * to be called before actually starting to play the event.
     */
    EventSchedulerService.prototype.playEvent = function (id, syncToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var playlistEntry, timeoutTimer;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug("event-scheduler:playEvent(" + id + ")");
                        if (this.player === undefined) {
                            logger.warn("Received event(" + id + ") before event scheduler was started.");
                            return [2 /*return*/];
                        }
                        // If we are currently waiting for synchronization of another event cancel waiting
                        if (this.currentSyncEvent !== undefined) {
                            this.stopCurrentSyncEvent();
                        }
                        playlistEntry = this.getPlaylistEntryFromEvent(id);
                        if (playlistEntry === undefined) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.player.prepareEvent(playlistEntry)];
                    case 1:
                        if (!(_a.sent())) {
                            return [2 /*return*/];
                        }
                        if (!(syncToken === undefined)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.player.playEvent(playlistEntry)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3:
                        timeoutTimer = setTimeout(function () {
                            if (_this.currentSyncEvent === undefined) {
                                logger.warn('Ignoring event timeout cause current sync event is missing.');
                                return;
                            }
                            logger.warn("Timeout while waiting for event sync from other screens. Playing event(" + id + ") now.");
                            _this.currentSyncEvent = undefined;
                            if (_this.player !== undefined) {
                                _this.player.playEvent(playlistEntry);
                            }
                        }, EventSchedulerService.EVENT_SYNC_TIMEOUT);
                        // Save the event infos until syncEvent() gets called.
                        this.currentSyncEvent = {
                            id: id,
                            syncToken: syncToken,
                            playlistEntry: playlistEntry,
                            timeoutTimer: timeoutTimer,
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Called by node-client when an event sync token is received.
     */
    EventSchedulerService.prototype.syncEvent = function (syncToken) {
        if (this.currentSyncEvent === undefined || this.player === undefined) {
            logger.debug("syncEvent(" + syncToken + ") called but we're not expecting any!");
            return;
        }
        if (this.currentSyncEvent.syncToken !== syncToken) {
            logger.warn("syncEvent(" + syncToken + ") called but we're expecting "
                + ("a different syncToken: " + this.currentSyncEvent.syncToken));
            return;
        }
        logger.debug('Calling playEvent() after receiving syncToken for event: ' + this.currentSyncEvent.id);
        this.player.playEvent(this.currentSyncEvent.playlistEntry);
        this.stopCurrentSyncEvent();
    };
    EventSchedulerService.prototype.playStream = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var playlistEntry;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.player === undefined) {
                            logger.warn("Received stream (" + url + ") before event scheduler was started.");
                            return [2 /*return*/];
                        }
                        playlistEntry = {
                            id: 0,
                            type: ContentType.STREAM,
                            source: url,
                        };
                        return [4 /*yield*/, this.player.prepareEvent(playlistEntry)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.player.playEvent(playlistEntry)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventSchedulerService.prototype.stopCurrentSyncEvent = function () {
        if (this.currentSyncEvent === undefined) {
            return;
        }
        clearTimeout(this.currentSyncEvent.timeoutTimer);
        this.currentSyncEvent = undefined;
    };
    EventSchedulerService.prototype.getPlaylistEntryFromEvent = function (eventId) {
        var event = this.settings.getEvent(eventId);
        if (event === undefined) {
            logger.warn('Unknown event: ' + eventId);
            return undefined;
        }
        var myScreenId = this.settings.get(SettingKeys.SCREEN_ID);
        var eventContent = event.contents.find(function (c) { return c.screen === myScreenId; });
        if (eventContent === undefined || eventContent.contents.length === 0) {
            logger.warn("Event(" + eventId + ") has no contents for screen(" + myScreenId + ").");
            return undefined;
        }
        var eventContentId = eventContent.contents[0].id;
        var content = this.settings.getContent(eventContentId);
        if (content === undefined) {
            logger.warn('Unknown content: ' + eventContentId);
            return undefined;
        }
        var source = this.settings.getContentFileURL(eventContentId);
        if (source === undefined) {
            logger.warn('Failed to generate file url for content: ' + eventContentId);
            return undefined;
        }
        return {
            id: content.id,
            type: content.type === 'picture' ? ContentType.PICTURE : ContentType.VIDEO,
            layout: this.getLayoutByLayoutId(eventContent.layout),
            source: source,
            period: eventContent.contents[0].period,
        };
    };
    EventSchedulerService.prototype.getLayoutByLayoutId = function (layoutId) {
        // no layout
        if (layoutId === 'default') {
            return {
                template: 'default',
            };
        }
        // on selected layout
        if (typeof layoutId === 'number') {
            // error case: layout is undefined
            // then it should be used: current layout
            return this.settings.getLayout(layoutId);
        }
        // on current layout = do nothing
        return undefined;
    };
    EventSchedulerService.EVENT_SYNC_TIMEOUT = 6 * 1000;
    return EventSchedulerService;
}());
export { EventSchedulerService };
