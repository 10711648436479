import * as tslib_1 from "tslib";
import { AbstractPlatform } from './platform';
var startTime = Date.now();
var WebPlatform = /** @class */ (function (_super) {
    tslib_1.__extends(WebPlatform, _super);
    function WebPlatform() {
        var _this = _super.call(this) || this;
        _this.isPanelEnabled = true;
        return _this;
    }
    WebPlatform.prototype.captureScreen = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new ArrayBuffer(0)];
            });
        });
    };
    WebPlatform.prototype.reboot = function () {
        return new Promise(function (resolve, reject) {
            try {
                window.location.reload();
                resolve();
            }
            catch (e) {
                reject(e);
            }
        });
    };
    Object.defineProperty(WebPlatform.prototype, "appUpdateUrl", {
        get: function () {
            return window.location.host;
        },
        enumerable: true,
        configurable: true
    });
    WebPlatform.prototype.getConnectedWifiSignalStrength = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getConnectedWifiSsid = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getCpuLoad = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getEthernetMacAddress = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getFirmwareVersion = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getIREnabled = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getInternalStorageAvailableCapacity = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getIpAddress = function () {
        return '';
    };
    WebPlatform.prototype.getMacAddress = function () {
        return Promise.resolve('');
    };
    WebPlatform.prototype.getPanelEnabled = function () {
        return Promise.resolve(this.isPanelEnabled);
    };
    WebPlatform.prototype.getPowerButtonEnabled = function () {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.getSerialNumber = function () {
        return Promise.resolve('');
    };
    WebPlatform.prototype.getSystemUptime = function () {
        return Date.now() - startTime;
    };
    WebPlatform.prototype.getWifiMacAddress = function () {
        return Promise.resolve(undefined);
    };
    Object.defineProperty(WebPlatform.prototype, "model", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    WebPlatform.prototype.setIREnabled = function (enabled) {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.setPanelEnabled = function (enabled) {
        this.isPanelEnabled = enabled;
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.setPowerButtonEnabled = function (enabled) {
        return Promise.resolve(undefined);
    };
    WebPlatform.prototype.startNodeServer = function (filename) {
        return Promise.resolve(undefined);
    };
    return WebPlatform;
}(AbstractPlatform));
export { WebPlatform };
