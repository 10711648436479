import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import * as Logger from 'js-logger';
import { AbstractPlatform } from '../platforms/platform';
import { SyncLoaderPageBaseComponent } from './sync-loader-page.component.base';
import { SyncServiceBase } from './sync.service.base';
var logger = Logger.get('sync-loader-page');
var SyncLoaderPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncLoaderPageComponent, _super);
    function SyncLoaderPageComponent(sync, router, platform) {
        var _this = _super.call(this, sync, router) || this;
        _this.platform = platform;
        return _this;
    }
    SyncLoaderPageComponent.prototype.routerNavigatePlayerOnSyncError = function () {
        logger.debug('Navigating immediately to player.');
        return _super.prototype.routerNavigatePlayerOnSyncError.call(this);
    };
    return SyncLoaderPageComponent;
}(SyncLoaderPageBaseComponent));
export { SyncLoaderPageComponent };
