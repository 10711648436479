/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../video-player.component.ngfactory";
import * as i2 from "../video-player.component";
import * as i3 from "../image-player.component.ngfactory";
import * as i4 from "../image-player.component";
import * as i5 from "./full-screen-layout.component";
import * as i6 from "../../sync/settings.service";
import * as i7 from "../../sync/file-sync.service.base";
var styles_FullScreenLayoutComponent = [];
var RenderType_FullScreenLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FullScreenLayoutComponent, data: {} });
export { RenderType_FullScreenLayoutComponent as RenderType_FullScreenLayoutComponent };
export function View_FullScreenLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VideoPlayerComponent_0, i1.RenderType_VideoPlayerComponent)), i0.ɵdid(3, 49152, [[1, 4], ["videoPlayer", 4]], 0, i2.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ImagePlayerComponent_0, i3.RenderType_ImagePlayerComponent)), i0.ɵdid(5, 49152, [[2, 4], ["imagePlayer", 4]], 0, i4.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" })], null, null); }
export function View_FullScreenLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-full-screen-layout", [], null, null, null, View_FullScreenLayoutComponent_0, RenderType_FullScreenLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i5.FullScreenLayoutComponent, [i6.SettingsService, i7.FileSyncServiceBase], null, null)], null, null); }
var FullScreenLayoutComponentNgFactory = i0.ɵccf("siq-full-screen-layout", i5.FullScreenLayoutComponent, View_FullScreenLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { FullScreenLayoutComponentNgFactory as FullScreenLayoutComponentNgFactory };
