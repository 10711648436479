import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import { BehaviorSubject, EMPTY, interval } from 'rxjs';
import { delay, filter, first, tap } from 'rxjs/operators';
import { TimeService } from './shared/time.service';
import { SettingKeys } from './sync/settings.service';
import { SyncConfigDownloadEvent, SyncEndEvent } from './sync/sync.service.base';
var BootstrapServiceBase = /** @class */ (function () {
    function BootstrapServiceBase(sync, settings, platform, controllerClient) {
        this.sync = sync;
        this.settings = settings;
        this.platform = platform;
        this.controllerClient = controllerClient;
        this.dhcpError$ = EMPTY;
        this.messageText$ = new BehaviorSubject(['Starte']);
        this.logger = Logger.get('bootstrap');
    }
    /**
     * Called before anything else after angular has finished booting.
     */
    BootstrapServiceBase.prototype.bootstrap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logger.debug('Executing bootstrap.service.base.ts:bootstrap()');
                        // load our secret
                        return [4 /*yield*/, this.sync.loadSecret()];
                    case 1:
                        // load our secret
                        _a.sent();
                        this.sync
                            .events
                            .pipe(filter(function (event) { return event instanceof SyncEndEvent; }), first())
                            .subscribe(this.onAfterFirstSync.bind(this));
                        this.sync
                            .events
                            .pipe(filter(function (event) { return event instanceof SyncConfigDownloadEvent; }))
                            .subscribe(this.onConfigDownloaded.bind(this));
                        this.scheduleReboot();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Called after the first time the sync is run (regardless of whether is succeeded or not).
     */
    BootstrapServiceBase.prototype.onAfterFirstSync = function () {
        this.logger.debug('Executing bootstrap.service.base.ts:onAfterFirstSync()');
        this.startControllerClient();
        return Promise.resolve();
    };
    /**
     * Called every time a new configuration from the server is received.
     */
    BootstrapServiceBase.prototype.onConfigDownloaded = function () {
        this.logger.debug('Executing bootstrap.service.base.ts:onConfigDownloaded()');
        this.startControllerClient();
        return Promise.resolve();
    };
    BootstrapServiceBase.prototype.startControllerClient = function () {
        var controllerClientEnabled = this.settings.get(SettingKeys.CONTROLLER_CLIENT_ENABLED, false);
        if (controllerClientEnabled && !this.controllerClient.isConnectionStarted) {
            this.controllerClient.connect();
        }
    };
    BootstrapServiceBase.prototype.scheduleReboot = function () {
        var _this = this;
        var rebootHour = 4; // Do reboot only from 4:00:00 until 4:59:59
        var checkInterval = 60 * 60 * 1000; // Spread reboot of multiple devices over the reboot hour
        var uptimeThreshold = function () {
            // Use positive infinity to prevent reboot if interval is missing in settings.
            var rebootInterval = _this.settings.get(SettingKeys.REBOOT_INTERVAL, Number.POSITIVE_INFINITY) * 1000;
            // Subtract check interval to be sure not to exceed reboot hour especially for daily reboot.
            return rebootInterval - checkInterval;
        };
        // Subtract some seconds from check interval to be sure
        // to match reboot hour with the not so steady time service.
        interval(checkInterval - 5000)
            .pipe(filter(function () { return TimeService.now().hours() === rebootHour; }), filter(function () { return _this.platform.getSystemUptime() >= uptimeThreshold(); }), first(), tap(function () { return _this.logger.info('Rebooting as scheduled.'); }), delay(5000))
            .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, this.platform.reboot()];
        }); }); });
    };
    return BootstrapServiceBase;
}());
export { BootstrapServiceBase };
