import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ElementRef, NgZone, OnInit } from '@angular/core';
import { parseString } from 'xml2js';
var NewsTickerComponent = /** @class */ (function () {
    function NewsTickerComponent(http, zone) {
        this.http = http;
        this.zone = zone;
    }
    NewsTickerComponent.prototype.ngOnInit = function () {
        this.fetchNews();
    };
    NewsTickerComponent.prototype.fetchNews = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, news, newsParsed, newsItems, newsString, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = 'https://www.welt.de/feeds/topnews.rss';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.http.get(url, { responseType: 'text' }).toPromise()];
                    case 2:
                        news = _a.sent();
                        return [4 /*yield*/, parseXml(news)];
                    case 3:
                        newsParsed = _a.sent();
                        newsItems = newsParsed.rss.channel[0].item;
                        newsString = newsItems.map(function (item) { return item.title[0]; }).join(' ⋅ ');
                        localStorage.setItem('news', newsString);
                        this.newsString = newsString;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        this.newsString = localStorage.getItem('news') || '';
                        return [3 /*break*/, 5];
                    case 5:
                        setTimeout(function () {
                            var element = _this.marqueeElementRef.nativeElement;
                            var seconds = Math.floor(element.offsetWidth / 100);
                            element.style.animation = "marquee " + seconds + "s linear infinite";
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    return NewsTickerComponent;
}());
export { NewsTickerComponent };
function parseXml(xml) {
    return new Promise(function (resolve, reject) {
        parseString(xml, function (err, result) {
            if (err) {
                reject(err);
            }
            resolve(result);
        });
    });
}
