import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { ConnectionCloseEvent, ConnectionOpenEvent, DataReceivedEvent, DeckStatus, OvenStatus, } from './oven-data-types';
var logger = Logger.get('wachtel-client');
var WachtelClient = /** @class */ (function () {
    function WachtelClient(host) {
        this.host = host;
        this.events = new Subject();
        this.closing = false;
        this.reconnectDelay = WachtelClient.RECONNECT_INITIAL_DELAY;
        this.messages = new Subject();
        this.closeEvents = new Subject();
        this.closeEvents.subscribe(this.onClose.bind(this));
    }
    WachtelClient.prototype.connect = function () {
        var _this = this;
        if (this.connectionTimeoutTimer !== undefined) {
            console.warn('Already connecting.');
            return;
        }
        this.connectionTimeoutTimer = setTimeout(function () {
            logger.debug("Timeout while connecting to " + _this.host + ". Closing connection.");
            _this.connectionTimeoutTimer = undefined;
            _this.forceClose();
        }, WachtelClient.CONNECTION_TIMEOUT);
        this.closing = false;
        this.socket = new WebSocket("ws://" + this.host + ":9080/liveTicker");
        this.socket.onmessage = this.messages.next.bind(this.messages);
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.closeEvents.next.bind(this.closeEvents);
    };
    WachtelClient.prototype.disconnect = function () {
        logger.debug('Disconnect called for host: ' + this.host);
        this.closing = true;
        clearTimeout(this.reconnectTimer);
        this.reconnectTimer = undefined;
        if (this.socket !== undefined) {
            this.socket.close();
            this.socket = undefined;
        }
    };
    WachtelClient.prototype.onOpen = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                clearTimeout(this.connectionTimeoutTimer);
                this.connectionTimeoutTimer = undefined;
                // Close connection when nothing received within timout.
                this.messages
                    .pipe(timeout(WachtelClient.RECEIVE_TIMEOUT), takeUntil(this.closeEvents))
                    .subscribe(this.onMessage.bind(this), function () {
                    logger.debug("Connection to " + _this.host + " timed out. Closing connection.");
                    _this.forceClose();
                });
                this.events.next(new ConnectionOpenEvent());
                return [2 /*return*/];
            });
        });
    };
    WachtelClient.prototype.onMessage = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var oven, _i, _a, wachtelDeck;
            return tslib_1.__generator(this, function (_b) {
                oven = new OvenStatus();
                for (_i = 0, _a = JSON.parse(e.data).Herde; _i < _a.length; _i++) {
                    wachtelDeck = _a[_i];
                    oven.decks.push(new DeckStatus(wachtelDeck.Backzeit, wachtelDeck.BackprogrammNummer));
                }
                this.events.next(new DataReceivedEvent(oven));
                return [2 /*return*/];
            });
        });
    };
    WachtelClient.prototype.onClose = function () {
        this.socket = undefined;
        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;
        this.events.next(new ConnectionCloseEvent());
        if (!this.closing) {
            this.reconnect();
        }
    };
    WachtelClient.prototype.forceClose = function () {
        if (this.socket !== undefined) {
            logger.debug('Forcing WebSocket close for host: ' + this.host);
            this.socket.onmessage = function () { return undefined; };
            this.socket.onopen = function () { return undefined; };
            this.socket.onclose = function () { return undefined; };
            this.socket.close();
        }
        this.closeEvents.next();
    };
    WachtelClient.prototype.reconnect = function () {
        var _this = this;
        if (this.reconnectTimer !== undefined) {
            logger.warn('Reconnect triggered while already reconnecting.');
            return;
        }
        this.reconnectTimer = setTimeout(function () {
            _this.reconnectTimer = undefined;
            _this.reconnectDelay = Math.min(WachtelClient.RECONNECT_MAX_DELAY, _this.reconnectDelay * 2);
            _this.connect();
        }, this.reconnectDelay);
    };
    WachtelClient.RECONNECT_MAX_DELAY = 60 * 1000;
    WachtelClient.RECONNECT_INITIAL_DELAY = 5 * 1000;
    WachtelClient.CONNECTION_TIMEOUT = 15 * 1000;
    WachtelClient.RECEIVE_TIMEOUT = 20 * 1000;
    return WachtelClient;
}());
export { WachtelClient };
