import * as tslib_1 from "tslib";
import { WeatherForecastBaseComponent } from './base-weather-forecast.component';
import { SettingsService } from '../sync/settings.service';
var WeatherForecastPortraitComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WeatherForecastPortraitComponent, _super);
    function WeatherForecastPortraitComponent(settings) {
        return _super.call(this, settings) || this;
    }
    return WeatherForecastPortraitComponent;
}(WeatherForecastBaseComponent));
export { WeatherForecastPortraitComponent };
