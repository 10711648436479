import * as tslib_1 from "tslib";
var ConnectionEvent = /** @class */ (function () {
    function ConnectionEvent() {
    }
    return ConnectionEvent;
}());
export { ConnectionEvent };
var ConnectionOpenEvent = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionOpenEvent, _super);
    function ConnectionOpenEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ConnectionOpenEvent;
}(ConnectionEvent));
export { ConnectionOpenEvent };
var ConnectionCloseEvent = /** @class */ (function (_super) {
    tslib_1.__extends(ConnectionCloseEvent, _super);
    function ConnectionCloseEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ConnectionCloseEvent;
}(ConnectionEvent));
export { ConnectionCloseEvent };
var DataReceivedEvent = /** @class */ (function () {
    function DataReceivedEvent(data) {
        this.data = data;
    }
    return DataReceivedEvent;
}());
export { DataReceivedEvent };
var DeckStatus = /** @class */ (function () {
    function DeckStatus(remainingBakingTime, // in seconds
    program) {
        this.remainingBakingTime = remainingBakingTime;
        this.program = program;
    }
    return DeckStatus;
}());
export { DeckStatus };
var OvenStatus = /** @class */ (function () {
    function OvenStatus() {
        this.decks = Array();
    }
    return OvenStatus;
}());
export { OvenStatus };
