import {Component} from '@angular/core';

import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

@Component({
    selector: 'siq-full-screen-layout',
    templateUrl: 'full-screen-layout.html',
})
export class FullScreenLayoutComponent extends BaseLayoutComponent {
    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}
