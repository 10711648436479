import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { combineLatest, merge, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, throttleTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EventSchedulerService } from '../player/event-scheduler.service';
import { NodeClientService } from '../shared/node-client-service';
import { SettingKeys, SettingsService } from '../sync/settings.service';
import { SyncEndEvent, SyncServiceBase } from '../sync/sync.service.base';
var logger = Logger.get('event-trigger');
export var SIQ_EVENT_CONDITION_PROVIDER = new InjectionToken('SiqConditionProvider');
var EventTriggerService = /** @class */ (function () {
    function EventTriggerService(httpClient, sync, settings, nodeClient, eventScheduler, conditionServices) {
        this.httpClient = httpClient;
        this.sync = sync;
        this.settings = settings;
        this.nodeClient = nodeClient;
        this.eventScheduler = eventScheduler;
        this.conditionServices = conditionServices;
        this.unsubscribeAllEvents = new Subject();
    }
    EventTriggerService.prototype.start = function () {
        var _this = this;
        this.sync
            .events
            .pipe(filter(function (event) { return event instanceof SyncEndEvent; }), map(function () { return (_this.sync.syncData === undefined ?
            { events: [] } :
            {
                settings: { screen_id: _this.sync.syncData.settings.screen_id },
                events: _this.sync.syncData.events,
                // TODO Move this change detection to OvenConnectionManagerService.
                oven_decks: _this.sync.syncData.oven_decks,
            }); }), distinctUntilChanged(_.isEqual))
            .subscribe(this.reload.bind(this));
    };
    EventTriggerService.prototype.triggerEventById = function (eventId) {
        var event = this.settings.getEvent(eventId);
        if (!event) {
            var message = "Event for event id " + eventId + " not found.";
            logger.warn(message);
            throw new Error(message);
        }
        this.triggerEvent(this.settings.get(SettingKeys.SCREEN_ID), event);
    };
    EventTriggerService.prototype.reload = function (syncData) {
        var _this = this;
        logger.debug('Reloading events.');
        this.unsubscribeAllEvents.next();
        var events = syncData.events.filter(function (e) { return e.screen === syncData.settings.screen_id; });
        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
            var event_1 = events_1[_i];
            // Get one observable for each condition that emits void when condition is matched.
            var matches$ = _(event_1.conditions)
                .map(function (condition) {
                return [
                    condition,
                    _this.conditionServices.find(function (service) { return service.isAssociatedCondition(condition); }),
                ];
            })
                // There may be unsupported conditions.
                .filter((function (_a) {
                var service = _a[1];
                return service !== undefined;
            }))
                .map(function (_a) {
                var condition = _a[0], service = _a[1];
                // Map the observable or the caught error object.
                return _.attempt(service.getConditionMatch$.bind(service), condition);
            })
                // Split into [Array<Error>, Array<Observable<void>>]
                .partition(_.isError)
                // Log the errors.
                .tap(function (_a) {
                var errors = _a[0];
                return errors.forEach(function (e) { return logger.warn(e.message); });
            })
                // Take the observables.
                .filter(function (group, index) { return index === 1; })
                .flatten()
                .value();
            merge.apply(void 0, matches$).pipe(takeUntil(this.unsubscribeAllEvents))
                .pipe(throttleTime(event_1.timeout * 1000)) // Discard trigger again until timeout is over.
                .subscribe(this.triggerEvent.bind(this, syncData.settings.screen_id, event_1));
        }
    };
    EventTriggerService.prototype.triggerEvent = function (ownScreenId, event) {
        var _this = this;
        logger.debug('Triggering event with id: ' + event.id);
        // No network needed if only playing on this screen.
        if (event.contents.length === 1 && event.contents[0].screen === ownScreenId) {
            logger.debug('Playing event locally. Id: ' + event.id);
            this.eventScheduler.playEvent(event.id).then();
            return;
        }
        var syncToken = ownScreenId + "-" + Math.floor(Math.random() * 1000 * 1000 * 1000);
        // Do some checks to be sure to have only valid ips.
        var ips = event.contents
            .map(function (content) {
            var screen = _this.sync.syncData.smart_screens.find(function (s) { return s.id === content.screen; });
            if (screen === undefined) {
                logger.warn('Found no screen with id: ' + content.screen);
                return undefined;
            }
            if (!screen.ipAddress || screen.ipAddress.length === 0) {
                logger.warn("IP of screen " + screen.name + " (id: " + screen.id + ") is missing.");
                return undefined;
            }
            return screen.ipAddress;
        })
            .filter(function (ip) { return ip !== undefined; });
        // No sync needed if only playing on one screen.
        if (ips.length === 1) {
            this.playEventOnScreen$(event.id, ips[0]);
            return;
        }
        // Get one observable for post-request to each screen.
        var screens = ips.map(function (ip) { return _this.playEventOnScreen$(event.id, ip, syncToken); });
        // Send sync broadcast when each screen has responded.
        combineLatest(screens).pipe(take(1)).subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.nodeClient.sendUdpBroadcast(environment.nodeServerPort, { play_event_sync: syncToken })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    EventTriggerService.prototype.playEventOnScreen$ = function (id, screenIp, syncToken) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa('shopiq:shopiq'),
        });
        var url = "http://" + screenIp + ":" + environment.nodeServerPort + "/api";
        logger.debug('Event post url: ' + url);
        // If we give screen a sync token, it will wait for syn broadcast before playing.
        var playEvent = { id: id };
        if (syncToken !== undefined) {
            playEvent.sync_token = syncToken;
        }
        return this.httpClient.post(url, JSON.stringify({ play_event: playEvent }), { headers: headers });
    };
    return EventTriggerService;
}());
export { EventTriggerService };
