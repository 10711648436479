import * as Logger from 'js-logger';
import {ILogLevel} from 'js-logger/src/types';

import {TimeService} from './time.service';

const handlers = new Map<string, LogHandler>();

export type LogHandler = (level: ILogLevel, name: string, messages: Array<string>) => any;

export function addLogHandler(identifier: string, handler: LogHandler): void {
    if (handlers.has(identifier)) {
        throw new Error(`Log handler for identifier ${identifier} already exists.`);
    }

    handlers.set(identifier, handler);
}

export function removeLogHandler(identifier: string): void {
    handlers.delete(identifier);
}

export function setupLogging(level: ILogLevel = Logger.INFO): void {
    if (handlers.size === 0) {
        handlers.set('console', consoleLogHandler);
    }

    Logger.setLevel(level);
    Logger.setHandler((messages, context) => {
        const name = context.name || 'global';
        const messagesArray = Array.from(messages);

        for (const handler of Array.from(handlers.values())) {
            try {
                handler(context.level, name, messagesArray);
            } catch (e) {
                console.warn('Error in log handler: ' + e.toString(), e);
            }
        }
    });
}

function consoleLogHandler(level: ILogLevel, name: string, messages: Array<string>): void {
    const defaultHandler = Logger.createDefaultHandler({
        formatter: (messagesArray: Array<any>, context: any) => {
            const now = TimeService.nowMillis(false);
            const timezoneOffset = (new Date(now)).getTimezoneOffset() * 60_000;
            const timePart = `[${(new Date(now - timezoneOffset)).toISOString().slice(0, -1)}]`;
            const moduleName = context.name || 'global';
            const modulePart = `[${moduleName}]`;
            messagesArray.unshift(timePart, modulePart, context.level.name, '-');
        },
    });

    defaultHandler(messages, {level, name});
}
