import * as tslib_1 from "tslib";
import { WeatherForecastBaseComponent } from './base-weather-forecast.component';
import { SettingsService } from '../sync/settings.service';
var WeatherForecastLandscapeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WeatherForecastLandscapeComponent, _super);
    function WeatherForecastLandscapeComponent(settings) {
        return _super.call(this, settings) || this;
    }
    return WeatherForecastLandscapeComponent;
}(WeatherForecastBaseComponent));
export { WeatherForecastLandscapeComponent };
