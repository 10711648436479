
        <img [src]="imageBackground$ | async">

        <div class="right-bar">
            <div class="carwash" [style.background-color]="carwashBgColor$ | async">
                <span style="display: inline-block; height: 100%; vertical-align: middle"></span>
                <span style="display: inline-block; vertical-align: middle">
                    <span>WASCHANLAGE</span>
                    <br>
                    <span>{{ carwashMessage$ | async }}</span>
                </span>
            </div>
            <img style="height: 333px;"
                 [src]="contentRight$ | async" [style.display]="(contentRightIsVideo$ | async) ? 'none' : 'block'">
            <!-- If both video elements touch, a strip flickers in between.
                 So we need 2px bottom margin on first video element. -->
            <video style="height: 333px; margin-bottom: 2px;"
                   autoplay loop
                   [src]="contentRight$ | async"
                   [style.display]="(contentRightIsVideo$ | async) ? 'block' : 'none'">
            </video>
            <div style="position: relative; height: 333px;">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
            <div style="height: 234px;">
                <siq-weather-forecast-landscape [ngStyle]="weatherStyle$ | async"
                                                [shortTemperatureFormat]="true">
                </siq-weather-forecast-landscape>
            </div>
        </div>
    