/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./news-ticker.component";
import * as i2 from "@angular/common/http";
var styles_NewsTickerComponent = ["[_nghost-%COMP%] {\n            display: block;\n            position: relative;\n            height: 100%;\n            overflow: hidden;\n            white-space: nowrap;\n        }\n\n        [_nghost-%COMP%]   span[_ngcontent-%COMP%] {\n            display: inline-block;\n            position: absolute;\n            left: 100%;\n            color: black;\n            line-height: 60px;\n            text-indent: 0;\n\n            color: black;\n            font-size: 24px;\n        }\n\n        @keyframes marquee {\n            0%   { transform: translate(0, 0);  }\n            100% { transform: translate(-100%, 0);  }\n        }"];
var RenderType_NewsTickerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_NewsTickerComponent, data: {} });
export { RenderType_NewsTickerComponent as RenderType_NewsTickerComponent };
export function View_NewsTickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { marqueeElementRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["marquee", 1]], null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newsString; _ck(_v, 2, 0, currVal_0); }); }
export function View_NewsTickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-news-ticker", [], null, null, null, View_NewsTickerComponent_0, RenderType_NewsTickerComponent)), i0.ɵdid(1, 114688, null, 0, i1.NewsTickerComponent, [i2.HttpClient, i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsTickerComponentNgFactory = i0.ɵccf("siq-news-ticker", i1.NewsTickerComponent, View_NewsTickerComponent_Host_0, {}, {}, []);
export { NewsTickerComponentNgFactory as NewsTickerComponentNgFactory };
