/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../video-player.component.ngfactory";
import * as i3 from "../video-player.component";
import * as i4 from "../image-player.component.ngfactory";
import * as i5 from "../image-player.component";
import * as i6 from "./hella-access-control-layout.component";
import * as i7 from "../../sync/settings.service";
import * as i8 from "../../sync/file-sync.service.base";
import * as i9 from "../../connect/hella-aps.service";
var styles_HellaAccessControlLayoutComponent = [".detailed-count[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 25px;\n            left: 30px;\n            text-align: left;\n            color: white;\n            font-size: 50px;\n        }\n\n        .allowed-people-left[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 425px;\n            left: 0;\n            width: 251px;\n            text-align: right;\n            vertical-align: middle;\n            color: white;\n            font-size: 70px;\n            font-weight: bold;\n        }"];
var RenderType_HellaAccessControlLayoutComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_HellaAccessControlLayoutComponent, data: {} });
export { RenderType_HellaAccessControlLayoutComponent as RenderType_HellaAccessControlLayoutComponent };
function View_HellaAccessControlLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "detailed-count"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " von ", " + ", " "])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.peopleCount$)); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.parameters$))) == null) ? null : tmp_1_0.people_count_legal); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.parameters$))) == null) ? null : tmp_2_0.employee_count); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HellaAccessControlLayoutComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "allowed-people-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.allowedPeopleLeft$)); _ck(_v, 1, 0, currVal_0); }); }
export function View_HellaAccessControlLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["style", "position: absolute; left: 0; top: 0; width: 1080px; height: 1920px;"]], [[8, "src", 4]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_HellaAccessControlLayoutComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_HellaAccessControlLayoutComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["style", "position: absolute; left: 0; top: 1312px; width: 1080px; height: 608px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VideoPlayerComponent_0, i2.RenderType_VideoPlayerComponent)), i0.ɵdid(12, 49152, [[1, 4], ["videoPlayer", 4]], 0, i3.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(13, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ImagePlayerComponent_0, i4.RenderType_ImagePlayerComponent)), i0.ɵdid(14, 49152, [[2, 4], ["imagePlayer", 4]], 0, i5.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" })], function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.parameters$))) == null) ? null : tmp_1_0.show_detailed_count); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.go$)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.imageSrc$)); _ck(_v, 2, 0, currVal_0); }); }
export function View_HellaAccessControlLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-hella-access-control-layout", [], null, null, null, View_HellaAccessControlLayoutComponent_0, RenderType_HellaAccessControlLayoutComponent)), i0.ɵdid(1, 245760, null, 0, i6.HellaAccessControlLayoutComponent, [i7.SettingsService, i8.FileSyncServiceBase, i9.HellaApsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HellaAccessControlLayoutComponentNgFactory = i0.ɵccf("siq-hella-access-control-layout", i6.HellaAccessControlLayoutComponent, View_HellaAccessControlLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { HellaAccessControlLayoutComponentNgFactory as HellaAccessControlLayoutComponentNgFactory };
