import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, delay, map, timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';

const logger = Logger.get('time');

export abstract class TimeServiceBase {
    protected static readonly HTTP_TIME_SYNC_RETRY_DELAY = 60_000;

    protected readonly getTimeByHttp$ = createGetTimeByHttp(this.http);

    protected constructor(protected http: HttpClient) {
    }
}

export function sleep(millis: number): Promise<void> {
    return of(undefined).pipe(delay(millis)).toPromise();
}

function createGetTimeByHttp(http: HttpClient): Observable<moment.Moment> {
    return http.get(`${getHttpSyncUrl()}/api/time`, {
        observe: 'response',
        responseType: 'text',
    }).pipe(
        map(response => [response.headers.get('Date'), response.statusText]),
        // Http error response is also useful if date header is set.
        catchError((response: HttpErrorResponse) => {
            // Try to get a better error message on android.
            const stack = _.get(response, 'error.originalStack');
            const status = response.statusText === 'Unknown Error' && typeof stack === 'string'
                ? stack.split('\n', 1)[0]
                : response.statusText;

            return of([response.headers.get('Date'), status]);
        }),
        timeout(30_000),
        catchError(() => of([undefined, 'Timeout'])),
        map(([headerDate, status]) => {
            if (!headerDate) {
                const message = `Failed to get time by http: ${status}`;
                logger.warn(message);
                throw new Error(message);
            }

            return moment(headerDate);
        }),
    );
}

function getHttpSyncUrl(): string {
    if (!environment.serverAddress.startsWith('https://')) {
        return environment.serverAddress;
    }

    return `http://${environment.serverAddress.substr(8)}`;
}
