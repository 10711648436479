import {Injectable} from '@angular/core';
import {AbstractPlatform} from './platform';

const startTime = Date.now();

@Injectable()
export class WebPlatform extends AbstractPlatform {

    private isPanelEnabled = true;

    constructor() {
        super();
    }

    async captureScreen(): Promise<ArrayBuffer> {
        return new ArrayBuffer(0);
    }

    reboot(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                window.location.reload();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    get appUpdateUrl(): string | undefined {
        return window.location.host;
    }

    getConnectedWifiSignalStrength(): Promise<number | undefined> {
        return Promise.resolve(undefined);
    }

    getConnectedWifiSsid(): Promise<string | undefined> {
        return Promise.resolve(undefined);
    }

    getCpuLoad(): Promise<number | undefined> {
        return Promise.resolve(undefined);
    }

    getEthernetMacAddress(): Promise<string | undefined> {
        return Promise.resolve(undefined);
    }

    getFirmwareVersion(): Promise<string | undefined> {
        return Promise.resolve(undefined);
    }

    getIREnabled(): Promise<boolean | undefined> {
        return Promise.resolve(undefined);
    }

    getInternalStorageAvailableCapacity(): Promise<number | undefined> {
        return Promise.resolve(undefined);
    }

    getIpAddress(): string {
        return '';
    }

    getMacAddress(): Promise<string> {
        return Promise.resolve('');
    }

    getPanelEnabled(): Promise<boolean> {
        return Promise.resolve(this.isPanelEnabled);
    }

    getPowerButtonEnabled(): Promise<boolean | undefined> {
        return Promise.resolve(undefined);
    }

    getSerialNumber(): Promise<string> {
        return Promise.resolve('');
    }

    getSystemUptime(): number {
        return Date.now() - startTime;
    }

    getWifiMacAddress(): Promise<string | undefined> {
        return Promise.resolve(undefined);
    }

    get model(): string {
        return '';
    }

    setIREnabled(enabled: boolean): Promise<void> {
        return Promise.resolve(undefined);
    }

    setPanelEnabled(enabled: boolean): Promise<void> {
        this.isPanelEnabled = enabled;
        return Promise.resolve(undefined);
    }

    setPowerButtonEnabled(enabled: boolean): Promise<void> {
        return Promise.resolve(undefined);
    }

    startNodeServer(filename: string): Promise<void> {
        return Promise.resolve(undefined);
    }
}
