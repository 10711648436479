import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { SettingsService } from '../sync/settings.service';
import { TimeService } from './time.service';
var ActionService = /** @class */ (function () {
    function ActionService(settings) {
        this.settings = settings;
    }
    ActionService.prototype.getActions = function (atTime) {
        if (atTime === void 0) { atTime = TimeService.now(); }
        return this.settings.getActions().map(function (action) {
            var mappedAction = tslib_1.__assign({}, action);
            mappedAction.dateStart = moment(action.dateStart.substr(0, 10), 'YYYY-MM-DD');
            mappedAction.dateEnd = moment(action.dateEnd.substr(0, 10), 'YYYY-MM-DD');
            mappedAction.timeStart = parseTime(action.timeStart, atTime);
            mappedAction.timeEnd = parseTime(action.timeEnd, atTime);
            return mappedAction;
        });
    };
    return ActionService;
}());
export { ActionService };
function parseTime(timeStr, atTime) {
    if (atTime === void 0) { atTime = TimeService.now(); }
    var timeParts = timeStr.substr(11, 5).split(':'); // ['13', '00']
    return atTime.clone().set({ hour: parseInt(timeParts[0], 10), minute: parseInt(timeParts[1], 10), second: 0 });
}
