/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./day-weather-portrait.component";
var styles_DayWeatherPortraitComponent = ["[_nghost-%COMP%] {\n            display: block;\n            text-align: center;\n            font-size: 20px;\n        }\n\n        .fal[_ngcontent-%COMP%] {\n            font-size: 70px;\n            line-height: 120%;\n        }"];
var RenderType_DayWeatherPortraitComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DayWeatherPortraitComponent, data: {} });
export { RenderType_DayWeatherPortraitComponent as RenderType_DayWeatherPortraitComponent };
function View_DayWeatherPortraitComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\u00B0C/", "\u00B0C "])), i0.ɵppd(2, 2), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.weatherData.maximum_temperature, "1.0-0")); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.weatherData.minimum_temperature, "1.0-0")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_DayWeatherPortraitComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\u00B0C / ", " \u00B0C "])), i0.ɵppd(2, 2), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.weatherData.maximum_temperature, "1.0-1")); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.weatherData.minimum_temperature, "1.0-1")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_DayWeatherPortraitComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [["class", "fal"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 2), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DayWeatherPortraitComponent_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DayWeatherPortraitComponent_2)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fal"; var currVal_1 = _co.weatherData.icon; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.shortTemperatureFormat; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.shortTemperatureFormat; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), _co.weatherData.date, "EEEEEE dd.MM.")); _ck(_v, 7, 0, currVal_2); }); }
export function View_DayWeatherPortraitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-day-weather-portrait", [], null, null, null, View_DayWeatherPortraitComponent_0, RenderType_DayWeatherPortraitComponent)), i0.ɵdid(1, 49152, null, 0, i2.DayWeatherPortraitComponent, [], null, null)], null, null); }
var DayWeatherPortraitComponentNgFactory = i0.ɵccf("siq-day-weather-portrait", i2.DayWeatherPortraitComponent, View_DayWeatherPortraitComponent_Host_0, { weatherData: "weatherData", shortTemperatureFormat: "shortTemperatureFormat" }, {}, []);
export { DayWeatherPortraitComponentNgFactory as DayWeatherPortraitComponentNgFactory };
