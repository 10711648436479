import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import {combineLatest, Observable, Subject, timer} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, skip, startWith, takeUntil, tap} from 'rxjs/operators';

import {ActionService} from '../shared/action.service';
import {TimeService} from '../shared/time.service';
import {LayoutEntity, SettingsService} from '../sync/settings.service';
import {SyncEndEvent, SyncServiceBase} from '../sync/sync.service.base';

const logger = Logger.get('scheduler');

interface DefaultLayout {
    template: 'default';
}

export type Layout = LayoutEntity | DefaultLayout;

export interface Playlist extends Array<PlaylistEntry> {}

export interface PlaylistEntry {
    id: number;
    type: ContentType;
    source: string;
    period?: number;
    layout?: Layout;
}

export enum ContentType {
    PICTURE,
    VIDEO,
    STREAM,
}

/**
 * SchedulerService.
 *
 * - Must be started using start() first
 * - Manual update can be triggered using updatePlaylist() after started
 *
 * The playlist will be updated on:
 * - every sync
 * - every 60s, only if the current playlist is empty
 */
@Injectable()
export class SchedulerServiceBase {
    protected readonly playlist$: Observable<Playlist>;
    protected readonly updateTrigger$ = new Subject<void>();
    protected playlist?: Playlist;

    constructor(protected settings: SettingsService,
                protected sync: SyncServiceBase,
                protected actionService: ActionService) {

        const afterSyncEvents$ = this.sync.events
            .pipe(filter(event => event instanceof SyncEndEvent));

        this.playlist$ = combineLatest([
            this.updateTrigger$.pipe(startWith(0)),
            afterSyncEvents$.pipe(startWith(0)),
        ]).pipe(
            map(() => this.generatePlaylist()),
            distinctUntilChanged(_.isEqual),
            tap((playlist: Playlist) => {
                this.playlist = playlist;
                if (playlist.length === 0) {
                    this.startUpdateTimer();
                }

                // Lock content files of current playlist to prevent deletion during playing.
                this.sync.lockedContents = playlist.map(entry => entry.id);
            }),
            shareReplay({bufferSize: 1, refCount: true}),
        );
    }

    getPlaylist$(): Observable<Playlist> {
        return this.playlist$;
    }

    getPlaylist(): Playlist | undefined {
        return this.playlist;
    }

    triggerPlaylistUpdate(): void {
        this.updateTrigger$.next();
    }

    protected startUpdateTimer(): void {
        // Start a timer observable that triggers at the start of each minute until the playlist is not empty anymore
        const timeToNextMinute = (60 - TimeService.now().seconds()) * 1000;
        timer(timeToNextMinute, 60 * 1000).pipe(
            map(() => undefined),
            takeUntil(this.playlist$.pipe(
                // Always skip the first value, because this is either the value cached by shareReplay()
                // or the empty playlist that called this method in the first place (from the tap() operator).
                // The cached value is the one which would lead to premature exit for this timer
                // even if the real current playlist is still empty.
                skip(1),
                filter(playlist => playlist.length > 0),
            )),
        ).subscribe(this.updateTrigger$.next.bind(this.updateTrigger$));
    }

    /**
     * @param atTime The time to use as "current time".
     * @param savedTimeMode When true holiday calendar, weekdays and current time is ignored.
     * @param ignoreHolidays When true holiday calendar is ignored.
     */
    protected generatePlaylist(
        atTime: moment.Moment = TimeService.now(),
        savedTimeMode: boolean = false,
        ignoreHolidays: boolean = false,
    ): Playlist {
        // Check whether today is a holiday and how it has to be handled
        if (!savedTimeMode && !ignoreHolidays) {
            const holidays = this.settings.getHolidays();
            const holidayAction = getHolidayActionForDay(holidays, atTime);
            if (holidayAction === 'OFF') {
                return [];
            } else if (holidayAction === 'LIKE_SUNDAY') {
                // Use calendar from previous sunday
                return this.generatePlaylist(
                    atTime.clone().subtract(7, 'day').isoWeekday(7),
                    false,
                    true,
                );
            } else if (holidayAction === 'NONE') {
                // Just continue like normal
            }
        }

        const actions = this.actionService.getActions(atTime);
        const playlist: Playlist = [];
        let minPriority = 0;

        // Helper function
        const addActionToPlaylist = (action: any): void => {
            minPriority = action.priority;

            for (const content of action.parameters.contents) {
                const contentDb = this.settings.getContent(content.id);
                if (contentDb === undefined) {
                    logger.warn(`No db content found for content ${content.id} in action ${action.id}`);
                    continue;
                }

                const type = contentDb.type === 'picture' ? ContentType.PICTURE : ContentType.VIDEO;
                const source = this.settings.getContentFileURL(content.id);
                if (source === undefined) {
                    logger.warn('Failed to generate file url for content: ' + content.id);
                    continue;
                }

                const layoutId: number = content.layout || action.parameters.layout;

                playlist.push({
                    type,
                    source,
                    id: content.id,
                    period: content.period,
                    layout: this.settings.getLayout(layoutId) || {template: 'default'},
                });
            }
        };

        for (const action of actions) {
            // Exit if we have reached the lower priorities
            if (action.priority < minPriority) {
                break;
            }

            // Handle actions crossing midnight correctly
            if (action.timeEnd.isBefore(action.timeStart)) {
                const prevDay = atTime.clone().subtract(1, 'day');

                if (checkActionDayConditions(action, atTime, savedTimeMode)
                    && (savedTimeMode || atTime.isSameOrAfter(action.timeStart))) {
                    addActionToPlaylist(action);
                } else if (checkActionDayConditions(action, prevDay, savedTimeMode)
                    && (savedTimeMode || atTime.isSameOrBefore(action.timeEnd))) {
                    addActionToPlaylist(action);
                }

                continue;
            }

            const timeIsBetween = atTime.isBetween(action.timeStart, action.timeEnd, undefined, '[]');
            if (checkActionDayConditions(action, atTime, savedTimeMode) && (savedTimeMode || timeIsBetween)) {
                addActionToPlaylist(action);
            }
        }

        return playlist;
    }
}

function getHolidayActionForDay(holidays: Array<any>, day: moment.Moment): string | undefined {
    const dateString = day.format('DD.MM.YYYY');
    const holidaysFiltered = holidays.filter(value => value.dates.indexOf(dateString) >= 0);
    if (holidaysFiltered.length === 0) {
        return undefined;
    }

    const holiday = holidaysFiltered[0];
    if (holiday.dates_details[dateString] && holiday.dates_details[dateString].marketing) {
        return holiday.dates_details[dateString].marketing;
    } else {
        return 'OFF';
    }
}

function checkActionDayConditions(action: any, day: moment.Moment, savedTimeMode: boolean = false): boolean {
    if (savedTimeMode) {
        return checkActionDate(action, day);
    }

    return _.every(
        [checkActionWeekRepeat, checkActionDate, checkActionWeekday],
        fn => fn(action, day),
    );
}

function checkActionWeekRepeat(action: any, day: moment.Moment): boolean {
    if (!Number.isInteger(action.weekRepeat) || action.weekRepeat <= 1) {
        return true;
    }

    const startWeek = action.dateStart.clone().startOf('isoWeek');
    const dayWeek = day.clone().startOf('isoWeek');
    return dayWeek.diff(startWeek, 'week') % action.weekRepeat === 0;
}

function checkActionDate(action: any, day: moment.Moment): boolean {
    return day.isBetween(action.dateStart, action.dateEnd, 'day', '[]');
}

function checkActionWeekday(action: any, day: moment.Moment): boolean {
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return action['day' + weekdays[day.isoWeekday() - 1]];
}
