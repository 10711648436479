import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import {combineLatest, merge, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, take, takeUntil, throttleTime} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {EventSchedulerService} from '../player/event-scheduler.service';
import {NodeClientService} from '../shared/node-client-service';
import {SettingKeys, SettingsService} from '../sync/settings.service';
import {SyncData, SyncEndEvent, SyncServiceBase} from '../sync/sync.service.base';
import {ConditionService} from './types';

const logger = Logger.get('event-trigger');

export const SIQ_EVENT_CONDITION_PROVIDER = new InjectionToken('SiqConditionProvider');

@Injectable()
export class EventTriggerService {
    private unsubscribeAllEvents = new Subject<void>();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly sync: SyncServiceBase,
        private readonly settings: SettingsService,
        private readonly nodeClient: NodeClientService,
        private readonly eventScheduler: EventSchedulerService,
        @Inject(SIQ_EVENT_CONDITION_PROVIDER) private readonly conditionServices: Array<ConditionService>,
    ) {}

    start(): void {
        this.sync
            .events
            .pipe(
                filter(event => event instanceof SyncEndEvent),
                map(() => (
                    this.sync.syncData === undefined ?
                        {events: []} :
                        {
                            settings: {screen_id: this.sync.syncData.settings.screen_id},
                            events: this.sync.syncData.events,
                            // TODO Move this change detection to OvenConnectionManagerService.
                            oven_decks: this.sync.syncData.oven_decks,
                        }
                )),
                distinctUntilChanged(_.isEqual),
            )
            .subscribe(this.reload.bind(this));
    }

    triggerEventById(eventId: number): void {
        const event = this.settings.getEvent(eventId);
        if (!event) {
            const message = `Event for event id ${eventId} not found.`;
            logger.warn(message);
            throw new Error(message);
        }

        this.triggerEvent(this.settings.get(SettingKeys.SCREEN_ID), event);
    }

    private reload(syncData: SyncData): void {
        logger.debug('Reloading events.');
        this.unsubscribeAllEvents.next();

        const events = syncData.events.filter(e => e.screen === syncData.settings.screen_id);
        for (const event of events) {
            // Get one observable for each condition that emits void when condition is matched.
            const matches$: Array<Observable<void>> = _(event.conditions)
                .map((condition): [any, ConditionService | undefined] =>
                    [
                        condition,
                        this.conditionServices.find(service => service.isAssociatedCondition(condition)),
                    ],
                )
                // There may be unsupported conditions.
                .filter((([, service]) => service !== undefined) as
                    (a: [any, ConditionService | undefined]) => a is [any, ConditionService])
                .map(([condition, service]) =>
                    // Map the observable or the caught error object.
                    _.attempt(service.getConditionMatch$.bind(service), condition),
                )
                // Split into [Array<Error>, Array<Observable<void>>]
                .partition(_.isError)
                // Log the errors.
                .tap(([errors]) => errors.forEach(e => logger.warn((e as Error).message)))
                // Take the observables.
                .filter((group, index): group is Array<Observable<void>> => index === 1)
                .flatten()
                .value();

            merge(...matches$)
                .pipe(takeUntil(this.unsubscribeAllEvents))
                .pipe(throttleTime(event.timeout * 1000))  // Discard trigger again until timeout is over.
                .subscribe(this.triggerEvent.bind(this, syncData.settings.screen_id, event));
        }
    }

    private triggerEvent(ownScreenId: number, event: any): void {
        logger.debug('Triggering event with id: ' + event.id);

        // No network needed if only playing on this screen.
        if (event.contents.length === 1 && event.contents[0].screen === ownScreenId) {
            logger.debug('Playing event locally. Id: ' + event.id);
            this.eventScheduler.playEvent(event.id).then();
            return;
        }

        const syncToken = `${ownScreenId}-${Math.floor(Math.random() * 1000 * 1000 * 1000)}`;

        // Do some checks to be sure to have only valid ips.
        const ips = (event.contents as Array<any>)
            .map((content): string | undefined => {
                const screen = (this.sync.syncData as SyncData).smart_screens.find(s => s.id === content.screen);
                if (screen === undefined) {
                    logger.warn('Found no screen with id: ' + content.screen);
                    return undefined;
                }
                if (!screen.ipAddress || screen.ipAddress.length === 0) {
                    logger.warn(`IP of screen ${screen.name} (id: ${screen.id}) is missing.`);
                    return undefined;
                }
                return screen.ipAddress;
            })
            .filter((ip): ip is string => ip !== undefined);

        // No sync needed if only playing on one screen.
        if (ips.length === 1) {
            this.playEventOnScreen$(event.id, ips[0]);
            return;
        }

        // Get one observable for post-request to each screen.
        const screens = ips.map(ip => this.playEventOnScreen$(event.id, ip, syncToken));

        // Send sync broadcast when each screen has responded.
        combineLatest(screens).pipe(take(1)).subscribe(async () => {
            await this.nodeClient.sendUdpBroadcast(environment.nodeServerPort, {play_event_sync: syncToken});
        });
    }

    private playEventOnScreen$(id: number, screenIp: string, syncToken?: string): Observable<Object> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa('shopiq:shopiq'),
        });

        const url = `http://${screenIp}:${environment.nodeServerPort}/api`;
        logger.debug('Event post url: ' + url);

        // If we give screen a sync token, it will wait for syn broadcast before playing.
        const playEvent = {id};
        if (syncToken !== undefined) {
            (playEvent as any).sync_token = syncToken;
        }

        return this.httpClient.post(
            url,
            JSON.stringify({play_event: playEvent}),
            {headers},
        );
    }
}
