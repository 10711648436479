import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Logger from 'js-logger';
import {combineLatest, concat, Observable, Subject} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    first,
    last,
    map,
    pairwise,
    pluck,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
import {HellaApsService} from '../../connect/hella-aps.service';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

const logger = Logger.get('hella-access-control-layout');

interface Parameters {
    people_count_legal: number;
    employee_count?: number;

    // hidden parameter for debug purpose
    show_detailed_count?: boolean;
}

@Component({
    selector: 'siq-hella-access-control-layout',
    styles: [`
        .detailed-count {
            position: absolute;
            top: 25px;
            left: 30px;
            text-align: left;
            color: white;
            font-size: 50px;
        }

        .allowed-people-left {
            position: absolute;
            top: 425px;
            left: 0;
            width: 251px;
            text-align: right;
            vertical-align: middle;
            color: white;
            font-size: 70px;
            font-weight: bold;
        }
    `],
    // language=Angular2HTML
    template: `
        <img style="position: absolute; left: 0; top: 0; width: 1080px; height: 1920px;" [src]="imageSrc$ | async">

        <span *ngIf="(parameters$ | async)?.show_detailed_count" class="detailed-count">
            {{ peopleCount$ | async }}
            von {{ (parameters$|async)?.people_count_legal }} + {{ (parameters$|async)?.employee_count }}
        </span>

        <span *ngIf="go$ | async" class="allowed-people-left">
            {{ allowedPeopleLeft$ | async }}
        </span>

        <div style="position: absolute; left: 0; top: 1312px; width: 1080px; height: 608px;">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>
    `,
})
export class HellaAccessControlLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
    readonly parameters$!: Observable<Parameters>;
    readonly imageStop$ = this.getLayoutImage$('image_stop');
    readonly imageGo$ = this.getLayoutImage$('image_go');

    readonly peopleCount$ = this.hellaAps.peopleCount$;
    readonly allowedPeopleLeft$ = createAllowedPeopleLeft$(this.peopleCount$, this.parameters$);
    readonly go$ = this.allowedPeopleLeft$.pipe(map(allowedPeopleLeft => (allowedPeopleLeft || 0) > 0));
    readonly imageSrc$ = createImageSrc$(this.go$, this.imageStop$, this.imageGo$);

    private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
    private readonly audio = new Audio();
    private audioPlaying = false;

    constructor(settings: SettingsService,
                fileSync: FileSyncServiceBase,
                private hellaAps: HellaApsService) {
        super(settings, fileSync);
    }

    ngOnInit(): void {
        this.peopleCount$.pipe(first()).subscribe(count =>
            logger.info(`Starting with people count ${count}.`),
        );
        this.peopleCount$.pipe(takeUntil(this.ngUnsubscribe), last()).subscribe(count =>
            logger.info(`Ending with people count ${count}.`),
        );

        // Play stop sound when traffic light switches to stop.
        this.go$.pipe(
            distinctUntilChanged(),
            filter(go => !go && !this.audioPlaying),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(() => this.playAudio('assets/stop.mp3'));

        // Play alarm when next person enters if limit is already reached.
        this.peopleCount$.pipe(
            pairwise(),
            filter((counts): counts is [number, number] => !counts.includes(undefined)),
            filter(([oldCount, actCount]) => actCount > oldCount),
            pluck('1'),
            withLatestFrom(this.parameters$),
            filter(([count, parameters]) =>
                count > parameters.people_count_legal + (parameters.employee_count || 0),
            ),
            filter(() => !this.audioPlaying),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(
            // Duration of alarm.mp3 is about 0.85s. Play it 17 times to get alarm duration nearly 15s.
            () => this.playAudio('assets/alarm.mp3', 17),
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    playAudio(src: string, repeat: number = 1): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.audioPlaying) {
                reject();
            }
            let endedCount = 0;
            this.audioPlaying = true;

            this.audio.src = src;
            this.audio.onended = () => {
                endedCount += 1;
                if (endedCount < repeat) {
                    this.audio.play();
                    return;
                }
                this.audioPlaying = false;
                resolve();
            };

            this.audio.load();
            this.audio.play();
        });
    }
}

function createAllowedPeopleLeft$(peopleCount$: Observable<number | undefined>,
                                  parameters$: Observable<Parameters>): Observable<number | undefined> {
    return combineLatest([
        peopleCount$,
        parameters$],
    ).pipe(
        map(([peopleCount, parameters]) => {
            if (peopleCount === undefined) {
                return undefined;
            }
            const visitorCount = Math.max(0, peopleCount - (parameters.employee_count || 0));
            return Math.max(0, parameters.people_count_legal - visitorCount);
        }),
    );
}

function createImageSrc$(go$: Observable<boolean>,
                         imageStop$: Observable<string>,
                         imageGo$: Observable<string>): Observable<string> {
    return concat(
        imageStop$.pipe(first()),
        combineLatest([go$, imageStop$, imageGo$]).pipe(
            map(([go, imageStop, imageGo]) => go ? imageGo : imageStop),
        ),
    );
}
