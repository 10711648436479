import * as tslib_1 from "tslib";
import { SchedulerServiceBase } from './scheduler.service.base';
export { ContentType } from './scheduler.service.base';
var SchedulerService = /** @class */ (function (_super) {
    tslib_1.__extends(SchedulerService, _super);
    function SchedulerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SchedulerService;
}(SchedulerServiceBase));
export { SchedulerService };
