import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';
import {Subject, timer} from 'rxjs';
import {first} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {NodeClientService} from '../shared/node-client-service';
import {SettingKeys, SettingsService} from '../sync/settings.service';

const logger = Logger.get('playlist-sync');

class ScreenSyncCounter {
    count = 0;
    lastSync = 0;

    incrementCount(): ScreenSyncCounter {
        this.count++;
        this.lastSync = window.performance.now();
        return this;
    }
}

interface SyncWaitInfos {
    broadcastInterval: any;
    resolve(): void;
    reject(message: string): void;
}

@Injectable()
export class PlaylistSyncService {
    receivedMissingScreen = new Subject<number>();
    private screenSyncCounters = new Map<number, ScreenSyncCounter>();
    private missingScreens: Array<number> = [];

    /** holds promise callbacks (resolve, reject) for current waitForScreenGroup() call */
    private currentSyncWaitInfos?: SyncWaitInfos;

    constructor(private settings: SettingsService,
                private nodeClient: NodeClientService) {
        this.nodeClient.playlistSyncEvent.subscribe(this.onSyncBroadcastReceived.bind(this));
    }

    waitForScreenGroup(): Promise<void> {
        if (this.currentSyncWaitInfos !== undefined) {
            this.currentSyncWaitInfos.reject('Wait called during wait.');
            clearInterval(this.currentSyncWaitInfos.broadcastInterval);
            this.currentSyncWaitInfos = undefined;
        }

        // Get own screen id and screen ids of screens to sync with
        const ownScreenId = this.settings.get<number>(SettingKeys.SCREEN_ID);
        const syncScreenIds = this.settings.get(SettingKeys.PLAYLIST_SYNCHRONIZATION_SCREENS, []);
        if (ownScreenId === undefined || syncScreenIds.length === 0) {
            logger.debug('Not member of sync screen group.');
            return Promise.resolve();
        }

        // discard outdated sync-messages
        this.removeTimedOutSyncScreens(syncScreenIds, 7);

        return new Promise<void>(async (resolve, reject) => {
            this.currentSyncWaitInfos = {
                resolve,
                reject,
                broadcastInterval: setInterval(this.sendBroadcast.bind(this, ownScreenId), 5_000),
            };

            await timer(100).pipe(first()).toPromise();
            await this.sendBroadcast(ownScreenId);
        });
    }

    cancelWaitForScreenGroup(reason: string = ''): void {
        if (this.currentSyncWaitInfos === undefined) {
            return;
        }

        this.currentSyncWaitInfos.reject(reason);
        clearInterval(this.currentSyncWaitInfos.broadcastInterval);
        this.currentSyncWaitInfos = undefined;
    }

    /**
     * Called every time we receive a playlist synchronization broadcast from another or our *own* screen.
     * We handle our own screen the same way as remote screens
     * to avoid timing problems that might come with special handling of our own screen.
     */
    private onSyncBroadcastReceived(screenId: number): void {
        const syncScreenIds = this.settings.get<Array<number>>(SettingKeys.PLAYLIST_SYNCHRONIZATION_SCREENS, []);
        if (!syncScreenIds.includes(screenId)) {
            // There can be multiple synchronization groups in the same network,
            // not all broadcasts we receive are relevant for us
            return;
        }

        logger.debug('Received sync broadcast from: ' + screenId);

        // Remove screens from missingScreens that got removed from our sync group
        this.missingScreens = setIntersect(this.missingScreens, syncScreenIds);

        // if we received a broadcast from a missing screen it's no longer missing
        // and we notify the player about it
        if (this.missingScreens.includes(screenId)) {
            this.missingScreens = this.missingScreens.filter(id => id !== screenId);
            this.receivedMissingScreen.next(screenId);
        }

        // Get or create a screen sync counter and increment its value
        const currentScreenSyncCounter = (this.screenSyncCounters.get(screenId)
            || new ScreenSyncCounter()).incrementCount();
        this.screenSyncCounters.set(screenId, currentScreenSyncCounter);

        // We are done now if we are not waiting for our screen group
        if (this.currentSyncWaitInfos === undefined) {
            return;
        }

        // If we have received a sync broadcast from each "active" screen
        // we can resolve the wait and continue playback
        const activeScreens = setSubtract(syncScreenIds, this.missingScreens);
        if (setSubtract(activeScreens, Array.from(this.screenSyncCounters.keys())).length === 0) {
            if (this.missingScreens.length === 0) {
                logger.debug('Received sync from all screens in group.');
            } else {
                logger.debug('Received sync from all active screens in group, missing screens: ' + this.missingScreens);
            }

            this.resolve(this.currentSyncWaitInfos);
            return;
        }

        // At this point we have not yet received a broadcast from each "active" screen
        // The only way that we can now resolve the wait if there is a screen which we haven't yet marked as missing

        // We only want to mark new screens as missing if we have received at least 2 broadcasts
        if (currentScreenSyncCounter.count < 2) {
            return;
        }

        // We only want to mark new screens as missing if there aren't other screens who are only behind by one
        // Either these screens will catch up or they won't catch up
        // and be marked as missing the next time we end up here
        const screensAvailableThatCouldCatchUp = Array
            .from(this.screenSyncCounters.values())
            .some(counter => counter.count === currentScreenSyncCounter.count - 1);
        if (screensAvailableThatCouldCatchUp) {
            return;
        }

        // Update the missingScreens = allScreens - onlineScreens
        // onlineScreen = screens that have at least the same count as the one we just received
        const onlineScreenIds = Array
            .from(this.screenSyncCounters.entries())
            .filter(([id, screen]) => screen.count >= currentScreenSyncCounter.count)
            .map(([id]) => id);
        this.missingScreens = setSubtract(syncScreenIds, onlineScreenIds);

        logger.info('Received sync not from all screens in group, missing screens: ' + this.missingScreens);
        this.resolve(this.currentSyncWaitInfos);
    }

    private async sendBroadcast(screenId: number): Promise<void> {
        try {
            await this.nodeClient.sendUdpBroadcast(
                environment.nodeServerPort,
                {playlist_sync: {screen_id: screenId}},
            );
        } catch (e) {
            logger.debug('Failed so send sync broadcast: ' + e.message);

            // Wait for screen group may have been canceled while awaiting send broadcast.
            if (this.currentSyncWaitInfos === undefined) {
                return;
            }

            // If we can't even send our own broadcast we just start playing ... no sense in waiting
            this.resolve(this.currentSyncWaitInfos);
        }
    }

    private resolve(currentSyncWaitInfos: SyncWaitInfos): void {
        currentSyncWaitInfos.resolve();
        this.screenSyncCounters.clear();
        clearInterval(currentSyncWaitInfos.broadcastInterval);
        this.currentSyncWaitInfos = undefined;
    }

    private removeTimedOutSyncScreens(syncScreenIds: Array<number>, timeout: number): void {
        // We use window.performance here because we need a always monotonic clock
        const now = window.performance.now();
        const screens = Array.from(this.screenSyncCounters.entries());

        // Clear the counters and only add back those that we received in the last timeout seconds
        this.screenSyncCounters.clear();
        for (const [id, screen] of screens) {
            if (!syncScreenIds.includes(id) || now - screen.lastSync > timeout * 1000) {
                continue;
            }

            screen.count = 1;
            this.screenSyncCounters.set(id, screen);
        }
    }
}

// returns a - b
function setSubtract<T>(a: Array<T>, b: Array<T>): Array<T> {
    return a.filter(x => !b.includes(x));
}

// returns a ∩ b
function setIntersect<T>(a: Array<T>, b: Array<T>): Array<T> {
    return a.filter(x => b.includes(x));
}
