/**
 * This file is also used for tizen and web player `test-prod` build, but not for android.
 */
export const environment = {
    production: true,
    release: '${RELEASE}',
    serverAddress: 'https://www.app.shop-iq.eu',
    controllerAddress: 'wss://ssc.shop-iq.eu/ws',
    magicInfoServerAddress: 'ssc.shop-iq.eu',
    currentYear: 2024,

    nodeServerExternalHost: undefined,
    nodeServerPort: 26517,
    nodeServerWsInternalPath: '/aixaixah0Yaengaeyoh8oop6Echo7ieSeifeeph2Keit0sha',

    androidUpdateUrl: 'https://shopiq-android-update.s3.eu-central-1.amazonaws.com/',

    hawkbitServerUrl: undefined,
    hawkbitClientCert: undefined,
};
