import * as tslib_1 from "tslib";
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { concat, from, merge, of, Subject } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, map, retryWhen, share, shareReplay, startWith, switchMap, timeout, } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileSyncError, FileSyncErrorType, mapFileSyncProgressToFormattedProgress, } from './file-sync.service.base';
export var ProgressAction;
(function (ProgressAction) {
    ProgressAction[ProgressAction["DOWNLOAD_CONFIG"] = 0] = "DOWNLOAD_CONFIG";
    ProgressAction[ProgressAction["DOWNLOAD_CONTENTS"] = 1] = "DOWNLOAD_CONTENTS";
    ProgressAction[ProgressAction["DOWNLOAD_LAYOUTS"] = 2] = "DOWNLOAD_LAYOUTS";
    ProgressAction[ProgressAction["DOWNLOAD_HTML_TEMPLATE_ASSETS"] = 3] = "DOWNLOAD_HTML_TEMPLATE_ASSETS";
})(ProgressAction || (ProgressAction = {}));
export var SyncErrorType;
(function (SyncErrorType) {
    SyncErrorType[SyncErrorType["DOWNLOAD_CONFIG_DENIED"] = 0] = "DOWNLOAD_CONFIG_DENIED";
    SyncErrorType[SyncErrorType["DOWNLOAD_CONFIG_CLIENT"] = 1] = "DOWNLOAD_CONFIG_CLIENT";
    SyncErrorType[SyncErrorType["DOWNLOAD_CONFIG_SERVER"] = 2] = "DOWNLOAD_CONFIG_SERVER";
    SyncErrorType[SyncErrorType["DOWNLOAD_CONFIG_GENERIC"] = 3] = "DOWNLOAD_CONFIG_GENERIC";
    SyncErrorType[SyncErrorType["DOWNLOAD_FILES"] = 4] = "DOWNLOAD_FILES";
    SyncErrorType[SyncErrorType["GENERIC"] = 5] = "GENERIC";
})(SyncErrorType || (SyncErrorType = {}));
var SyncEvent = /** @class */ (function () {
    function SyncEvent() {
    }
    return SyncEvent;
}());
export { SyncEvent };
var SyncStartEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncStartEvent, _super);
    function SyncStartEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SyncStartEvent;
}(SyncEvent));
export { SyncStartEvent };
var SyncEndEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncEndEvent, _super);
    function SyncEndEvent(success) {
        if (success === void 0) { success = true; }
        var _this = _super.call(this) || this;
        _this.success = success;
        return _this;
    }
    return SyncEndEvent;
}(SyncEvent));
export { SyncEndEvent };
var SyncConfigDownloadEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncConfigDownloadEvent, _super);
    function SyncConfigDownloadEvent(syncData, modified, initial) {
        var _this = _super.call(this) || this;
        _this.syncData = syncData;
        _this.modified = modified;
        _this.initial = initial;
        return _this;
    }
    return SyncConfigDownloadEvent;
}(SyncEvent));
export { SyncConfigDownloadEvent };
var SyncProgressEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncProgressEvent, _super);
    function SyncProgressEvent(action, formattedProgress) {
        var _this = _super.call(this) || this;
        _this.action = action;
        _this.formattedProgress = formattedProgress;
        return _this;
    }
    Object.defineProperty(SyncProgressEvent.prototype, "formattedAction", {
        get: function () {
            switch (this.action) {
                case ProgressAction.DOWNLOAD_CONFIG:
                    return 'Verbinde zum Shop-IQ Server';
                case ProgressAction.DOWNLOAD_CONTENTS:
                    return 'Herunterladen des Contents';
                case ProgressAction.DOWNLOAD_LAYOUTS:
                    return 'Herunterladen der Layouts';
                case ProgressAction.DOWNLOAD_HTML_TEMPLATE_ASSETS:
                    return 'Herunterladen der HTML Vorlagen';
                default:
                    return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    return SyncProgressEvent;
}(SyncEvent));
export { SyncProgressEvent };
var SyncErrorEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SyncErrorEvent, _super);
    function SyncErrorEvent(type, message, extra) {
        var _this = _super.call(this) || this;
        _this.type = type;
        _this.message = message;
        _this.extra = extra;
        return _this;
    }
    return SyncErrorEvent;
}(SyncEvent));
export { SyncErrorEvent };
var FileSyncErrorEvent = /** @class */ (function (_super) {
    tslib_1.__extends(FileSyncErrorEvent, _super);
    function FileSyncErrorEvent(fileSyncErrorType, message, extra) {
        var _this = _super.call(this, SyncErrorType.DOWNLOAD_FILES, message, extra) || this;
        _this.fileSyncErrorType = fileSyncErrorType;
        _this.message = message;
        _this.extra = extra;
        return _this;
    }
    return FileSyncErrorEvent;
}(SyncErrorEvent));
export { FileSyncErrorEvent };
/**
 * The standard sync workflow is as following:
 *
 * - We request the device configuration from the server
 *   + Authentication uses a single secret provided once on device setup from the server
 */
var SyncServiceBase = /** @class */ (function () {
    function SyncServiceBase(http, router, fileSync, platform) {
        var _this = this;
        this.http = http;
        this.router = router;
        this.fileSync = fileSync;
        this.platform = platform;
        this.events = new Subject();
        this.logger = Logger.get('sync');
        this._isSyncing = false;
        // Load the last stored config and remember it
        this.loadConfig().then(function (config) { return _this.lastStoredConfig = config; });
        // Also store the config after a successful config download
        this.events.pipe(filter(function (event) {
            return event instanceof SyncConfigDownloadEvent && event.modified;
        })).subscribe(function (event) { return _this.storeConfig(event.syncData); });
        this.events.subscribe(function (event) {
            if (event instanceof SyncErrorEvent) {
                _this.logger.warn("SyncErrorEvent: " + SyncErrorType[event.type] + ": " + event.message);
            }
            else if (event instanceof SyncConfigDownloadEvent) {
                // Logging the entire config sometimes blocks forever on android.
                var syncData = event.syncData, e = tslib_1.__rest(event, ["syncData"]);
                _this.logger.debug('SyncEvent: ' + event.constructor.name, e);
            }
            else {
                _this.logger.debug('SyncEvent: ' + event.constructor.name, event);
            }
        });
    }
    Object.defineProperty(SyncServiceBase.prototype, "syncData", {
        // TODO: Remove when refactor is finished
        get: function () {
            if (this.lastSuccessfulConfigDownloadResponse) {
                return this.lastSuccessfulConfigDownloadResponse.body;
            }
            if (this.lastStoredConfig) {
                return this.lastStoredConfig;
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SyncServiceBase.prototype, "isSyncing", {
        get: function () {
            return this._isSyncing;
        },
        enumerable: true,
        configurable: true
    });
    SyncServiceBase.prototype.start = function () {
        var _this = this;
        if (this.isSyncing) {
            this.logger.warn('Sync already running, exiting.');
            return undefined;
        }
        // Stop any scheduled syncs
        clearTimeout(this.scheduleTimer);
        this.scheduleTimer = undefined;
        // Create the whole sync pipeline / workflow here
        // Sync always starts with downloading the config
        var downloadConfig$ = this.downloadConfig().pipe(share());
        // Download files is triggered by a successful config download
        var downloadFiles$ = downloadConfig$.pipe(filter(function (event) { return event instanceof SyncConfigDownloadEvent; }), switchMap(function (event) { return _this.downloadFiles(event); }));
        var pipeline$ = merge(downloadConfig$, downloadFiles$).pipe(finalize(function () {
            _this._isSyncing = false;
            _this.scheduleTimer = setTimeout(_this.start.bind(_this), SyncServiceBase.SYNC_INTERVAL);
        }), 
        // As we subscribe internally, we need replay to ensure that external subscribers get all events.
        shareReplay());
        // Subscribe and redirect to events (but only next, not complete or error)
        this._isSyncing = true;
        this.events.next(new SyncStartEvent());
        pipeline$.subscribe({
            next: function (event) { return _this.events.next(event); },
            complete: function () { return _this.events.next(new SyncEndEvent()); },
            error: function (error) {
                if (error instanceof SyncErrorEvent) {
                    _this.events.next(error);
                }
                else {
                    _this.events.next(new SyncErrorEvent(SyncErrorType.GENERIC, error.toString(), error));
                }
                _this.events.next(new SyncEndEvent(false));
            },
        });
        return pipeline$;
    };
    Object.defineProperty(SyncServiceBase.prototype, "lockedContents", {
        set: function (ids) {
            this.fileSync.lockedContents = ids;
        },
        enumerable: true,
        configurable: true
    });
    SyncServiceBase.prototype.hasSecret = function () {
        return this.secret !== undefined;
    };
    SyncServiceBase.prototype.setSecret = function (secret) {
        this.secret = secret;
        return this.storeSecret();
    };
    SyncServiceBase.prototype.downloadConfig = function () {
        var _this = this;
        var url = environment.serverAddress + '/api/smartscreen/sync';
        var headers = new HttpHeaders({ 'If-None-Match': this.getLastConfigHash() || '' });
        var http$ = from(this.prepareSyncRequestBody()).pipe(switchMap(function (body) { return _this.http.post(url, body, {
            params: { secret: _this.secret },
            observe: 'response',
            headers: headers,
        }); }));
        return http$.pipe(timeout(60000), catchError(function (response) {
            if (response.status === 304) {
                // server returned "not modified" response so we just continue using our last saved response
                return of(new SyncConfigDownloadEvent(_this.lastSuccessfulConfigDownloadResponse.body, false, false));
            }
            if (response.status === 403) {
                // Access denied (403) gets its own error type because it needs special handling
                // (normally we return to the setup screen when this happens)
                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_DENIED);
            }
            // Everything else (e.g. server error 500, ...) gets rethrown (and retried)
            throw response;
        }), map(function (response) {
            // If we previously converted a 304 to a sync event we just pass it through
            if (response instanceof SyncConfigDownloadEvent) {
                return response;
            }
            // We only continue without error if the response is a HTTP 200
            // and the body is an object (parsed from json)
            if (response.status !== 200) {
                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_SERVER, response.status + ": " + response.statusText);
            }
            if (!(response.body instanceof Object)) {
                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_SERVER, 'Config download response is invalid JSON.');
            }
            var isInitialConfigDownload = _this.lastSuccessfulConfigDownloadResponse === undefined;
            _this.lastSuccessfulConfigDownloadResponse = response;
            return new SyncConfigDownloadEvent(response.body, true, isInitialConfigDownload);
        }), retryWhen(function (errors) { return errors.pipe(filter(function (error, index) {
            if (index >= 2 // Try it up to 3 times.
                || _this.lastSuccessfulConfigDownloadResponse === undefined // no retry to speedup app startup
                || (error instanceof SyncErrorEvent
                    && error.type === SyncErrorType.DOWNLOAD_CONFIG_DENIED) // no retry on access denied
            ) {
                throw error;
            }
            return true;
        }), concatMap(function (error, index) {
            var delayTime = index === 0 ? 30000 : 120000;
            return of(error).pipe(delay(delayTime + ((Math.random() - 0.5) * delayTime)));
        })); }), 
        // Convert any remaining errors to SyncErrorEvents
        catchError(function (error) {
            if (error instanceof SyncErrorEvent) {
                throw error;
            }
            // If it's a error response and the status isn't set or 0 it's a client side error
            if (error instanceof HttpErrorResponse && (!error.status || error.status === 0)) {
                if (error.status === 0 && error.statusText === 'Unknown Error') {
                    // for net::ERR_CONNECTION_REFUSED,net::ERR_NAME_RESOLUTION_FAILED etc.
                    throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_CLIENT, 'Network Error', error.error);
                }
                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_CLIENT, error.statusText, error.error);
            }
            // If it's a error response and we have a status code it's a server error
            if (error instanceof HttpErrorResponse && error.status) {
                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_SERVER, error.statusText, error);
            }
            throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_GENERIC, error.toString(), error);
        }), startWith(new SyncProgressEvent(ProgressAction.DOWNLOAD_CONFIG, '')));
    };
    SyncServiceBase.prototype.prepareSyncRequestBody = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deviceInfoPromises, deviceInformation, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        deviceInfoPromises = this.getDeviceInfoPairs().map(function (_a) {
                            var key = _a[0], apiFunc = _a[1];
                            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _b, e_1;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _c.trys.push([0, 2, , 3]);
                                            _b = [key];
                                            return [4 /*yield*/, Promise.resolve(apiFunc())];
                                        case 1: return [2 /*return*/, _b.concat([_c.sent()])];
                                        case 2:
                                            e_1 = _c.sent();
                                            this.logger.debug("Failed to get " + key + " for device info: " + e_1.message);
                                            return [2 /*return*/, undefined];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        _a = _;
                        return [4 /*yield*/, Promise.all(deviceInfoPromises)];
                    case 1:
                        deviceInformation = _a.apply(void 0, [_b.sent()])
                            .filter(function (item) { return item !== undefined; })
                            // Replace undefined values by null as undefined is removed on serialization
                            // tslint:disable-next-line:no-null-keyword
                            .map(function (_a) {
                            var key = _a[0], value = _a[1];
                            return [key, value !== undefined ? value : null];
                        })
                            .fromPairs()
                            .value();
                        return [2 /*return*/, { device_information: deviceInformation }];
                }
            });
        });
    };
    SyncServiceBase.prototype.getDeviceInfoPairs = function () {
        var _this = this;
        return [
            ['mac_address', this.platform.getMacAddress.bind(this.platform)],
            ['model', function () { return _this.platform.model; }],
            ['app_update_url', function () { return _this.platform.appUpdateUrl; }],
            ['app_version', _.constant(environment.release)],
            ['ip_address', this.platform.getIpAddress.bind(this.platform)],
            ['connected_wifi_ssid', this.platform.getConnectedWifiSsid.bind(this.platform)],
            ['connected_wifi_signal_strength', this.platform.getConnectedWifiSignalStrength.bind(this.platform)],
            [
                'internal_storage_available_capacity',
                this.platform.getInternalStorageAvailableCapacity.bind(this.platform),
            ],
        ];
    };
    SyncServiceBase.prototype.downloadFiles = function (event) {
        var downloadLayoutFiles$ = this.fileSync.syncLayoutFiles(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_LAYOUTS));
        var downloadContentFiles$ = this.fileSync.syncContentFiles(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_CONTENTS));
        var downloadHtmlTemplateAssetFiles$ = this.fileSync.syncHtmlTemplateAssets(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_HTML_TEMPLATE_ASSETS));
        return concat(downloadLayoutFiles$, downloadContentFiles$, downloadHtmlTemplateAssetFiles$)
            .pipe(catchError(this.fileSyncErrorHandler));
    };
    SyncServiceBase.prototype.mapFileSyncProgressToSyncEventsOperator = function (action) {
        return function (source) {
            return source.pipe(mapFileSyncProgressToFormattedProgress(), startWith(''), map(function (formattedProgress) { return new SyncProgressEvent(action, formattedProgress); }));
        };
    };
    // Method is not static to allow overriding in subclasses
    SyncServiceBase.prototype.fileSyncErrorHandler = function (error) {
        if (error instanceof Error) {
            Sentry.captureException(error);
        }
        if (error instanceof SyncErrorEvent) {
            if (error.extra instanceof Error) {
                Sentry.captureException(error.extra);
            }
            throw error;
        }
        if (error instanceof FileSyncError) {
            throw new FileSyncErrorEvent(error.type, error.message, error.extra);
        }
        throw new FileSyncErrorEvent(FileSyncErrorType.GENERIC, error.message || error.toString(), error);
    };
    SyncServiceBase.prototype.getLastConfigHash = function () {
        if (this.lastSuccessfulConfigDownloadResponse === undefined) {
            return undefined;
        }
        var hash = this.lastSuccessfulConfigDownloadResponse.headers.get('etag');
        if (!hash) {
            return undefined;
        }
        if (hash.startsWith('W/')) {
            hash = hash.substring(2);
        }
        if (hash.startsWith('"')) {
            hash = hash.substring(1, hash.length - 1);
        }
        return hash;
    };
    SyncServiceBase.SYNC_INTERVAL = 10 * 60 * 1000;
    return SyncServiceBase;
}());
export { SyncServiceBase };
