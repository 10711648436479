import {EventEmitter, Input, Output, ViewChild} from '@angular/core';
import * as Logger from 'js-logger';
import {ObservableInput} from 'observable-input/lib';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {LayoutEntity, SettingKeys, SettingsService} from '../../sync/settings.service';
import {BaseVideoPlayerComponent} from '../base-video-player.component';
import {ImagePlayerComponent} from '../image-player.component';
import {ContentType, PlaylistEntry} from '../scheduler.service';

const logger = Logger.get('base-layout');

export abstract class BaseLayoutComponent {
    private static readonly MISSING_TAG_MESSAGE = 'Video or image player are undefined. ' +
        'Are they tagged with #videoPlayer and #imagePlayer?';

    @Input() @ObservableInput() layout!: Observable<LayoutEntity>;
    @Output() readonly completed: EventEmitter<any> = new EventEmitter();
    @ViewChild('videoPlayer', {static: false}) video?: BaseVideoPlayerComponent;
    @ViewChild('imagePlayer', {static: false}) image?: ImagePlayerComponent;

    protected readonly parameters$ = this.layout.pipe(map(layout => layout.parameters));

    // Emits every time next playlist entry is played so that the layout image cache buster takes effect
    private parametersRefreshTrigger = new Subject<void>();

    protected constructor(protected settings: SettingsService, protected fileSync: FileSyncServiceBase) {}

    async play(element: PlaylistEntry): Promise<boolean> {
        if (this.video === undefined || this.image === undefined) {
            throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
        }

        this.parametersRefreshTrigger.next();

        if (element.type === ContentType.VIDEO || element.type === ContentType.STREAM) {
            // Start playing before hiding image to avoid last frame of last video is displayed again.
            // (especially on tizen with video pre-buffering)
            const playSuccess = await this.video.play();
            this.image.stop();
            return playSuccess;
        } else {
            await this.image.play();
            // Stop video explicitly to avoid last frame to be displayed when next video is started.
            // (especially on tizen without video pre-buffering)
            this.video.stop();
            return true;
        }
    }

    async prepare(nextElement: PlaylistEntry): Promise<boolean> {
        if (this.video === undefined || this.image === undefined) {
            throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
        }

        if (nextElement.type === ContentType.VIDEO || nextElement.type === ContentType.STREAM) {
            return this.video.prepare(nextElement.source);
        } else {
            this.image.prepare(nextElement);
            return true;
        }
    }

    stop(): void {
        logger.debug('Stopping layout.');

        if (this.video === undefined || this.image === undefined) {
            throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
        }

        this.video.stop();
        this.image.stop();
    }

    protected getLayoutImage$(key: string): Observable<string> {
        return combineLatest([this.parameters$, this.parametersRefreshTrigger]).pipe(
            map(([parameters]) => {
                const customerId = this.settings.get(SettingKeys.CUSTOMER_ID);
                const name = parameters[key];
                const nameWithoutCustomerId = name.replace(new RegExp(`^${customerId}/`), '');

                return `${this.fileSync.getLayoutFileURL(nameWithoutCustomerId)}`;
            }),
        );
    }
}
