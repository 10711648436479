import * as tslib_1 from "tslib";
import { ObservableInput } from 'observable-input/lib';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
var CarwashTimeComponent = /** @class */ (function () {
    function CarwashTimeComponent() {
        this.status$ = CarwashTimeComponent.createStatus$(this.remainingTime);
        this.timeMinutes$ = this.remainingTime.pipe(map(function (time) { return Math.trunc(time / 60); }));
    }
    CarwashTimeComponent.createStatus$ = function (remainingTime) {
        return remainingTime.pipe(filter(function (time) { return time !== null; }), map(function (time) {
            if (time === undefined) {
                return 'connection_lost';
            }
            return time === 0 ? 'free' : 'busy';
        }), startWith('connecting'));
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], CarwashTimeComponent.prototype, "remainingTime", void 0);
    return CarwashTimeComponent;
}());
export { CarwashTimeComponent };
