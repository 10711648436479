import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

interface Parameters {
    image_top: string;
    color_top?: string;
    background_color_top: string;
    iframe_url: string;
    iframe_reload_interval?: number;  // in minutes
}

@Component({
    selector: 'siq-weather-news-landscape-layout',
    // language=Angular2HTML
    template: `
        <div
            [ngStyle]="styleTop$ | async">
            <img [src]="imageTop$ | async">
            <siq-weather-forecast-landscape style="position: absolute; left: 1458px; top: 0; width: 462px;">
            </siq-weather-forecast-landscape>
        </div>

        <div style="width: 1920px;">
            <siq-full-hd-iframe [sourceUrl]="iframeUrl$ | async"
                                [reloadInterval]="iframeReloadInterval$ | async"
                                style="position: relative; float: left; width: 1458px; height: 820px">
            </siq-full-hd-iframe>

            <div style="position: relative; float: left; width: 462px; height: 820px">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
        </div>
    `,
})
export class WeatherNewsLandscapeLayoutComponent extends BaseLayoutComponent {
    readonly parameters$!: Observable<Parameters>;
    readonly imageTop$ = this.getLayoutImage$('image_top');
    readonly styleTop$ = createStyleTop$(this.parameters$);
    readonly iframeUrl$ = this.parameters$.pipe(pluck('iframe_url'));
    readonly iframeReloadInterval$ = this.parameters$.pipe(pluck('iframe_reload_interval'));

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}

function createStyleTop$(parameters$: Observable<Parameters>): any {
    return parameters$.pipe(map(parameters => ({
        width: '1920px',
        height: '260px',
        color: parameters.color_top || 'white',
        'background-color': parameters.background_color_top,
    })));
}
