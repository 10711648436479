import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AbstractPlatform } from '../platforms/platform';
var RegisterService = /** @class */ (function () {
    function RegisterService(http, platform) {
        this.http = http;
        this.platform = platform;
    }
    RegisterService.prototype.checkRegister = function (setupCode) {
        return this.registerInternal(setupCode, false)
            .pipe(map(function (responseText) { return JSON.parse(responseText); }));
    };
    RegisterService.prototype.register = function (setupCode) {
        return this.registerInternal(setupCode, true);
    };
    RegisterService.prototype.registerInternal = function (setupCode, confirm) {
        var _this = this;
        var url = environment.serverAddress + '/api/smartscreen/register';
        return from(this.platform.getSerialNumber()).pipe(switchMap(function (serial) {
            return _this.http.post(url, {
                setupCode: setupCode,
                confirm: confirm,
                serial: serial,
            }, {
                responseType: 'text',
            });
        }));
    };
    return RegisterService;
}());
export { RegisterService };
