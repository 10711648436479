import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, timer} from 'rxjs';
import {filter, map, mapTo, tap} from 'rxjs/operators';

import {TimeService} from '../shared/time.service';
import {Condition, ConditionService} from './types';

const logger = Logger.get('schedule-condition');

interface ScheduleCondition extends Condition {
    date_end: string;
    date_start: string;

    // Some of this day properties may be missing. Missing property means false.
    day_friday?: boolean;
    day_monday?: boolean;
    day_saturday?: boolean;
    day_sunday?: boolean;
    day_thursday?: boolean;
    day_tuesday?: boolean;
    day_wednesday?: boolean;

    // interval === 0 and time_start === time_end means no repeat on the same day
    interval: number;
    time_end: string;
    time_start: string;
}

@Injectable()
export class ScheduleConditionService implements ConditionService {
    isAssociatedCondition(condition: ScheduleCondition): boolean {
        return condition.type === 'schedule';
    }

    /**
     * Subscribing immediately ist recommended. Otherwise the condition is not checked at the beginning of each new minute.
     */
    getConditionMatch$(condition: ScheduleCondition): Observable<void> {
        // Convert condition properties once in internal format
        const dateStart = moment(condition.date_start);
        const dateEnd = moment(condition.date_end);
        const weekDays = _.chain(condition)
            .pickBy((value, key) => key.startsWith('day_') && value)
            .keys()
            .map(key => _.capitalize(key.substring(4)))
            .value();
        const timeStart = moment(condition.time_start, 'HH:mm');
        const timeEnd = moment(condition.time_end, 'HH:mm');
        const interval = Math.max(1, condition.interval); // Prevent modulo result NaN

        const creationTime = TimeService.now();
        const timeToNextMinute = 60_000 - creationTime.seconds() * 1000 - creationTime.milliseconds();

        return timer(timeToNextMinute % 60_000, 60_000).pipe(
            map(() => TimeService.now()),
            filter(now =>
                now.isBetween(dateStart, dateEnd, 'day', '[]')
                && weekDays.includes(now.locale('en').format('dddd'))
                && now.isBetween(timeStart, timeEnd, 'minute', '[]')
                && (now.diff(timeStart, 'minutes') % interval) === 0,
            ),
            mapTo(undefined),
            tap(() => logger.debug('Schedule condition matched.')),
        );
    }
}
