import * as tslib_1 from "tslib";
import * as msgpack from 'msgpack-lite';
import { Subject } from 'rxjs';
import { SMPCloseEvent, SMPDisconnectedException, SMPMessageEvent, SMPMessageType, SMPOpenEvent, SMPRemoteErrorException, SMPSendNotConnectedException, } from './types';
var BaseTransport = /** @class */ (function () {
    function BaseTransport() {
        this.reconnecting = false;
        this.closing = false; // set to true when close() is called
        this.reconnectDelay = BaseTransport.RECONNECT_INITIAL_DELAY;
        // low level request management
        // every request in SMP has a request id and gets a response from the other side
        this.requests = new Map();
        this.requestId = 1;
        this.eventsSubject = new Subject();
    }
    Object.defineProperty(BaseTransport.prototype, "events", {
        get: function () {
            return this.eventsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    BaseTransport.prototype.connect = function () {
        var _this = this;
        if (this.connectionTimeoutTimer !== undefined) {
            console.warn('Already connecting.');
            return;
        }
        this.connectionTimeoutTimer = setTimeout(function () {
            console.warn('Timeout while connecting. Closing connection.');
            _this.connectionTimeoutTimer = undefined;
            _this.forceClose();
        }, BaseTransport.CONNECTION_TIMEOUT);
        this.closing = false;
        this.connectInternal();
    };
    BaseTransport.prototype.close = function () {
        this.closing = true;
        this.forceClose();
    };
    BaseTransport.prototype.execute = function (msgType) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return new Promise(function (resolve, reject) {
            var requestId = _this.requestId++;
            _this.requests.set(requestId, { resolve: resolve, reject: reject });
            _this.send([msgType, requestId].concat(args));
        });
    };
    BaseTransport.prototype.onOpen = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                clearTimeout(this.connectionTimeoutTimer);
                this.connectionTimeoutTimer = undefined;
                this.reconnectDelay = BaseTransport.RECONNECT_INITIAL_DELAY;
                this.schedulePing();
                this.eventsSubject.next(new SMPOpenEvent(this.reconnecting));
                this.reconnecting = false;
                return [2 /*return*/];
            });
        });
    };
    BaseTransport.prototype.onClose = function () {
        this.requestId = 1;
        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;
        clearTimeout(this.pingTimer);
        clearTimeout(this.pingTimeoutTimer);
        // Reject all in-flight requests (Better as leaving them hanging without any response).
        for (var _i = 0, _a = Array.from(this.requests.values()); _i < _a.length; _i++) {
            var reject = _a[_i].reject;
            reject(new SMPDisconnectedException());
        }
        this.requests.clear();
        // Trigger a reconnect if close() wasn't called.
        if (!this.closing) {
            // close() wasn't called, try to reconnect
            this.reconnect();
        }
        this.eventsSubject.next(new SMPCloseEvent(this.closing));
    };
    BaseTransport.prototype.onMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var msgType, requestId, msgArgs;
            return tslib_1.__generator(this, function (_a) {
                msgType = message[0], requestId = message[1], msgArgs = message.slice(2);
                if (msgType === SMPMessageType.SUCCESS) {
                    if (!this.requests.has(requestId)) {
                        // logger.debug('Received success msg for non-existent request');
                    }
                    else {
                        this.requests.get(requestId).resolve(msgArgs[0]);
                        this.requests.delete(requestId);
                    }
                    return [2 /*return*/];
                }
                if (msgType === SMPMessageType.ERROR) {
                    if (!this.requests.has(requestId)) {
                        // logger.debug('Received error msg for non-existent request');
                    }
                    else {
                        this.requests.get(requestId).reject(new SMPRemoteErrorException(msgArgs[0]));
                        this.requests.delete(requestId);
                    }
                    return [2 /*return*/];
                }
                this.eventsSubject.next(new SMPMessageEvent(message));
                return [2 /*return*/];
            });
        });
    };
    BaseTransport.prototype.reconnect = function () {
        var _this = this;
        if (this.reconnectTimer !== undefined) {
            console.warn('Reconnect triggered while already scheduled for reconnect.');
            return;
        }
        this.reconnecting = true;
        this.reconnectTimer = setTimeout(function () {
            _this.reconnectTimer = undefined;
            _this.reconnectDelay = Math.min(BaseTransport.RECONNECT_MAX_DELAY, _this.reconnectDelay * 2);
            _this.connect();
        }, this.reconnectDelay);
    };
    BaseTransport.prototype.schedulePing = function () {
        var _this = this;
        clearTimeout(this.pingTimer);
        this.pingTimer = setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sendPing()];
                    case 1:
                        _a.sent();
                        // Only continue scheduling pings if we are connected
                        if (this.connected) {
                            this.schedulePing();
                        }
                        return [2 /*return*/];
                }
            });
        }); }, BaseTransport.PING_INTERVAL);
    };
    BaseTransport.prototype.sendPing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestId_1, promise, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        requestId_1 = this.requestId++;
                        promise = new Promise(function (resolve, reject) {
                            _this.requests.set(requestId_1, { resolve: resolve, reject: reject });
                            _this.send([SMPMessageType.PING, requestId_1]);
                        });
                        this.pingTimeoutTimer = setTimeout(function () {
                            console.warn('Connection timed out. Closing connection.');
                            var reject = _this.requests.get(requestId_1).reject;
                            _this.requests.delete(requestId_1);
                            // This triggers the try catch surrounding this, which triggers forceClose()
                            reject();
                        }, BaseTransport.PING_TIMEOUT);
                        return [4 /*yield*/, promise];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        e_1 = _a.sent();
                        this.forceClose();
                        return [3 /*break*/, 4];
                    case 3:
                        clearTimeout(this.pingTimeoutTimer);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BaseTransport.RECONNECT_MAX_DELAY = 60000;
    BaseTransport.RECONNECT_INITIAL_DELAY = 1000;
    BaseTransport.CONNECTION_TIMEOUT = 15000;
    BaseTransport.PING_INTERVAL = 60000;
    BaseTransport.PING_TIMEOUT = 15000;
    return BaseTransport;
}());
export { BaseTransport };
var WebsocketTransport = /** @class */ (function (_super) {
    tslib_1.__extends(WebsocketTransport, _super);
    function WebsocketTransport(url, useJSON, zonePatch) {
        if (useJSON === void 0) { useJSON = false; }
        if (zonePatch === void 0) { zonePatch = false; }
        var _this = _super.call(this) || this;
        _this.url = url;
        _this.useJSON = useJSON;
        _this.zonePatch = zonePatch;
        return _this;
    }
    Object.defineProperty(WebsocketTransport.prototype, "connected", {
        get: function () {
            return this.socket !== undefined && this.socket.readyState === WebSocket.OPEN;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebsocketTransport.prototype, "bufferedAmount", {
        get: function () {
            return this.socket !== undefined ? this.socket.bufferedAmount : 0;
        },
        enumerable: true,
        configurable: true
    });
    WebsocketTransport.prototype.send = function (msg) {
        if (this.socket === undefined || !this.connected) {
            throw new SMPSendNotConnectedException();
        }
        if (this.useJSON) {
            this.socket.send(JSON.stringify(msg));
        }
        else {
            this.socket.send(msgpack.encode(msg));
        }
    };
    WebsocketTransport.prototype.forceClose = function () {
        if (this.socket !== undefined) {
            this.cleanupSocket();
        }
        this.onClose();
    };
    WebsocketTransport.prototype.connectInternal = function () {
        var _this = this;
        var url = typeof this.url === 'string' ? this.url : this.url();
        this.socket = new WebSocket(url);
        this.socket.binaryType = 'arraybuffer';
        var onOpen = this.onOpen.bind(this);
        var onClose = function () {
            _this.cleanupSocket();
            _this.onClose();
        };
        var onMessage = function (e) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, deserializeMessage(e.data)];
                    case 1:
                        message = _a.sent();
                        return [4 /*yield*/, this.onMessage(message)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        // We need to patch zone.js for nativescript manually
        this.socket.onopen = this.zonePatch ? Zone.current.wrap(onOpen, 'websocket-onopen') : onOpen;
        this.socket.onclose = this.zonePatch ? Zone.current.wrap(onClose, 'websocket-onclose') : onClose;
        this.socket.onmessage = this.zonePatch ? Zone.current.wrap(onMessage, 'websocket-onmessage') : onMessage;
    };
    WebsocketTransport.prototype.cleanupSocket = function () {
        if (this.socket === undefined) {
            return;
        }
        this.socket.onmessage = function () { return undefined; };
        this.socket.onopen = function () { return undefined; };
        this.socket.onclose = function () { return undefined; };
        this.socket.onerror = function () { return undefined; };
        this.socket.close();
        this.socket = undefined;
    };
    return WebsocketTransport;
}(BaseTransport));
export { WebsocketTransport };
function deserializeMessage(msg) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var arrayBuffer, _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (typeof msg === 'string') {
                        return [2 /*return*/, JSON.parse(msg)];
                    }
                    if (!(msg instanceof ArrayBuffer)) return [3 /*break*/, 1];
                    _a = msg;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, readBlobAsArrayBuffer(msg)];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    arrayBuffer = _a;
                    return [2 /*return*/, msgpack.decode(new Uint8Array(arrayBuffer))];
            }
        });
    });
}
function readBlobAsArrayBuffer(blob) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () { return resolve(reader.result); };
        reader.onerror = function () { return reject(reader.error); };
        reader.readAsArrayBuffer(blob);
    });
}
