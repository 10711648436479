import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, delay, map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
var logger = Logger.get('time');
var TimeServiceBase = /** @class */ (function () {
    function TimeServiceBase(http) {
        this.http = http;
        this.getTimeByHttp$ = createGetTimeByHttp(this.http);
    }
    TimeServiceBase.HTTP_TIME_SYNC_RETRY_DELAY = 60000;
    return TimeServiceBase;
}());
export { TimeServiceBase };
export function sleep(millis) {
    return of(undefined).pipe(delay(millis)).toPromise();
}
function createGetTimeByHttp(http) {
    return http.get(getHttpSyncUrl() + "/api/time", {
        observe: 'response',
        responseType: 'text',
    }).pipe(map(function (response) { return [response.headers.get('Date'), response.statusText]; }), 
    // Http error response is also useful if date header is set.
    catchError(function (response) {
        // Try to get a better error message on android.
        var stack = _.get(response, 'error.originalStack');
        var status = response.statusText === 'Unknown Error' && typeof stack === 'string'
            ? stack.split('\n', 1)[0]
            : response.statusText;
        return of([response.headers.get('Date'), status]);
    }), timeout(30000), catchError(function () { return of([undefined, 'Timeout']); }), map(function (_a) {
        var headerDate = _a[0], status = _a[1];
        if (!headerDate) {
            var message = "Failed to get time by http: " + status;
            logger.warn(message);
            throw new Error(message);
        }
        return moment(headerDate);
    }));
}
function getHttpSyncUrl() {
    if (!environment.serverAddress.startsWith('https://')) {
        return environment.serverAddress;
    }
    return "http://" + environment.serverAddress.substr(8);
}
