import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import {defer, EMPTY, forkJoin, from, Observable, of, throwError, Timestamp} from 'rxjs';
import {bufferCount, catchError, concatMap, map, mergeAll, tap, timeout, timestamp} from 'rxjs/operators';

import {LayoutAsset} from './settings.service';
import {SyncData} from './sync.service.base';

export interface FileSyncProgress {
    currentFile: string;    // current downloading file
    currentSize: number;    // total downloaded bytes
    currentCount?: number;  // total downloaded files
    totalSize: number;      // total bytes to download
    totalCount?: number;    // total files to download
}

interface BaseRemoteFileSyncFileConfig {
    name: string;
    url: string;
    modified?: never;  // Ensure that immutable file config can be used as immutable remote file
}

interface ImmutableRemoteFileSyncFileConfig extends BaseRemoteFileSyncFileConfig {
    size: number;
    headUrl?: never;
}

interface MutableRemoteFileSyncFileConfig extends BaseRemoteFileSyncFileConfig {
    headUrl: string;
    size?: never;
}

export type RemoteFileSyncFileConfig = ImmutableRemoteFileSyncFileConfig | MutableRemoteFileSyncFileConfig;

export interface RemoteFileSyncFile {
    name: string;
    url: string;

    size: number;
    modified?: Date;  // for mutable files only
}

export interface LocalFileSyncFile {
    name: string;
    size: number;
    modified: Date;
}

export enum FileSyncErrorType {
    NO_NETWORK,
    TIMEOUT,
    NOT_FOUND,
    GENERIC,
}

export class FileSyncError {
    constructor(readonly type: FileSyncErrorType, readonly message?: string, readonly extra?: any) {
        if (this.message === undefined) {
            this.message = FileSyncErrorType[type];
        }
    }
}

export type RemoveKeyFilterFn = (fileName: string) => boolean;

@Injectable()
export abstract class FileSyncServiceBase {
    protected abstract localContentPrefix: string;
    protected abstract localLayoutPrefix: string;
    protected abstract localHtmlTemplateAssetPrefix: string;

    private lockedContentIds: Array<string> = [];

    set lockedContents(ids: Array<number>) {
        this.lockedContentIds = ids.map(id => id.toString());
    }

    syncContentFiles(syncData: SyncData): Observable<FileSyncProgress> {
        const remoteFiles = _(syncData.contents)
            .filter(content => content.used || !syncData.settings.selective_file_sync)
            .map(content => ({
                name: `${content.id}.${content.type === 'picture' ? 'png' : 'mp4'}`,
                url: content.url,
                size: content.size,
            }))
            .value();

        const removeKeyFilter = (fileName: string) =>
            !this.lockedContentIds.includes(fileName.substring(0, fileName.lastIndexOf('.')));

        const syncer = this.createUrlFileSyncer(
            this.localContentPrefix,
            remoteFiles,
            removeKeyFilter,
        );

        return syncer.syncToLocalFolder();
    }

    syncLayoutFiles(syncData: SyncData): Observable<FileSyncProgress> {
        const remoteFiles = _(syncData.layout_assets as Array<LayoutAsset>)
            .filter(asset => asset.used || !syncData.settings.selective_file_sync)
            .map(asset => ({
                name: `${asset.id}`,
                url: asset.url,
                ...(asset.headUrl !== undefined ? {headUrl: asset.headUrl} : {size: asset.size}),
            }))
            .value();

        return this.createUrlFileSyncer(this.localLayoutPrefix, remoteFiles).syncToLocalFolder();
    }

    syncHtmlTemplateAssets(syncData: SyncData): Observable<FileSyncProgress> {
        const remoteFiles = _(syncData.html_template_assets)
            .filter(asset => asset.used || !syncData.settings.selective_file_sync)
            .map(asset => ({name: `${asset.id}`, url: asset.url, size: asset.size}))
            .value();

        return this.createUrlFileSyncer(this.localHtmlTemplateAssetPrefix, remoteFiles).syncToLocalFolder();
    }

    abstract getContentFileURL(name: string): string | undefined;

    abstract getLayoutFileURL(name: string): string | undefined;

    abstract getHtmlTemplateAssetFileURL(name: string): string | undefined;

    protected abstract createUrlFileSyncer(localPrefix: string,
                                           remoteFiles?: Array<RemoteFileSyncFileConfig>,
                                           removeKeyFilter?: RemoveKeyFilterFn): UrlFileSyncerBase;
}

export abstract class FileSyncerBase {
    protected logger = Logger.get('file-syncer');

    protected constructor(private removeKeyFilter: RemoveKeyFilterFn = () => true) {}

    syncToLocalFolder(): Observable<FileSyncProgress> {
        return defer(async () => {
            const localFiles = await this.getLocalFiles();
            const remoteFiles = await this.getRemoteFiles();

            const filesToRemove = FileSyncerBase.getFilesToRemove(localFiles, remoteFiles);
            await this.removeFiles(filesToRemove.filter(file => this.removeKeyFilter(file.name)));

            const filesToDownload = this.getFilesToDownload(localFiles, remoteFiles);
            return this.downloadFiles(filesToDownload);
        }).pipe(mergeAll());
    }

    protected abstract getLocalFiles(): Promise<Array<LocalFileSyncFile>>;

    protected abstract getRemoteFiles(): Promise<Array<RemoteFileSyncFile>>;

    protected abstract removeFiles(files: Array<LocalFileSyncFile>): Promise<void>;

    protected abstract downloadSingleFile(file: RemoteFileSyncFile): Observable<number>;

    protected downloadFiles(files: Array<RemoteFileSyncFile>): Observable<FileSyncProgress> {
        const totalCount = files.length;
        const totalSize = _.sumBy(files, file => file.size);
        let currentSize = 0;
        let currentCount = 1;
        const skippedErrors: Array<FileSyncError> = [];

        return from(files).pipe(
            concatMap(file => this.downloadSingleFile(file).pipe(
                map(currentFileSize => ({
                    currentFile: file.name,
                    currentSize: currentSize + currentFileSize,
                    currentCount,
                    totalSize,
                    totalCount,
                })),
                tap({
                    complete: () => {
                        currentSize += file.size;
                        currentCount += 1;
                    },
                }),
                catchError(error => {
                    if (error instanceof FileSyncError && error.type === FileSyncErrorType.NOT_FOUND) {
                        skippedErrors.push(error);
                        this.logger.warn(`Failed to download file ${file.name}: ${error.message}`);
                        return EMPTY;
                    }
                    throw error;
                }),
            )),
            tap({
                complete: () => {
                    if (skippedErrors.length > 0) {
                        // TODO pack all errors
                        throw skippedErrors[0];
                    }
                },
            }),
        );
    }

    protected getFilesToDownload(localFiles: Array<LocalFileSyncFile>,
                                 remoteFiles: Array<RemoteFileSyncFile>): Array<RemoteFileSyncFile> {
        // We need to find out which files need to be downloaded and which files are already up-to-date
        const localFileNames = localFiles.map(file => file.name);

        // files to download = missing files + outdated files
        const missingFiles = remoteFiles.filter(file => !localFileNames.includes(file.name));
        const outdatedFiles = remoteFiles
            .filter(remoteFile => {
                const localFile = localFiles.find(file => file.name === remoteFile.name);
                return localFile !== undefined && !this.isFileUpToDate(localFile, remoteFile);
            });

        return missingFiles.concat(outdatedFiles);
    }

    protected isFileUpToDate(localFile: LocalFileSyncFile, remoteFile: RemoteFileSyncFile): boolean {
        // modified is undefined for immutable files
        if (remoteFile.modified === undefined) {
            return true;
        }

        // Do not load again if modified is missing.
        return (localFile.modified === undefined
                || localFile.modified >= remoteFile.modified
            )
            && localFile.size === remoteFile.size;
    }

    protected static getFilesToRemove(localFiles: Array<LocalFileSyncFile>,
                                      remoteFiles: Array<RemoteFileSyncFile>): Array<LocalFileSyncFile> {
        const remoteFileNames = remoteFiles.map(file => file.name);

        // files to remove = localFiles that aren't available in remoteFiles
        return localFiles.filter(file => !remoteFileNames.includes(file.name));
    }
}

export abstract class UrlFileSyncerBase extends FileSyncerBase {
    constructor(protected httpClient: HttpClient,
                protected localPrefix: string,
                protected remoteFiles: Array<RemoteFileSyncFileConfig>,
                removeKeyFilter?: RemoveKeyFilterFn) {
        super(removeKeyFilter);
    }

    protected getRemoteFiles(): Promise<Array<RemoteFileSyncFile>> {
        const files$ = this.remoteFiles.map(
            (file: RemoteFileSyncFileConfig) => file.headUrl !== undefined
                ? this.getRemoteFileFromMutableRemoteFileConfig$(file)
                : of(file),
        );

        // forkJoin returns an empty array if it gets an empty array
        return files$.length ? forkJoin(files$).toPromise() : Promise.resolve([]);
    }

    protected getRemoteFileFromMutableRemoteFileConfig$(
        file: MutableRemoteFileSyncFileConfig,
    ): Observable<RemoteFileSyncFile> {

        const getHeader = (headers: HttpHeaders, key: string): string => {
            const value = headers.get(key);
            // tslint:disable-next-line:no-null-keyword
            if (value == null) {
                throw new Error(`Missing header ${key}`);
            }
            return value;
        };

        return this.httpClient.head(
            file.headUrl,
            {
                observe: 'response',
                headers: new HttpHeaders({'Cache-Control': 'no-cache'}),
            },
        ).pipe(
            timeout(30_000),
            map(response => ({
                name: file.name,
                url: file.url,
                size: +getHeader(response.headers, 'Content-Length'),
                modified: new Date(getHeader(response.headers, 'Last-Modified')),
            })),
            tap({
                error: e => this.logger.debug(`HEAD request failed for ${file.name}: ${e.message || e.toString()}`),
            }),
            catchError(error => throwError(new FileSyncError(
                FileSyncErrorType.GENERIC,
                `HEAD: ${error.statusText || error.message || error.toString()}`,
                error,
            ))),
        );
    }
}

export function mapFileSyncProgressToFormattedProgress(): (source: Observable<FileSyncProgress>) => Observable<string> {
    return (source: Observable<FileSyncProgress>) =>
        source.pipe(
            timestamp(),
            bufferCount(5, 1),
            map((progressArray: Array<Timestamp<FileSyncProgress>>) => {
                const currentProgress = progressArray[progressArray.length - 1].value;

                // calculate average download speed using the last 3 progress events
                const downloadProgress = `${humanFileSize(currentProgress.currentSize)} / ${humanFileSize(currentProgress.totalSize)}`;
                const downloadRate = calculateAverageDownloadRatePerSecond(progressArray);

                return `${downloadProgress} (${humanFileSize(downloadRate)}/s)`;
            }),
        );
}

function humanFileSize(bytes: number, si: boolean = true): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes.toFixed(0)} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    let size = bytes;
    do {
        size /= thresh;
        u += 1;
    } while (Math.abs(size) >= thresh && u < units.length - 1);
    return `${size.toFixed(1)} ${units[u]}`;
}

function calculateAverageDownloadRatePerSecond(
    fileSyncProgressEvents: Array<Timestamp<FileSyncProgress>>): number {

    if (fileSyncProgressEvents.length < 2) {
        return 0;
    }

    const downloadRates = [];
    for (let i = 1; i < fileSyncProgressEvents.length; i++) {
        const timeDiff = fileSyncProgressEvents[i].timestamp
            - fileSyncProgressEvents[i - 1].timestamp;

        const sizeDiff = fileSyncProgressEvents[i].value.currentSize
            - fileSyncProgressEvents[i - 1].value.currentSize;

        const sizePerSecond = sizeDiff / (timeDiff / 1000);
        downloadRates.push(sizePerSecond);
    }

    return _.mean(downloadRates);
}
