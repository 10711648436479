import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import {defer, forkJoin, Observable, of} from 'rxjs';
import {
    catchError,
    concatMap,
    delay,
    distinctUntilChanged,
    expand,
    first,
    map,
    retry,
    retryWhen,
    shareReplay,
    switchMap,
    takeWhile,
    timeout,
} from 'rxjs/operators';

import {NodeClientService} from '../shared/node-client-service';
import {TimeService} from '../shared/time.service';
import {SettingsService} from '../sync/settings.service';

const logger = Logger.get('hella-aps');

interface ApsSettings {
    ip_address: string;
    password: string;
}

interface LineCount {
    name: string;
    data: Array<{
        class: 'Adult' | 'Child' | 'Group' | 'Cart';
        in: number;
        out: number;
    }>;
}

@Injectable()
export class HellaApsService {
    private static readonly API_PORT = 8091;
    private static readonly COUNTS_PATH = '/apiv1/sensorData/counts';
    private static readonly POLL_TIMEOUT = 3_000;
    private static readonly POLL_DELAY = 3_000;

    private static readonly RESET_TIME = {hour: 3, minute: 0, second: 0, millisecond: 0};
    private static readonly RESET_PERIOD = 'days';
    private static readonly RESET_CATCHUP_TIME = 60 * 60 * 1000;
    private static readonly RESET_TIMEOUT = 30_000;
    private static readonly RESET_RETRY_DELAY = 60_000;

    readonly cameras$ = createApsCameras$(this.settings);
    readonly peopleCount$ = this.createPeopleCount(this.cameras$);

    constructor(private settings: SettingsService,
                private nodeClient: NodeClientService) {
    }

    start(): void {
        // Schedule daily reset of line counts of all cameras.
        const startTime = TimeService.now();
        const todayResetTime = startTime.clone().set(HellaApsService.RESET_TIME);
        const diff = startTime.diff(todayResetTime);

        const initialDelay = diff > 0 && diff < HellaApsService.RESET_CATCHUP_TIME
            ? 0
            : todayResetTime.clone().add(diff >= 0 ? 1 : 0, HellaApsService.RESET_PERIOD).diff(startTime);

        const resetTimer$ = of(undefined).pipe(
            delay(initialDelay),
            expand(() => {
                const now = TimeService.now();
                return of(undefined).pipe(
                    delay(now.clone()
                        .set(HellaApsService.RESET_TIME)
                        .add(1, HellaApsService.RESET_PERIOD)
                        .diff(now),
                    ),
                );
            }),
        );

        resetTimer$.subscribe(async () => {
            const cameras = await this.cameras$.pipe(first()).toPromise();
            await Promise.all(cameras.map(camera =>
                this.resetCountsWithRetry$(camera.ip_address, camera.password).toPromise(),
            ));
        });
    }

    async resetCounts(ip: string, password: string): Promise<void> {
        logger.debug('Resetting line counts of Hella APS with ip: ' + ip);

        const token = await this.getToken(ip, password);

        await this.nodeClient.sendHttpsRequest({
            requestOptions: {
                hostname: ip,
                port: HellaApsService.API_PORT,
                path: HellaApsService.COUNTS_PATH,
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
            rejectUnauthorized: false,
        });

        logger.info('Successfully reset line counts of Hella APS with ip: ' + ip);
    }

    resetCountsWithRetry$(ip: string, password: string): Observable<void> {
        return defer(() => this.resetCounts(ip, password)).pipe(
            timeout(HellaApsService.RESET_TIMEOUT),
            retryWhen(errors => errors.pipe(
                takeWhile(e => {
                    const maxTimeDiff = HellaApsService.RESET_CATCHUP_TIME - HellaApsService.RESET_RETRY_DELAY;
                    const now = TimeService.now();

                    if (now.diff(now.clone().set(HellaApsService.RESET_TIME)) <= maxTimeDiff) {
                        logger.warn(`Failed to reset Hella APS with ip ${ip}: ${e.message}`);
                        return true;
                    }
                    logger.error(`Finally failed to reset Hella APS with ip ${ip}: ${e.message}`);
                    return false;
                }),
                delay(HellaApsService.RESET_RETRY_DELAY),
            )),
        );
    }

    async getToken(ip: string, password: string): Promise<string> {
        return (await this.nodeClient.sendHttpsRequest({
            requestOptions: {
                hostname: ip,
                port: HellaApsService.API_PORT,
                path: '/auth',
                method: 'POST',
            },
            body: {username: 'user-role-edit', password},
            rejectUnauthorized: false,
        })).access_token;
    }

    async pollCounts(ip: string, password: string): Promise<Array<LineCount>> {
        const token = await this.getToken(ip, password);

        return (await this.nodeClient.sendHttpsRequest({
            requestOptions: {
                hostname: ip,
                port: HellaApsService.API_PORT,
                path: HellaApsService.COUNTS_PATH,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
            rejectUnauthorized: false,
        })).counts;
    }

    pollCountsWithRetry$(ip: string, password: string): Observable<Array<LineCount>> {
        return defer(() => this.pollCounts(ip, password)).pipe(
            timeout(HellaApsService.POLL_TIMEOUT),
            catchError(e => {
                logger.debug(`Error while polling counts of Hella APS with ip ${ip}: ${e.message}`);
                throw e;
            }),
            retry(2),
        );
    }

    createPeopleCount(cameras$: Observable<Array<ApsSettings>>): Observable<number | undefined> {
        return cameras$.pipe(
            switchMap(cameras => cameras.length > 0
                ? forkJoin(cameras.map(camera => this.pollCountsWithRetry$(camera.ip_address, camera.password))).pipe(
                    // Combine cameras, lines and age groups
                    map(counts => _(counts)
                        .flatten()
                        .map('data')
                        .flatten()
                        .value(),
                    ),
                    map(counts => counts.length > 0
                        ? _.sumBy(counts, count => count.in - count.out)
                        : undefined,
                    ),
                    catchError(() => of(undefined)),
                    loop(HellaApsService.POLL_DELAY),
                )
                : of(undefined),
            ),
            shareReplay({bufferSize: 1, refCount: true}),
        );
    }
}

function createApsCameras$(settings: SettingsService): Observable<Array<ApsSettings>> {
    return settings.getCameras$().pipe(
        map(cameras => cameras.filter(camera => camera.type === 'HELLA_AGLAIA_APS') as Array<ApsSettings>),
        distinctUntilChanged(),
    );
}

function loop<T>(sleep: number): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
        source.pipe(expand(() => source.pipe(subscribeDelayed(sleep))));
}

function subscribeDelayed<T>(sleep: number): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => of(undefined).pipe(
        delay(sleep),
        concatMap(() => source),
    );
}
