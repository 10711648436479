import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { combineLatest, interval, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
var WeatherForecastBaseComponent = /** @class */ (function () {
    function WeatherForecastBaseComponent(settings) {
        this.settings = settings;
        this.shortTemperatureFormat = false;
        this.ngUnsubscribe = new Subject();
        this.dayTrackByFn = function (index, day) { return day.date; };
        this.days$ = combineLatest([
            this.settings.getWeather$(),
            interval(60 * 1000).pipe(startWith((0))),
        ]).pipe(takeUntil(this.ngUnsubscribe), map(function (_a) {
            var days = _a[0];
            var today = moment().startOf('day').toDate();
            return days
                .map(function (day) { return (tslib_1.__assign({}, day, { date: new Date(day.date) })); })
                .filter(function (day) { return day.date >= today; })
                .slice(0, 3);
        }));
    }
    WeatherForecastBaseComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    return WeatherForecastBaseComponent;
}());
export { WeatherForecastBaseComponent };
