import {Component} from '@angular/core';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

@Component({
    selector: 'siq-std-layout',
    // language=Angular2HTML
    template: `
        <div style="position: relative; width: 1600px; height: 900px">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>
        <div style="position: absolute; top: 0; left: 1600px; width: 320px; height: 1080px; background-color: #19191A">
            <div style="margin-top: 100px">
                <siq-date></siq-date>
                <siq-clock [color]="'#ffd218'"></siq-clock>
            </div>

            <div class="line"></div>

            <img [src]="imageRight$ | async">
        </div>
        <div style="position: absolute; top: 900px; left: 0; width: 1600px; height: 180px; z-index: 100">
            <img [src]="imageBottom$ | async" style="position: absolute; top: -210px; left: 0">
            <div style="height: 60px; width: 100%; position: absolute; top: 120px; background-color: #ebebeb;">
                <img src="assets/logo_n24.png" width="100" style="position:absolute; left: 2px; top: 15px">
                <siq-news-ticker style="margin-left: 120px"></siq-news-ticker>
            </div>
        </div>
    `,
})
export class ShopiqStdLayoutComponent extends BaseLayoutComponent {
    readonly imageRight$ = this.getLayoutImage$('image_right');
    readonly imageBottom$ = this.getLayoutImage$('image_bottom');

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}
