import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {AbstractPlatform} from '../platforms/platform';

@Injectable()
export class RegisterService {
    constructor(private http: HttpClient, private platform: AbstractPlatform) {
    }

    checkRegister(setupCode: string): Observable<any> {
        return this.registerInternal(setupCode, false)
            .pipe(map((responseText: string) => JSON.parse(responseText)));
    }

    register(setupCode: string): Observable<string> {
        return this.registerInternal(setupCode, true);
    }

    private registerInternal(setupCode: string, confirm: boolean): Observable<string> {
        const url = environment.serverAddress + '/api/smartscreen/register';
        return from(this.platform.getSerialNumber()).pipe(
            switchMap(serial =>
                this.http.post(url, {
                    setupCode,
                    confirm,
                    serial,
                }, {
                    responseType: 'text',
                }),
            ),
        );
    }
}
