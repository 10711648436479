import {Subject} from 'rxjs';

export class ConnectionEvent {}
export class ConnectionOpenEvent extends ConnectionEvent {}
export class ConnectionCloseEvent extends ConnectionEvent {}
export class DataReceivedEvent {
    constructor(public data: OvenStatus) {}
}

export type OvenEvent = ConnectionEvent | DataReceivedEvent;

export class DeckStatus {
    constructor(
        public remainingBakingTime: number,  // in seconds
        public program: number,
    ) {}
}

export class OvenStatus {
    decks = Array<DeckStatus>();
}

export interface OvenClient {
    events: Subject<OvenEvent>;

    connect(): void;
    disconnect(): void;
}
