import {Component} from '@angular/core';
import {combineLatest, merge, Observable} from 'rxjs';
import {distinctUntilChanged, first, map, share, startWith, switchMap} from 'rxjs/operators';
import {WashtecClient} from '../../connect/washtec-client';
import {BaseLayoutComponent} from '../../player/layout/base-layout.component';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';

interface Parameters {
    carwash_ip_addresses: Array<string>;
    carwash_background_color_busy: string;
    carwash_background_color_free: string;
    color_bottom?: string;
}

@Component({
    selector: 'siq-washtec-weather-layout',
    styles: [`
        img {
            display: block;
        }

        .right-bar {
            position: absolute;
            left: 1328px;
            top: 0;
            width: 592px;
        }

        .carwash {
            height: 180px;
            text-align: center;
            font-size: 66px;
            font-weight: bold;
            color: white;
        }
    `],
    // language=Angular2HTML
    template: `
        <img [src]="imageBackground$ | async">

        <div class="right-bar">
            <div class="carwash" [style.background-color]="carwashBgColor$ | async">
                <span style="display: inline-block; height: 100%; vertical-align: middle"></span>
                <span style="display: inline-block; vertical-align: middle">
                    <span>WASCHANLAGE</span>
                    <br>
                    <span>{{ carwashMessage$ | async }}</span>
                </span>
            </div>
            <img style="height: 333px;"
                 [src]="contentRight$ | async" [style.display]="(contentRightIsVideo$ | async) ? 'none' : 'block'">
            <!-- If both video elements touch, a strip flickers in between.
                 So we need 2px bottom margin on first video element. -->
            <video style="height: 333px; margin-bottom: 2px;"
                   autoplay loop
                   [src]="contentRight$ | async"
                   [style.display]="(contentRightIsVideo$ | async) ? 'block' : 'none'">
            </video>
            <div style="position: relative; height: 333px;">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
            <div style="height: 234px;">
                <siq-weather-forecast-landscape [ngStyle]="weatherStyle$ | async"
                                                [shortTemperatureFormat]="true">
                </siq-weather-forecast-landscape>
            </div>
        </div>
    `,
})

export class WashtecWeatherLayoutComponent extends BaseLayoutComponent {
    readonly parameters$!: Observable<Parameters>;
    readonly imageBackground$ = this.getLayoutImage$('image_background');
    readonly contentRight$ = this.getLayoutImage$('content_right');
    readonly contentRightIsVideo$ = this.contentRight$.pipe(map(path => path.includes('.mp4?t=')));
    readonly carwashMessage$: Observable<string>;
    readonly carwashBgColor$: Observable<string>;
    readonly weatherStyle$ = createWeatherStyle$(this.parameters$);

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);

        const carwashTime$ = createCarwashTime$(this.parameters$);
        this.carwashMessage$ = createCarwashMessage$(carwashTime$);
        this.carwashBgColor$ = createCarwashBackgroundColor$(this.parameters$, carwashTime$);
    }
}

function createCarwashTime$(parameters$: Observable<Parameters>): Observable<number | undefined> {
    return parameters$.pipe(
        switchMap(parameters =>
            combineLatest(parameters.carwash_ip_addresses.map(ip => WashtecClient.getTime$(ip)))),
        map(times => times.filter(time => time !== undefined)),
        map((times: Array<number>) => times.length === 0 ? undefined : Math.min(...times)),
        distinctUntilChanged(),
        share(),
    );
}

function createCarwashMessage$(time$: Observable<number | undefined>): Observable<string> {
    return time$.pipe(
        map(time => {
                if (time === 0) {
                    return 'JETZT FREI';
                }
                if (time === undefined) {
                    return 'Keine Verbindung';
                }
                // something like 'FREI IN 03:05'
                return `FREI IN ${('00' + Math.trunc(time / 60)).slice(-2)}:${('00' + (time % 60)).slice(-2)}`;
            },
        ),
        startWith('Verbinde...'),
    );
}

function createCarwashBackgroundColor$(parameters$: Observable<Parameters>,
                                       time$: Observable<number | undefined>): Observable<string> {
    return merge(
        // To have earlier a bg-color.
        parameters$.pipe(first(), map(parameters => parameters.carwash_background_color_busy)),
        combineLatest([time$, parameters$]).pipe(
            map(([time, parameters]) =>
                time === 0 ? parameters.carwash_background_color_free : parameters.carwash_background_color_busy,
            ),
        ),
    );
}

function createWeatherStyle$(parameters$: Observable<Parameters>): any {
    return parameters$.pipe(map(parameters => ({
        width: '358px',
        color: parameters.color_bottom || 'white',
    })));
}
