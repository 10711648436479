import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { combineLatest, Subject, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, startWith, takeUntil, tap } from 'rxjs/operators';
import { ActionService } from '../shared/action.service';
import { TimeService } from '../shared/time.service';
import { SettingsService } from '../sync/settings.service';
import { SyncEndEvent, SyncServiceBase } from '../sync/sync.service.base';
var logger = Logger.get('scheduler');
export var ContentType;
(function (ContentType) {
    ContentType[ContentType["PICTURE"] = 0] = "PICTURE";
    ContentType[ContentType["VIDEO"] = 1] = "VIDEO";
    ContentType[ContentType["STREAM"] = 2] = "STREAM";
})(ContentType || (ContentType = {}));
/**
 * SchedulerService.
 *
 * - Must be started using start() first
 * - Manual update can be triggered using updatePlaylist() after started
 *
 * The playlist will be updated on:
 * - every sync
 * - every 60s, only if the current playlist is empty
 */
var SchedulerServiceBase = /** @class */ (function () {
    function SchedulerServiceBase(settings, sync, actionService) {
        var _this = this;
        this.settings = settings;
        this.sync = sync;
        this.actionService = actionService;
        this.updateTrigger$ = new Subject();
        var afterSyncEvents$ = this.sync.events
            .pipe(filter(function (event) { return event instanceof SyncEndEvent; }));
        this.playlist$ = combineLatest([
            this.updateTrigger$.pipe(startWith(0)),
            afterSyncEvents$.pipe(startWith(0)),
        ]).pipe(map(function () { return _this.generatePlaylist(); }), distinctUntilChanged(_.isEqual), tap(function (playlist) {
            _this.playlist = playlist;
            if (playlist.length === 0) {
                _this.startUpdateTimer();
            }
            // Lock content files of current playlist to prevent deletion during playing.
            _this.sync.lockedContents = playlist.map(function (entry) { return entry.id; });
        }), shareReplay({ bufferSize: 1, refCount: true }));
    }
    SchedulerServiceBase.prototype.getPlaylist$ = function () {
        return this.playlist$;
    };
    SchedulerServiceBase.prototype.getPlaylist = function () {
        return this.playlist;
    };
    SchedulerServiceBase.prototype.triggerPlaylistUpdate = function () {
        this.updateTrigger$.next();
    };
    SchedulerServiceBase.prototype.startUpdateTimer = function () {
        // Start a timer observable that triggers at the start of each minute until the playlist is not empty anymore
        var timeToNextMinute = (60 - TimeService.now().seconds()) * 1000;
        timer(timeToNextMinute, 60 * 1000).pipe(map(function () { return undefined; }), takeUntil(this.playlist$.pipe(
        // Always skip the first value, because this is either the value cached by shareReplay()
        // or the empty playlist that called this method in the first place (from the tap() operator).
        // The cached value is the one which would lead to premature exit for this timer
        // even if the real current playlist is still empty.
        skip(1), filter(function (playlist) { return playlist.length > 0; })))).subscribe(this.updateTrigger$.next.bind(this.updateTrigger$));
    };
    /**
     * @param atTime The time to use as "current time".
     * @param savedTimeMode When true holiday calendar, weekdays and current time is ignored.
     * @param ignoreHolidays When true holiday calendar is ignored.
     */
    SchedulerServiceBase.prototype.generatePlaylist = function (atTime, savedTimeMode, ignoreHolidays) {
        var _this = this;
        if (atTime === void 0) { atTime = TimeService.now(); }
        if (savedTimeMode === void 0) { savedTimeMode = false; }
        if (ignoreHolidays === void 0) { ignoreHolidays = false; }
        // Check whether today is a holiday and how it has to be handled
        if (!savedTimeMode && !ignoreHolidays) {
            var holidays = this.settings.getHolidays();
            var holidayAction = getHolidayActionForDay(holidays, atTime);
            if (holidayAction === 'OFF') {
                return [];
            }
            else if (holidayAction === 'LIKE_SUNDAY') {
                // Use calendar from previous sunday
                return this.generatePlaylist(atTime.clone().subtract(7, 'day').isoWeekday(7), false, true);
            }
            else if (holidayAction === 'NONE') {
                // Just continue like normal
            }
        }
        var actions = this.actionService.getActions(atTime);
        var playlist = [];
        var minPriority = 0;
        // Helper function
        var addActionToPlaylist = function (action) {
            minPriority = action.priority;
            for (var _i = 0, _a = action.parameters.contents; _i < _a.length; _i++) {
                var content = _a[_i];
                var contentDb = _this.settings.getContent(content.id);
                if (contentDb === undefined) {
                    logger.warn("No db content found for content " + content.id + " in action " + action.id);
                    continue;
                }
                var type = contentDb.type === 'picture' ? ContentType.PICTURE : ContentType.VIDEO;
                var source = _this.settings.getContentFileURL(content.id);
                if (source === undefined) {
                    logger.warn('Failed to generate file url for content: ' + content.id);
                    continue;
                }
                var layoutId = content.layout || action.parameters.layout;
                playlist.push({
                    type: type,
                    source: source,
                    id: content.id,
                    period: content.period,
                    layout: _this.settings.getLayout(layoutId) || { template: 'default' },
                });
            }
        };
        for (var _i = 0, actions_1 = actions; _i < actions_1.length; _i++) {
            var action = actions_1[_i];
            // Exit if we have reached the lower priorities
            if (action.priority < minPriority) {
                break;
            }
            // Handle actions crossing midnight correctly
            if (action.timeEnd.isBefore(action.timeStart)) {
                var prevDay = atTime.clone().subtract(1, 'day');
                if (checkActionDayConditions(action, atTime, savedTimeMode)
                    && (savedTimeMode || atTime.isSameOrAfter(action.timeStart))) {
                    addActionToPlaylist(action);
                }
                else if (checkActionDayConditions(action, prevDay, savedTimeMode)
                    && (savedTimeMode || atTime.isSameOrBefore(action.timeEnd))) {
                    addActionToPlaylist(action);
                }
                continue;
            }
            var timeIsBetween = atTime.isBetween(action.timeStart, action.timeEnd, undefined, '[]');
            if (checkActionDayConditions(action, atTime, savedTimeMode) && (savedTimeMode || timeIsBetween)) {
                addActionToPlaylist(action);
            }
        }
        return playlist;
    };
    return SchedulerServiceBase;
}());
export { SchedulerServiceBase };
function getHolidayActionForDay(holidays, day) {
    var dateString = day.format('DD.MM.YYYY');
    var holidaysFiltered = holidays.filter(function (value) { return value.dates.indexOf(dateString) >= 0; });
    if (holidaysFiltered.length === 0) {
        return undefined;
    }
    var holiday = holidaysFiltered[0];
    if (holiday.dates_details[dateString] && holiday.dates_details[dateString].marketing) {
        return holiday.dates_details[dateString].marketing;
    }
    else {
        return 'OFF';
    }
}
function checkActionDayConditions(action, day, savedTimeMode) {
    if (savedTimeMode === void 0) { savedTimeMode = false; }
    if (savedTimeMode) {
        return checkActionDate(action, day);
    }
    return _.every([checkActionWeekRepeat, checkActionDate, checkActionWeekday], function (fn) { return fn(action, day); });
}
function checkActionWeekRepeat(action, day) {
    if (!Number.isInteger(action.weekRepeat) || action.weekRepeat <= 1) {
        return true;
    }
    var startWeek = action.dateStart.clone().startOf('isoWeek');
    var dayWeek = day.clone().startOf('isoWeek');
    return dayWeek.diff(startWeek, 'week') % action.weekRepeat === 0;
}
function checkActionDate(action, day) {
    return day.isBetween(action.dateStart, action.dateEnd, 'day', '[]');
}
function checkActionWeekday(action, day) {
    var weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return action['day' + weekdays[day.isoWeekday() - 1]];
}
