import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import * as Logger from 'js-logger';
var logger = Logger.get('image-player');
var ImagePlayerComponent = /** @class */ (function () {
    function ImagePlayerComponent(cda) {
        this.cda = cda;
        this.completed = new EventEmitter();
        this.entries = [];
        this.entrySources = [];
        this.entryIdx = 0;
        this.hidden = true;
    }
    ImagePlayerComponent.prototype.prepare = function (entry) {
        logger.debug('preparing image for display ' + entry.source);
        this.entries[this.entryIdx] = entry;
        this.entrySources[this.entryIdx] = "" + entry.source;
    };
    ImagePlayerComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug('playing image: ' + this.entries[this.entryIdx].source);
                        this.playTimer = setTimeout(function () {
                            logger.debug('finished play image');
                            _this.playTimer = undefined;
                            _this.completed.emit();
                        }, (this.entries[this.entryIdx].period || 10) * 1000);
                        this.hidden = false;
                        this.entryIdx = (this.entryIdx + 1) % 3;
                        // Ensure that image is really displayed on screen when this play-method returns.
                        this.cda.detectChanges();
                        return [4 /*yield*/, new Promise(requestAnimationFrame)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, new Promise(requestAnimationFrame)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ImagePlayerComponent.prototype.stop = function () {
        if (this.playTimer === undefined) {
            this.hidden = true;
            return;
        }
        clearTimeout(this.playTimer);
        this.playTimer = undefined;
        this.hidden = true;
    };
    return ImagePlayerComponent;
}());
export { ImagePlayerComponent };
