import {Component, OnInit} from '@angular/core';
import {BootstrapServiceBase} from './bootstrap.service.base';

@Component({
    selector: 'siq-app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
    constructor(private bootstrap: BootstrapServiceBase) {}

    async ngOnInit(): Promise<void> {
        await this.bootstrap.bootstrap();
    }
}
