import {Input, OnDestroy} from '@angular/core';
import {combineLatest, interval, Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {DayWeatherData, SettingsService} from '../sync/settings.service';
import * as moment from 'moment';

export class WeatherForecastBaseComponent implements OnDestroy {
    @Input() shortTemperatureFormat = false;

    days$: Observable<Array<DayWeatherData>>;
    private ngUnsubscribe = new Subject<void>();

    constructor(private settings: SettingsService) {
        this.days$ = combineLatest([
            this.settings.getWeather$(),
            interval(60 * 1000).pipe(startWith((0))),
        ]).pipe(
            takeUntil(this.ngUnsubscribe),
            map(([days]) => {
                const today = moment().startOf('day').toDate();
                return days
                    .map(day => ({...day, date: new Date(day.date)}))
                    .filter(day => day.date >= today)
                    .slice(0, 3);
            }),
        );
    }

    dayTrackByFn = (index: number, day: DayWeatherData) => day.date;

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
