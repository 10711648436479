import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var ShopiqStandardOverlayLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShopiqStandardOverlayLayoutComponent, _super);
    function ShopiqStandardOverlayLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.imageOverlay$ = _this.getLayoutImage$('image_overlay');
        _this.clockColor$ = _this.layout.pipe(map(function (layout) { return layout ? layout.parameters.clock_color : 'red'; }));
        return _this;
    }
    return ShopiqStandardOverlayLayoutComponent;
}(BaseLayoutComponent));
export { ShopiqStandardOverlayLayoutComponent };
