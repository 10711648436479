import * as tslib_1 from "tslib";
import * as Sentry from '@sentry/minimal';
import { Severity } from '@sentry/types';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SMPCloseEvent, SMPDisconnectedException, SMPEndpointAlreadyRegisteredException, SMPException, SMPMessageEvent, SMPMessageType, SMPOpenEvent, } from './types';
// Reexport some things for easier usage
export { WebsocketTransport } from './transport';
export { SMPCloseEvent, SMPDisconnectedException, SMPEndpointAlreadyRegisteredException, SMPException, SMPMessageEvent, SMPMessageType, SMPOpenEvent, SMPSendNotConnectedException, } from './types';
/**
 * Shop-IQ Messaging Protocol
 */
var SMPConnection = /** @class */ (function () {
    function SMPConnection(transport, tokenProvider) {
        this.transport = transport;
        this.tokenProvider = tokenProvider;
        this.eventsSubject = new Subject();
        this.authenticated = false;
        // state management for high level features such as subscribe and register
        this.registrations = new Map();
        this.subscriptions = new Map();
        // Requests that are scheduled but not yet executed (scheduled using setTimeout(...,0))
        this.scheduledRequests = [];
        // State information for the pubblishIfSubscribed(...) method
        // which only sends published message if the topic has subscribers
        this.topicStateContainers = new Map();
        // Register onOpen()
        this.transport.events.pipe(filter(function (event) { return event instanceof SMPOpenEvent; })).subscribe(this.onOpen.bind(this));
        // Pass SMPCloseEvent() through to our own events
        this.transport.events.pipe(filter(function (event) { return event instanceof SMPCloseEvent; })).subscribe(this.eventsSubject);
        // Subscribe onMessage() to unpacked SMPMessageEvents
        this.transport.events.pipe(filter(function (event) { return event instanceof SMPMessageEvent; }), map(function (event) { return event.message; })).subscribe(this.onMessage.bind(this));
    }
    Object.defineProperty(SMPConnection.prototype, "events", {
        get: function () {
            return this.eventsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SMPConnection.prototype, "connected", {
        get: function () {
            return this.transport.connected && this.authenticated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SMPConnection.prototype, "bufferedAmount", {
        get: function () {
            return this.transport.bufferedAmount;
        },
        enumerable: true,
        configurable: true
    });
    SMPConnection.prototype.connect = function () {
        this.transport.connect();
    };
    SMPConnection.prototype.close = function () {
        this.transport.close();
    };
    SMPConnection.prototype.register = function (endpoint, handler) {
        if (this.registrations.has(endpoint)) {
            throw new SMPEndpointAlreadyRegisteredException();
        }
        this.registrations.set(endpoint, handler);
        // Only create a request if we are connected atm, otherwise onOpen() will register us
        if (this.connected) {
            // Register cannot be batched (yet?), so we always create a new request
            var request = this.getScheduledRequest(SMPMessageType.REGISTER, true);
            request.args = [endpoint];
        }
    };
    /**
     * Subscribe a handler to a topic.
     *
     * Limitations: If this connection is already subscribed to topic the parameter replayLastValue will be ignored!
     *
     * @param topic the topic to subscribe to
     * @param handler a handler callback, which be be called with a single parameter (the received message)
     * @param replayLastValue whether the server will send us the last value published before we subscribed
     */
    SMPConnection.prototype.subscribe = function (topic, handler, replayLastValue) {
        if (replayLastValue === void 0) { replayLastValue = false; }
        // We only need to send the subscribe command on the first subscription of a topic
        if (this.subscriptions.has(topic)) {
            // tslint:disable-next-line:ban-ts-ignore
            // @ts-ignore: Object is possibly undefined
            this.subscriptions.get(topic).push(handler);
            return;
        }
        this.subscriptions.set(topic, [handler]);
        // Only create a request if we are connected atm, otherwise onOpen() will subscribe us
        if (this.connected) {
            var type = replayLastValue ? SMPMessageType.SUBSCRIBE_REPLAY : SMPMessageType.SUBSCRIBE;
            var request = this.getScheduledRequest(type);
            request.args.push(topic);
        }
    };
    SMPConnection.prototype.subscribe$ = function (topic, replayLastValue) {
        var _this = this;
        if (replayLastValue === void 0) { replayLastValue = false; }
        return new Observable(function (subscriber) {
            var handler = subscriber.next.bind(subscriber);
            _this.subscribe(topic, handler, replayLastValue);
            return function () {
                _this.unsubscribe(topic, handler);
            };
        });
    };
    SMPConnection.prototype.unsubscribe = function (topic, handler) {
        if (!this.subscriptions.has(topic)) {
            return;
        }
        // Remove handler from our handler list
        // tslint:disable-next-line:ban-ts-ignore
        // @ts-ignore: Object is possibly undefined
        var handlers = this.subscriptions.get(topic).filter(function (fn) { return fn !== handler; });
        if (handlers.length > 0) {
            this.subscriptions.set(topic, handlers);
            return;
        }
        // There are no more handlers left for this topic so we unsubscribe
        this.subscriptions.delete(topic);
        var request = this.getScheduledRequest(SMPMessageType.UNSUBSCRIBE);
        request.args.push(topic);
    };
    SMPConnection.prototype.call = function (endpoint) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, (_a = this.transport).execute.apply(_a, [SMPMessageType.CALL, endpoint].concat(args))];
            });
        });
    };
    SMPConnection.prototype.publish = function (topic, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transport.execute(SMPMessageType.PUBLISH, topic, msg)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SMPConnection.prototype.publishIfSubscribed = function (topic, msg) {
        var topicState = this.getTopicStateContainer(topic);
        topicState.lastPublishedValue = msg;
        if (topicState.alive) {
            // noinspection JSIgnoredPromiseFromCall
            this.publishTopicState(topic);
        }
    };
    SMPConnection.prototype.publishTopicState = function (topic) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var topicState, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        topicState = this.getTopicStateContainer(topic);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.transport.execute(SMPMessageType.PUBLISH, topic, topicState.lastPublishedValue)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.warn("Exception while publishing message on topic " + topic + ": " + e_1.toString());
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SMPConnection.prototype.getTopicStateContainer = function (topic) {
        var _this = this;
        if (this.topicStateContainers.has(topic)) {
            return this.topicStateContainers.get(topic);
        }
        var topicState = {
            alive: false,
        };
        topicState.subscription = this
            .subscribe$('meta.topic_changed.' + topic, true)
            .subscribe(function (change) {
            topicState.alive = change === 'CREATED';
            if (topicState.alive) {
                // noinspection JSIgnoredPromiseFromCall
                _this.publishTopicState(topic);
            }
        });
        this.topicStateContainers.set(topic, topicState);
        return topicState;
    };
    /**
     * Gets or creates a ScheduledRequest with the specified message type.
     *
     * @param type The type of the request
     * @param createNew If true a new request is always created
     */
    SMPConnection.prototype.getScheduledRequest = function (type, createNew) {
        var _this = this;
        if (createNew === void 0) { createNew = false; }
        if (!createNew) {
            for (var _i = 0, _a = this.scheduledRequests; _i < _a.length; _i++) {
                var request_1 = _a[_i];
                if (request_1.type === type) {
                    return request_1;
                }
            }
        }
        var request = {
            type: type,
            args: [],
            retryCount: 0,
        };
        var executeRequest = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var e_2;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // Remove the request from the scheduled array, because we are sending it now
                        this.scheduledRequests = this.scheduledRequests.filter(function (r) { return r !== request; });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, (_a = this.transport).execute.apply(_a, [request.type].concat(request.args))];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        if (e_2 instanceof SMPDisconnectedException) {
                            // we can ignore disconnected errors, because we will retry in onOpen()
                            return [2 /*return*/];
                        }
                        if (!this.connected) {
                            // same as above, requests will be retried in onOpen()
                            return [2 /*return*/];
                        }
                        // Only retry a request up to 3 times before we stop retrying
                        request.retryCount += 1;
                        if (request.retryCount > 3) {
                            console.warn('Failed to execute request after 3 retries.', e_2, request);
                            Sentry.captureException(e_2);
                            return [2 /*return*/];
                        }
                        // Remember the retry in sentry and retry later
                        Sentry.addBreadcrumb({
                            category: 'smp',
                            message: 'Retrying failed request because of exception: ' + e_2.toString(),
                            level: Severity.Warning,
                        });
                        setTimeout(executeRequest, SMPConnection.REQUEST_RETRY_DELAY);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        setTimeout(executeRequest, 0);
        this.scheduledRequests.push(request);
        return request;
    };
    SMPConnection.prototype.onOpen = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, e_3, _i, _d, endpoint;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        _b = (_a = this.transport).execute;
                        _c = [SMPMessageType.AUTH];
                        return [4 /*yield*/, this.tokenProvider()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_e.sent()]))];
                    case 2:
                        _e.sent();
                        this.authenticated = true;
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _e.sent();
                        console.warn('Error while authenticating. Closing connection and retrying.');
                        this.transport.forceClose();
                        return [2 /*return*/];
                    case 4:
                        // We can subscribe to all topics in a single request
                        if (this.subscriptions.size > 0) {
                            this.getScheduledRequest(SMPMessageType.SUBSCRIBE_REPLAY).args = Array.from(this.subscriptions.keys());
                        }
                        // We have to reregister every registration (one by one, there is not batch api)
                        for (_i = 0, _d = Array.from(this.registrations.keys()); _i < _d.length; _i++) {
                            endpoint = _d[_i];
                            this.getScheduledRequest(SMPMessageType.REGISTER, true).args = [endpoint];
                        }
                        this.eventsSubject.next(event);
                        return [2 /*return*/];
                }
            });
        });
    };
    SMPConnection.prototype.onMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var msgType, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msgType = message[0];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!(msgType === SMPMessageType.CALL)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.onCallMessage(message)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3:
                        if (msgType === SMPMessageType.EVENT) {
                            this.onEventMessage(message);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        if (!(e_4 instanceof SMPException)) {
                            throw e_4;
                        }
                        console.warn('Error while sending response for received message:', e_4);
                        return [2 /*return*/];
                    case 5:
                        console.warn('Unhandled message type: ' + msgType);
                        return [2 /*return*/];
                }
            });
        });
    };
    SMPConnection.prototype.onCallMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestId, endpoint, callArgs, result, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        requestId = message[1], endpoint = message[2], callArgs = message.slice(3);
                        if (!this.registrations.has(endpoint)) {
                            this.transport.send([SMPMessageType.ERROR, requestId, 'endpoint_not_registered']);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.resolve(this.registrations.get(endpoint).apply(this, callArgs))];
                    case 2:
                        // tslint:disable-next-line:ban-ts-ignore
                        // @ts-ignore: Object is possibly undefined
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_5 = _a.sent();
                        this.transport.send([SMPMessageType.ERROR, requestId, e_5.toString()]);
                        return [2 /*return*/];
                    case 4:
                        this.transport.send([SMPMessageType.SUCCESS, requestId, result]);
                        return [2 /*return*/];
                }
            });
        });
    };
    SMPConnection.prototype.onEventMessage = function (message) {
        var requestId = message[1], topic = message[2], msg = message[3];
        if (!this.subscriptions.has(topic)) {
            this.transport.send([SMPMessageType.ERROR, requestId, 'topic_not_subscribed']);
            return;
        }
        this.transport.send([SMPMessageType.SUCCESS, requestId]);
        // tslint:disable-next-line:ban-ts-ignore
        // @ts-ignore: Object is possibly undefined
        for (var _i = 0, _a = this.subscriptions.get(topic); _i < _a.length; _i++) {
            var handler = _a[_i];
            try {
                handler(msg);
            }
            catch (e) {
                console.warn('Unhandled exception in handler', e);
                Sentry.captureException(e);
            }
        }
    };
    SMPConnection.REQUEST_RETRY_DELAY = 5 * 1000;
    return SMPConnection;
}());
export { SMPConnection };
