import * as Logger from 'js-logger';
import {BehaviorSubject, EMPTY, interval, Observable} from 'rxjs';
import {delay, filter, first, tap} from 'rxjs/operators';

import {AbstractPlatform} from './platforms/platform';
import {ControllerClientServiceBase} from './shared/controller-client.service.base';
import {TimeService} from './shared/time.service';
import {SettingKeys, SettingsService} from './sync/settings.service';
import {SyncConfigDownloadEvent, SyncEndEvent, SyncServiceBase} from './sync/sync.service.base';

export abstract class BootstrapServiceBase {
    readonly dhcpError$: Observable<boolean> = EMPTY;
    readonly messageText$ = new BehaviorSubject<[string, string?]>(['Starte']);
    protected logger = Logger.get('bootstrap');

    protected constructor(protected sync: SyncServiceBase,
                          protected settings: SettingsService,
                          protected platform: AbstractPlatform,
                          private controllerClient: ControllerClientServiceBase) {}

    /**
     * Called before anything else after angular has finished booting.
     */
    async bootstrap(): Promise<void> {
        this.logger.debug('Executing bootstrap.service.base.ts:bootstrap()');

        // load our secret
        await this.sync.loadSecret();

        this.sync
            .events
            .pipe(filter(event => event instanceof SyncEndEvent), first())
            .subscribe(this.onAfterFirstSync.bind(this));

        this.sync
            .events
            .pipe(filter(event => event instanceof SyncConfigDownloadEvent))
            .subscribe(this.onConfigDownloaded.bind(this));

        this.scheduleReboot();
    }

    /**
     * Called after the first time the sync is run (regardless of whether is succeeded or not).
     */
    protected onAfterFirstSync(): Promise<void> {
        this.logger.debug('Executing bootstrap.service.base.ts:onAfterFirstSync()');
        this.startControllerClient();
        return Promise.resolve();
    }

    /**
     * Called every time a new configuration from the server is received.
     */
    protected onConfigDownloaded(): Promise<void> {
        this.logger.debug('Executing bootstrap.service.base.ts:onConfigDownloaded()');
        this.startControllerClient();
        return Promise.resolve();
    }

    protected startControllerClient(): void {
        const controllerClientEnabled = this.settings.get(SettingKeys.CONTROLLER_CLIENT_ENABLED, false);
        if (controllerClientEnabled && !this.controllerClient.isConnectionStarted) {
            this.controllerClient.connect();
        }
    }

    private scheduleReboot(): void {
        const rebootHour = 4;  // Do reboot only from 4:00:00 until 4:59:59
        const checkInterval = 60 * 60 * 1000;  // Spread reboot of multiple devices over the reboot hour

        const uptimeThreshold = () => {
            // Use positive infinity to prevent reboot if interval is missing in settings.
            const rebootInterval = this.settings.get(SettingKeys.REBOOT_INTERVAL, Number.POSITIVE_INFINITY) * 1000;

            // Subtract check interval to be sure not to exceed reboot hour especially for daily reboot.
            return rebootInterval - checkInterval;
        };

        // Subtract some seconds from check interval to be sure
        // to match reboot hour with the not so steady time service.
        interval(checkInterval - 5_000)
            .pipe(
                filter(() => TimeService.now().hours() === rebootHour),
                filter(() => this.platform.getSystemUptime() >= uptimeThreshold()),
                first(),
                tap(() => this.logger.info('Rebooting as scheduled.')),
                delay(5_000),  // Allow controller client to send the log message above.
            )
            .subscribe(async () => this.platform.reboot());
    }
}
