import * as tslib_1 from "tslib";
import { map, pluck } from 'rxjs/operators';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var WeatherNewsLandscapeLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WeatherNewsLandscapeLayoutComponent, _super);
    function WeatherNewsLandscapeLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.imageTop$ = _this.getLayoutImage$('image_top');
        _this.styleTop$ = createStyleTop$(_this.parameters$);
        _this.iframeUrl$ = _this.parameters$.pipe(pluck('iframe_url'));
        _this.iframeReloadInterval$ = _this.parameters$.pipe(pluck('iframe_reload_interval'));
        return _this;
    }
    return WeatherNewsLandscapeLayoutComponent;
}(BaseLayoutComponent));
export { WeatherNewsLandscapeLayoutComponent };
function createStyleTop$(parameters$) {
    return parameters$.pipe(map(function (parameters) { return ({
        width: '1920px',
        height: '260px',
        color: parameters.color_top || 'white',
        'background-color': parameters.background_color_top,
    }); }));
}
