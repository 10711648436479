import {registerLocaleData} from '@angular/common';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import * as Logger from 'js-logger';
// tslint:disable-next-line:no-import-side-effect
import 'moment/locale/de';

import {AppModule} from './app/app.module.lite';
import {setupLogging} from './app/shared/logging';
import {environment} from './environments/environment';

import localeDe from '@angular/common/locales/de';
import {registerSW} from './serviceworker/service-worker-api';
registerLocaleData(localeDe, 'de');

setupLogging(environment.production ? Logger.INFO : Logger.DEBUG);

if (environment.production) {
    Sentry.init({
        dsn: 'https://4f2234b9bab2492299878ddd9a04f56f@o83693.ingest.sentry.io/5800644',
        release: environment.release,
        ignoreErrors: [/^access_denied$/, /^disconnected$/, 'Uncaught (in promise): Error: disconnected'],
    });
    Sentry.configureScope(scope => {
        const secret = localStorage.getItem('secret') || '000000';
        scope.setTag('server_name', secret);
    });

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(_ => registerSW());
