import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { filter, map, mapTo, tap } from 'rxjs/operators';
import { TimeService } from '../shared/time.service';
var logger = Logger.get('schedule-condition');
var ScheduleConditionService = /** @class */ (function () {
    function ScheduleConditionService() {
    }
    ScheduleConditionService.prototype.isAssociatedCondition = function (condition) {
        return condition.type === 'schedule';
    };
    /**
     * Subscribing immediately ist recommended. Otherwise the condition is not checked at the beginning of each new minute.
     */
    ScheduleConditionService.prototype.getConditionMatch$ = function (condition) {
        // Convert condition properties once in internal format
        var dateStart = moment(condition.date_start);
        var dateEnd = moment(condition.date_end);
        var weekDays = _.chain(condition)
            .pickBy(function (value, key) { return key.startsWith('day_') && value; })
            .keys()
            .map(function (key) { return _.capitalize(key.substring(4)); })
            .value();
        var timeStart = moment(condition.time_start, 'HH:mm');
        var timeEnd = moment(condition.time_end, 'HH:mm');
        var interval = Math.max(1, condition.interval); // Prevent modulo result NaN
        var creationTime = TimeService.now();
        var timeToNextMinute = 60000 - creationTime.seconds() * 1000 - creationTime.milliseconds();
        return timer(timeToNextMinute % 60000, 60000).pipe(map(function () { return TimeService.now(); }), filter(function (now) {
            return now.isBetween(dateStart, dateEnd, 'day', '[]')
                && weekDays.includes(now.locale('en').format('dddd'))
                && now.isBetween(timeStart, timeEnd, 'minute', '[]')
                && (now.diff(timeStart, 'minutes') % interval) === 0;
        }), mapTo(undefined), tap(function () { return logger.debug('Schedule condition matched.'); }));
    };
    return ScheduleConditionService;
}());
export { ScheduleConditionService };
