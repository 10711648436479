import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { BootstrapServiceBase } from './bootstrap.service.base';
import { AbstractPlatform } from './platforms/platform';
import { ControllerClientServiceBase } from './shared/controller-client.service.base';
import { SettingsService } from './sync/settings.service';
import { SyncServiceBase } from './sync/sync.service.base';
var BootstrapService = /** @class */ (function (_super) {
    tslib_1.__extends(BootstrapService, _super);
    function BootstrapService(sync, settings, platform, router, controllerClient) {
        var _this = _super.call(this, sync, settings, platform, controllerClient) || this;
        _this.router = router;
        return _this;
    }
    BootstrapService.prototype.bootstrap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.bootstrap.call(this)];
                    case 1:
                        _a.sent();
                        this.logger.debug('Executing bootstrap.service.lite.ts:bootstrap()');
                        return [4 /*yield*/, this.router.navigate(['sync'])];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BootstrapService.prototype.onAfterFirstSync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.onAfterFirstSync.call(this)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BootstrapService.prototype.onConfigDownloaded = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    return BootstrapService;
}(BootstrapServiceBase));
export { BootstrapService };
