import { OnInit } from '@angular/core';
import { TimeService } from '../shared/time.service';
var ClockComponent = /** @class */ (function () {
    function ClockComponent() {
    }
    ClockComponent.prototype.ngOnInit = function () {
        var now = TimeService.now();
        var minutes = now.minutes();
        var hours = now.hours();
        this.minuteAngle = minutes * 6;
        this.hourAngle = (hours * 30) + (minutes / 2);
    };
    return ClockComponent;
}());
export { ClockComponent };
