import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ObservableInput } from 'observable-input/lib';
import { combineLatest, EMPTY, Observable, Subject, timer } from 'rxjs';
import { debounceTime, map, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';
var FullHdIframeComponent = /** @class */ (function () {
    function FullHdIframeComponent(elementRef, sanitizer) {
        var _this = this;
        this.elementRef = elementRef;
        this.sanitizer = sanitizer;
        this.sourceUrlSafe$ = this.sourceUrl.pipe(map(function (url) { return _this.sanitizer.bypassSecurityTrustResourceUrl(url); }));
        this.ngUnsubscribe = new Subject();
    }
    FullHdIframeComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([this.sourceUrl, this.reloadInterval]).pipe(
        // Get only one event if source and interval change
        debounceTime(1000), 
        // Get previous and actual values but also on first values (pairwise starts emit on second value)
        startWith([undefined, undefined]), pairwise(), switchMap(function (_a) {
            var prevSrc = _a[0][0], _b = _a[1], actSrc = _b[0], minutes = _b[1];
            // Reload is disabled if interval is not set
            if (!minutes || minutes < 1) {
                return EMPTY;
            }
            var period = minutes * 60 * 1000;
            // Do only reload immediately if source not changed cause this triggers an automatic reload
            return timer(actSrc === prevSrc ? 0 : period, period);
        }), takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.iframe.nativeElement.contentWindow.location.reload();
        });
    };
    FullHdIframeComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], FullHdIframeComponent.prototype, "sourceUrl", void 0);
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], FullHdIframeComponent.prototype, "reloadInterval", void 0);
    return FullHdIframeComponent;
}());
export { FullHdIframeComponent };
