import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ObservableInput} from 'observable-input/lib';
import {combineLatest, EMPTY, Observable, Subject, timer} from 'rxjs';
import {debounceTime, map, pairwise, startWith, switchMap, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'siq-full-hd-iframe',
    styles: [`
        :host {
            display: block;
            overflow: hidden;
        }

        iframe {
            width: 1920px;
            height: 1080px;
            border: none;
            -webkit-transform-origin: 0 0;
        }
    `],
    template: `
        <iframe #iframe
                [src]="sourceUrlSafe$ | async"
                [style.-webkit-transform]="'scale(' + elementRef.nativeElement.clientWidth / 1920 + ')'">
        </iframe>
    `,
})

export class FullHdIframeComponent implements OnInit, OnDestroy {
    @Input() @ObservableInput() sourceUrl!: Observable<string>;
    @Input() @ObservableInput() reloadInterval!: Observable<number | undefined>;

    @ViewChild('iframe', {static: false}) iframe!: ElementRef;

    readonly sourceUrlSafe$ = this.sourceUrl.pipe(map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)));

    private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(readonly elementRef: ElementRef, private readonly sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        combineLatest([this.sourceUrl, this.reloadInterval]).pipe(
            // Get only one event if source and interval change
            debounceTime(1000),

            // Get previous and actual values but also on first values (pairwise starts emit on second value)
            startWith([undefined, undefined]),
            pairwise(),

            switchMap(([[prevSrc], [actSrc, minutes]]) => {
                // Reload is disabled if interval is not set
                if (!minutes || minutes < 1) {
                    return EMPTY;
                }

                const period = minutes * 60 * 1000;

                // Do only reload immediately if source not changed cause this triggers an automatic reload
                return timer(actSrc === prevSrc ? 0 : period, period);
            }),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(() => {
            this.iframe.nativeElement.contentWindow.location.reload();
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
