import {Component} from '@angular/core';
import {TimeService} from '../shared/time.service';

@Component({
    selector: 'siq-date',
    styles: [`
        .date {
            padding: 10px 0 5px 15px;
            margin-top: 5px;
            color: white;
            font-size: 24px;
            text-align: center;
            width: 120px;
            float: left;
        }

        .date .year {
            font-size: 22px;
        }
    `],
    // language=Angular2HTML
    template: `
        <div class="date">
            <span class="weekday">{{getWeekday()}}</span>
            <br>
            <span class="day">{{getDate()}}</span>
            <br>
            <span class="year">{{getYear()}}</span>
        </div>
    `
})
export class DateComponent {
    getYear() {
        return TimeService.now().year();
    }

    getDate() {
        return TimeService.now().format('D. MMMM');
    }

    getWeekday() {
        return TimeService.now().format('dddd');
    }
}
