import {Component} from '@angular/core';

@Component({
    selector: 'siq-background',
    template: `
        <div class="shopiq-background">
            <div class="shopiq-logo">
                <img src="assets/logo_shopiq.svg" alt="">
            </div>
            <img class="shopiq-slogan" src="assets/logo_shopiq_slogan.svg" alt="">
        </div>
    `,
})
export class BackgroundComponent {
}
