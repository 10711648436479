import {Component, ElementRef, ViewChild} from '@angular/core';
import * as Logger from 'js-logger';

import {BaseVideoPlayerComponent} from './base-video-player.component';

const logger = Logger.get('video-tag-player');

@Component({
    selector: 'siq-video-tag-player',
    styles: [`
        video {
            width: 100%;
            height: 100%;
        }
    `],
    template: `
        <video #video [src]="videoSrc" [style.visibility]="hidden ? 'hidden' : 'visible'" (ended)="onEnded()" (canplay)="onCanplay()">
        </video>
    `,
})
export class VideoTagPlayerComponent extends BaseVideoPlayerComponent {
    videoSrc?: string;
    hidden = true;

    private video!: HTMLVideoElement;
    private prepareFile?: string;

    @ViewChild('video', {static: false})
    set videoRef(ref: ElementRef) {
        this.video = ref.nativeElement;
    }

    async play(): Promise<boolean> {
        logger.debug('Playing video: ' + this.prepareFile);

        if (this.videoSrc === this.prepareFile) {
            // onCanplay is only called if videoSrc changes. So start player manually if no change.
            this.hidden = false;
            await this.video.play();
        } else {
            this.videoSrc = this.prepareFile;
        }

        return true;
    }

    async prepare(filename: string): Promise<boolean> {
        logger.debug('Preparing new video: ' + filename);

        // TODO Set videoSrc here and return promise that resolves when onCanplay is called.
        //  Furthermore start video always in play method.
        this.prepareFile = filename;

        return true;
    }

    stop(): void {
        return;
    }

    onEnded(): void {
        this.hidden = true;
        this.completed.emit();
    }

    onCanplay(): void {
        logger.debug('onCanplay called.');
        this.video.muted = true;
        this.hidden = false;
        this.video.play();
    }
}
