import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from "rxjs/operators";
var AbstractPlatform = /** @class */ (function () {
    function AbstractPlatform() {
        var _this = this;
        this.panelEnabledChange$ = new BehaviorSubject(undefined);
        this.panelEnabled$ = this.panelEnabledChange$.pipe(switchMap(function (state) {
            return state !== undefined ? Promise.resolve(state) : _this.getPanelEnabled();
        }), distinctUntilChanged());
    }
    return AbstractPlatform;
}());
export { AbstractPlatform };
