import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, switchMap} from "rxjs/operators";

export abstract class AbstractPlatform {
    protected panelEnabledChange$ = new BehaviorSubject<boolean | undefined>(undefined);

    panelEnabled$ = this.panelEnabledChange$.pipe(
        switchMap((state) =>
            state !== undefined ? Promise.resolve(state) : this.getPanelEnabled(),
        ),
        distinctUntilChanged(),
    );

    abstract getSerialNumber(): Promise<string>;
    abstract getFirmwareVersion(): Promise<string | undefined>;
    abstract get model(): string;

    abstract getEthernetMacAddress(): Promise<string | undefined>;

    abstract getWifiMacAddress(): Promise<string | undefined>;
    abstract getConnectedWifiSsid(): Promise<string | undefined>;
    abstract getConnectedWifiSignalStrength(): Promise<number | undefined>; // 0..100

    abstract getMacAddress(): Promise<string>;
    abstract getIpAddress(): string;
    // TODO netmask, gateway, dns

    abstract get appUpdateUrl(): string | undefined;

    abstract getInternalStorageAvailableCapacity(): Promise<number | undefined>; // in bytes
    abstract getSystemUptime(): number; // in Milliseconds
    abstract getCpuLoad(): Promise<number | undefined>;  // 0..1

    abstract getPanelEnabled(): Promise<boolean>;
    abstract setPanelEnabled(enabled: boolean): Promise<void>;

    abstract getIREnabled(): Promise<boolean | undefined>;
    abstract setIREnabled(enabled: boolean): Promise<void>;

    abstract getPowerButtonEnabled(): Promise<boolean | undefined>;
    abstract setPowerButtonEnabled(enabled: boolean): Promise<void>;

    abstract captureScreen(): Promise<ArrayBuffer>;
    abstract reboot(): Promise<void>;

    abstract startNodeServer(filename: string): Promise<void>;
}
