/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../video-player.component.ngfactory";
import * as i3 from "../video-player.component";
import * as i4 from "../image-player.component.ngfactory";
import * as i5 from "../image-player.component";
import * as i6 from "./html-overlay-layout.component";
import * as i7 from "../../sync/settings.service";
import * as i8 from "../../sync/file-sync.service.base";
import * as i9 from "@angular/platform-browser";
var styles_HtmlOverlayLayoutComponent = ["iframe[_ngcontent-%COMP%] {\n            border: none;\n            -webkit-transform-origin: 0 0;\n            position: absolute;\n            top: 0;\n            left: 0;\n            width: 100%;\n            height: 100%;\n            z-index: 100;\n        }"];
var RenderType_HtmlOverlayLayoutComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_HtmlOverlayLayoutComponent, data: {} });
export { RenderType_HtmlOverlayLayoutComponent as RenderType_HtmlOverlayLayoutComponent };
function View_HtmlOverlayLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "iframe", [], [[8, "srcdoc", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
export function View_HtmlOverlayLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VideoPlayerComponent_0, i2.RenderType_VideoPlayerComponent)), i0.ɵdid(7, 49152, [[1, 4], ["videoPlayer", 4]], 0, i3.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(8, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ImagePlayerComponent_0, i4.RenderType_ImagePlayerComponent)), i0.ɵdid(9, 49152, [[2, 4], ["imagePlayer", 4]], 0, i5.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_HtmlOverlayLayoutComponent_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.contentStyle$)); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.htmlCode$)); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_HtmlOverlayLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-html-layout", [], null, null, null, View_HtmlOverlayLayoutComponent_0, RenderType_HtmlOverlayLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i6.HtmlOverlayLayoutComponent, [i7.SettingsService, i8.FileSyncServiceBase, i9.DomSanitizer], null, null)], null, null); }
var HtmlOverlayLayoutComponentNgFactory = i0.ɵccf("siq-html-layout", i6.HtmlOverlayLayoutComponent, View_HtmlOverlayLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { HtmlOverlayLayoutComponentNgFactory as HtmlOverlayLayoutComponentNgFactory };
