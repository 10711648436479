
        <div class="top clear">
            <siq-full-hd-iframe class="float-left" style="width: 1458px" [sourceUrl]="iframeUrl$ | async">
            </siq-full-hd-iframe>

            <div class="float-left" style="width: 462px">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
        </div>

        <div class="bottom clear">
            <div class="float-left carwash" [style.background-color]="colorLeft$ | async">
                <img src="assets/washtec_arrow.png" class="float-left">
                <siq-carwash-time-component class="float-right" [remainingTime]="timeLeft$ | async">
                </siq-carwash-time-component>
            </div>
            <div class="float-right carwash" [style.background-color]="colorRight$ | async">
                <img src="assets/washtec_arrow.png" class="float-right" style="-webkit-transform: rotate(180deg)">
                <siq-carwash-time-component class="float-left" [remainingTime]="timeRight$ | async">
                </siq-carwash-time-component>
            </div>
        </div>
    