/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../video-player.component.ngfactory";
import * as i2 from "../video-player.component";
import * as i3 from "../image-player.component.ngfactory";
import * as i4 from "../image-player.component";
import * as i5 from "../news-ticker.component.ngfactory";
import * as i6 from "../news-ticker.component";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/common";
import * as i9 from "../date.component.ngfactory";
import * as i10 from "../date.component";
import * as i11 from "../clock.component.ngfactory";
import * as i12 from "../clock.component";
import * as i13 from "./shopiq-standard-overlay-layout.component";
import * as i14 from "../../sync/settings.service";
import * as i15 from "../../sync/file-sync.service.base";
var styles_ShopiqStandardOverlayLayoutComponent = [".player[_ngcontent-%COMP%] {\n            position: relative;\n            width: 1600px;\n            height: 900px\n        }\n\n        .newsticker[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 1020px;\n            left: 0;\n            width: 1920px;\n            height: 60px;\n            background-color: #ebebeb;\n        }\n\n        .newsticker[_ngcontent-%COMP%]   img[_ngcontent-%COMP%] {\n            position: absolute;\n            left: 2px;\n            top: 15px;\n            width: 100px;\n        }\n\n        .newsticker[_ngcontent-%COMP%]   siq-news-ticker[_ngcontent-%COMP%] {\n            margin-left: 120px;\n        }\n\n        .overlay[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 0;\n            left: 0;\n            z-index: 100;\n        }\n\n        .date_and_clock[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 200px;\n            left: 1600px;\n            width: 320px;\n            z-index: 101;\n        }"];
var RenderType_ShopiqStandardOverlayLayoutComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ShopiqStandardOverlayLayoutComponent, data: {} });
export { RenderType_ShopiqStandardOverlayLayoutComponent as RenderType_ShopiqStandardOverlayLayoutComponent };
export function View_ShopiqStandardOverlayLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "player"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VideoPlayerComponent_0, i1.RenderType_VideoPlayerComponent)), i0.ɵdid(4, 49152, [[1, 4], ["videoPlayer", 4]], 0, i2.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ImagePlayerComponent_0, i3.RenderType_ImagePlayerComponent)), i0.ɵdid(6, 49152, [[2, 4], ["imagePlayer", 4]], 0, i4.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" }), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "newsticker"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["src", "assets/logo_n24.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "siq-news-ticker", [], null, null, null, i5.View_NewsTickerComponent_0, i5.RenderType_NewsTickerComponent)), i0.ɵdid(10, 114688, null, 0, i6.NewsTickerComponent, [i7.HttpClient, i0.NgZone], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "img", [["class", "overlay"]], [[8, "src", 4]], null, null, null, null)), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "date_and_clock"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "siq-date", [], null, null, null, i9.View_DateComponent_0, i9.RenderType_DateComponent)), i0.ɵdid(15, 49152, null, 0, i10.DateComponent, [], null, null), (_l()(), i0.ɵeld(16, 0, null, null, 2, "siq-clock", [], null, null, null, i11.View_ClockComponent_0, i11.RenderType_ClockComponent)), i0.ɵdid(17, 114688, null, 0, i12.ClockComponent, [], { color: [0, "color"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); var currVal_1 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.clockColor$)); _ck(_v, 17, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.imageOverlay$)); _ck(_v, 11, 0, currVal_0); }); }
export function View_ShopiqStandardOverlayLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-standard-overlay-layout", [], null, null, null, View_ShopiqStandardOverlayLayoutComponent_0, RenderType_ShopiqStandardOverlayLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i13.ShopiqStandardOverlayLayoutComponent, [i14.SettingsService, i15.FileSyncServiceBase], null, null)], null, null); }
var ShopiqStandardOverlayLayoutComponentNgFactory = i0.ɵccf("siq-standard-overlay-layout", i13.ShopiqStandardOverlayLayoutComponent, View_ShopiqStandardOverlayLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { ShopiqStandardOverlayLayoutComponentNgFactory as ShopiqStandardOverlayLayoutComponentNgFactory };
