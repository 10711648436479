import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { defer, EMPTY, forkJoin, from, of, throwError } from 'rxjs';
import { bufferCount, catchError, concatMap, map, mergeAll, tap, timeout, timestamp } from 'rxjs/operators';
export var FileSyncErrorType;
(function (FileSyncErrorType) {
    FileSyncErrorType[FileSyncErrorType["NO_NETWORK"] = 0] = "NO_NETWORK";
    FileSyncErrorType[FileSyncErrorType["TIMEOUT"] = 1] = "TIMEOUT";
    FileSyncErrorType[FileSyncErrorType["NOT_FOUND"] = 2] = "NOT_FOUND";
    FileSyncErrorType[FileSyncErrorType["GENERIC"] = 3] = "GENERIC";
})(FileSyncErrorType || (FileSyncErrorType = {}));
var FileSyncError = /** @class */ (function () {
    function FileSyncError(type, message, extra) {
        this.type = type;
        this.message = message;
        this.extra = extra;
        if (this.message === undefined) {
            this.message = FileSyncErrorType[type];
        }
    }
    return FileSyncError;
}());
export { FileSyncError };
var FileSyncServiceBase = /** @class */ (function () {
    function FileSyncServiceBase() {
        this.lockedContentIds = [];
    }
    Object.defineProperty(FileSyncServiceBase.prototype, "lockedContents", {
        set: function (ids) {
            this.lockedContentIds = ids.map(function (id) { return id.toString(); });
        },
        enumerable: true,
        configurable: true
    });
    FileSyncServiceBase.prototype.syncContentFiles = function (syncData) {
        var _this = this;
        var remoteFiles = _(syncData.contents)
            .filter(function (content) { return content.used || !syncData.settings.selective_file_sync; })
            .map(function (content) { return ({
            name: content.id + "." + (content.type === 'picture' ? 'png' : 'mp4'),
            url: content.url,
            size: content.size,
        }); })
            .value();
        var removeKeyFilter = function (fileName) {
            return !_this.lockedContentIds.includes(fileName.substring(0, fileName.lastIndexOf('.')));
        };
        var syncer = this.createUrlFileSyncer(this.localContentPrefix, remoteFiles, removeKeyFilter);
        return syncer.syncToLocalFolder();
    };
    FileSyncServiceBase.prototype.syncLayoutFiles = function (syncData) {
        var remoteFiles = _(syncData.layout_assets)
            .filter(function (asset) { return asset.used || !syncData.settings.selective_file_sync; })
            .map(function (asset) { return (tslib_1.__assign({ name: "" + asset.id, url: asset.url }, (asset.headUrl !== undefined ? { headUrl: asset.headUrl } : { size: asset.size }))); })
            .value();
        return this.createUrlFileSyncer(this.localLayoutPrefix, remoteFiles).syncToLocalFolder();
    };
    FileSyncServiceBase.prototype.syncHtmlTemplateAssets = function (syncData) {
        var remoteFiles = _(syncData.html_template_assets)
            .filter(function (asset) { return asset.used || !syncData.settings.selective_file_sync; })
            .map(function (asset) { return ({ name: "" + asset.id, url: asset.url, size: asset.size }); })
            .value();
        return this.createUrlFileSyncer(this.localHtmlTemplateAssetPrefix, remoteFiles).syncToLocalFolder();
    };
    return FileSyncServiceBase;
}());
export { FileSyncServiceBase };
var FileSyncerBase = /** @class */ (function () {
    function FileSyncerBase(removeKeyFilter) {
        if (removeKeyFilter === void 0) { removeKeyFilter = function () { return true; }; }
        this.removeKeyFilter = removeKeyFilter;
        this.logger = Logger.get('file-syncer');
    }
    FileSyncerBase.prototype.syncToLocalFolder = function () {
        var _this = this;
        return defer(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var localFiles, remoteFiles, filesToRemove, filesToDownload;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLocalFiles()];
                    case 1:
                        localFiles = _a.sent();
                        return [4 /*yield*/, this.getRemoteFiles()];
                    case 2:
                        remoteFiles = _a.sent();
                        filesToRemove = FileSyncerBase.getFilesToRemove(localFiles, remoteFiles);
                        return [4 /*yield*/, this.removeFiles(filesToRemove.filter(function (file) { return _this.removeKeyFilter(file.name); }))];
                    case 3:
                        _a.sent();
                        filesToDownload = this.getFilesToDownload(localFiles, remoteFiles);
                        return [2 /*return*/, this.downloadFiles(filesToDownload)];
                }
            });
        }); }).pipe(mergeAll());
    };
    FileSyncerBase.prototype.downloadFiles = function (files) {
        var _this = this;
        var totalCount = files.length;
        var totalSize = _.sumBy(files, function (file) { return file.size; });
        var currentSize = 0;
        var currentCount = 1;
        var skippedErrors = [];
        return from(files).pipe(concatMap(function (file) { return _this.downloadSingleFile(file).pipe(map(function (currentFileSize) { return ({
            currentFile: file.name,
            currentSize: currentSize + currentFileSize,
            currentCount: currentCount,
            totalSize: totalSize,
            totalCount: totalCount,
        }); }), tap({
            complete: function () {
                currentSize += file.size;
                currentCount += 1;
            },
        }), catchError(function (error) {
            if (error instanceof FileSyncError && error.type === FileSyncErrorType.NOT_FOUND) {
                skippedErrors.push(error);
                _this.logger.warn("Failed to download file " + file.name + ": " + error.message);
                return EMPTY;
            }
            throw error;
        })); }), tap({
            complete: function () {
                if (skippedErrors.length > 0) {
                    // TODO pack all errors
                    throw skippedErrors[0];
                }
            },
        }));
    };
    FileSyncerBase.prototype.getFilesToDownload = function (localFiles, remoteFiles) {
        var _this = this;
        // We need to find out which files need to be downloaded and which files are already up-to-date
        var localFileNames = localFiles.map(function (file) { return file.name; });
        // files to download = missing files + outdated files
        var missingFiles = remoteFiles.filter(function (file) { return !localFileNames.includes(file.name); });
        var outdatedFiles = remoteFiles
            .filter(function (remoteFile) {
            var localFile = localFiles.find(function (file) { return file.name === remoteFile.name; });
            return localFile !== undefined && !_this.isFileUpToDate(localFile, remoteFile);
        });
        return missingFiles.concat(outdatedFiles);
    };
    FileSyncerBase.prototype.isFileUpToDate = function (localFile, remoteFile) {
        // modified is undefined for immutable files
        if (remoteFile.modified === undefined) {
            return true;
        }
        // Do not load again if modified is missing.
        return (localFile.modified === undefined
            || localFile.modified >= remoteFile.modified)
            && localFile.size === remoteFile.size;
    };
    FileSyncerBase.getFilesToRemove = function (localFiles, remoteFiles) {
        var remoteFileNames = remoteFiles.map(function (file) { return file.name; });
        // files to remove = localFiles that aren't available in remoteFiles
        return localFiles.filter(function (file) { return !remoteFileNames.includes(file.name); });
    };
    return FileSyncerBase;
}());
export { FileSyncerBase };
var UrlFileSyncerBase = /** @class */ (function (_super) {
    tslib_1.__extends(UrlFileSyncerBase, _super);
    function UrlFileSyncerBase(httpClient, localPrefix, remoteFiles, removeKeyFilter) {
        var _this = _super.call(this, removeKeyFilter) || this;
        _this.httpClient = httpClient;
        _this.localPrefix = localPrefix;
        _this.remoteFiles = remoteFiles;
        return _this;
    }
    UrlFileSyncerBase.prototype.getRemoteFiles = function () {
        var _this = this;
        var files$ = this.remoteFiles.map(function (file) { return file.headUrl !== undefined
            ? _this.getRemoteFileFromMutableRemoteFileConfig$(file)
            : of(file); });
        // forkJoin returns an empty array if it gets an empty array
        return files$.length ? forkJoin(files$).toPromise() : Promise.resolve([]);
    };
    UrlFileSyncerBase.prototype.getRemoteFileFromMutableRemoteFileConfig$ = function (file) {
        var _this = this;
        var getHeader = function (headers, key) {
            var value = headers.get(key);
            // tslint:disable-next-line:no-null-keyword
            if (value == null) {
                throw new Error("Missing header " + key);
            }
            return value;
        };
        return this.httpClient.head(file.headUrl, {
            observe: 'response',
            headers: new HttpHeaders({ 'Cache-Control': 'no-cache' }),
        }).pipe(timeout(30000), map(function (response) { return ({
            name: file.name,
            url: file.url,
            size: +getHeader(response.headers, 'Content-Length'),
            modified: new Date(getHeader(response.headers, 'Last-Modified')),
        }); }), tap({
            error: function (e) { return _this.logger.debug("HEAD request failed for " + file.name + ": " + (e.message || e.toString())); },
        }), catchError(function (error) { return throwError(new FileSyncError(FileSyncErrorType.GENERIC, "HEAD: " + (error.statusText || error.message || error.toString()), error)); }));
    };
    return UrlFileSyncerBase;
}(FileSyncerBase));
export { UrlFileSyncerBase };
export function mapFileSyncProgressToFormattedProgress() {
    return function (source) {
        return source.pipe(timestamp(), bufferCount(5, 1), map(function (progressArray) {
            var currentProgress = progressArray[progressArray.length - 1].value;
            // calculate average download speed using the last 3 progress events
            var downloadProgress = humanFileSize(currentProgress.currentSize) + " / " + humanFileSize(currentProgress.totalSize);
            var downloadRate = calculateAverageDownloadRatePerSecond(progressArray);
            return downloadProgress + " (" + humanFileSize(downloadRate) + "/s)";
        }));
    };
}
function humanFileSize(bytes, si) {
    if (si === void 0) { si = true; }
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes.toFixed(0) + " B";
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    var size = bytes;
    do {
        size /= thresh;
        u += 1;
    } while (Math.abs(size) >= thresh && u < units.length - 1);
    return size.toFixed(1) + " " + units[u];
}
function calculateAverageDownloadRatePerSecond(fileSyncProgressEvents) {
    if (fileSyncProgressEvents.length < 2) {
        return 0;
    }
    var downloadRates = [];
    for (var i = 1; i < fileSyncProgressEvents.length; i++) {
        var timeDiff = fileSyncProgressEvents[i].timestamp
            - fileSyncProgressEvents[i - 1].timestamp;
        var sizeDiff = fileSyncProgressEvents[i].value.currentSize
            - fileSyncProgressEvents[i - 1].value.currentSize;
        var sizePerSecond = sizeDiff / (timeDiff / 1000);
        downloadRates.push(sizePerSecond);
    }
    return _.mean(downloadRates);
}
