import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {parseString} from 'xml2js';

@Component({
    selector: 'siq-news-ticker',
    styles: [`
        :host {
            display: block;
            position: relative;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
        }

        :host span {
            display: inline-block;
            position: absolute;
            left: 100%;
            color: black;
            line-height: 60px;
            text-indent: 0;

            color: black;
            font-size: 24px;
        }

        @keyframes marquee {
            0%   { transform: translate(0, 0);  }
            100% { transform: translate(-100%, 0);  }
        }

    `],
    template: `
        <span #marquee>
            {{ newsString }}
        </span>
    `,
})
export class NewsTickerComponent implements OnInit {
    @ViewChild('marquee', {static: false}) marqueeElementRef!: ElementRef;
    newsString?: string;

    constructor(private http: HttpClient, private zone: NgZone) { }

    ngOnInit(): void {
        this.fetchNews();
    }

    private async fetchNews(): Promise<void> {
        const url = 'https://www.welt.de/feeds/topnews.rss';
        try {
            const news = await this.http.get(url, {responseType: 'text'}).toPromise();
            const newsParsed = await parseXml(news);
            const newsItems = newsParsed.rss.channel[0].item;
            const newsString = newsItems.map((item: any) => item.title[0]).join(' ⋅ ');
            localStorage.setItem('news', newsString);
            this.newsString = newsString;
        } catch (e) {
            this.newsString = localStorage.getItem('news') || '';
        }

        setTimeout(() => {
            const element = this.marqueeElementRef.nativeElement;
            const seconds = Math.floor(element.offsetWidth / 100);
            element.style.animation = `marquee ${seconds}s linear infinite`;
        }, 1000);
    }
}

function parseXml(xml: string): Promise<any> {
    return new Promise((resolve, reject) => {
        parseString(xml, (err, result) => {
            if (err) {
                reject(err);
            }

            resolve(result);
        });
    });
}
