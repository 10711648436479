import {Component} from '@angular/core';
import * as Logger from 'js-logger';

import {VideoTagPlayerComponent} from './video-tag-player.component';

const logger = Logger.get('video-tag-player');

@Component({
    selector: 'siq-video-player',
    styles: [`
        video {
            width: 100%;
            height: 100%;
        }
    `],
    template: `
        <video #video [src]="videoSrc" [style.visibility]="hidden ? 'hidden' : 'visible'" (ended)="onEnded()" (canplay)="onCanplay()">
        </video>
    `,
})
export class VideoPlayerComponent extends VideoTagPlayerComponent {}
