import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { TimeServiceBase } from './time.service.base';
var TimeService = /** @class */ (function (_super) {
    tslib_1.__extends(TimeService, _super);
    function TimeService(http) {
        return _super.call(this, http) || this;
    }
    TimeService.now = function () {
        if (localStorage) {
            var timeZone = localStorage.getItem('timeZone');
            if (timeZone) {
                return moment().tz(timeZone);
            }
        }
        return moment();
    };
    TimeService.nowMillis = function (_1) {
        if (_1 === void 0) { _1 = true; }
        return Date.now();
    };
    return TimeService;
}(TimeServiceBase));
export { TimeService };
