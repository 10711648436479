export class SMPException extends Error {
    constructor(message: string) {
        super(message);

        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, SMPException.prototype);
    }
}

export class SMPDisconnectedException extends SMPException {
    constructor() {
        super('disconnected');
        Object.setPrototypeOf(this, SMPDisconnectedException.prototype);
    }
}

export class SMPSendNotConnectedException extends SMPException {
    constructor() {
        super('Cannot send while not connected');
        Object.setPrototypeOf(this, SMPSendNotConnectedException.prototype);
    }
}

export class SMPRemoteErrorException extends SMPException {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, SMPRemoteErrorException.prototype);
    }
}

export class SMPEndpointAlreadyRegisteredException extends SMPException {
    constructor() {
        super('endpoint_already_registered');
        Object.setPrototypeOf(this, SMPEndpointAlreadyRegisteredException.prototype);
    }
}

export enum SMPMessageType {
    SUCCESS = 1,
    ERROR = 2,
    AUTH = 3,
    PING = 4,
    REGISTER = 10,
    CALL = 11,
    SUBSCRIBE = 20,
    SUBSCRIBE_REPLAY = 24,
    UNSUBSCRIBE = 21,
    PUBLISH = 22,
    EVENT = 23,
}

export type SMPMessage = [SMPMessageType, number, ...Array<any>];

export type SMPEvent = SMPOpenEvent | SMPCloseEvent | SMPMessageEvent;

export class SMPOpenEvent {
    /**
     * @param isReconnect If true then this connection was already open once and is now reconnecting.
     */
    constructor(readonly isReconnect: boolean = false) {}
}

export class SMPCloseEvent {
    /**
     * @param isClosing If true then .close() was called and the connection will not reconnect.
     */
    constructor(readonly isClosing: boolean = false) {}
}

export class SMPMessageEvent {
    constructor(readonly message: SMPMessage) {}
}
