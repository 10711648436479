
        <div class="playlist">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>

        <siq-full-hd-iframe [sourceUrl]="iframeUrl$ | async"
                            [reloadInterval]="iframeReloadInterval$ | async">
        </siq-full-hd-iframe>

        <div class="bottom">
            <img [src]="imageBottom$ | async" alt="">

            <siq-weather-forecast-portrait [style.color]="weatherColor$ | async"
                                           [shortTemperatureFormat]="true">
            </siq-weather-forecast-portrait>

        </div>
    