import * as tslib_1 from "tslib";
import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, first, map, share, startWith, switchMap } from 'rxjs/operators';
import { WashtecClient } from '../../connect/washtec-client';
import { BaseLayoutComponent } from '../../player/layout/base-layout.component';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
var WashtecWeatherLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WashtecWeatherLayoutComponent, _super);
    function WashtecWeatherLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.imageBackground$ = _this.getLayoutImage$('image_background');
        _this.contentRight$ = _this.getLayoutImage$('content_right');
        _this.contentRightIsVideo$ = _this.contentRight$.pipe(map(function (path) { return path.includes('.mp4?t='); }));
        _this.weatherStyle$ = createWeatherStyle$(_this.parameters$);
        var carwashTime$ = createCarwashTime$(_this.parameters$);
        _this.carwashMessage$ = createCarwashMessage$(carwashTime$);
        _this.carwashBgColor$ = createCarwashBackgroundColor$(_this.parameters$, carwashTime$);
        return _this;
    }
    return WashtecWeatherLayoutComponent;
}(BaseLayoutComponent));
export { WashtecWeatherLayoutComponent };
function createCarwashTime$(parameters$) {
    return parameters$.pipe(switchMap(function (parameters) {
        return combineLatest(parameters.carwash_ip_addresses.map(function (ip) { return WashtecClient.getTime$(ip); }));
    }), map(function (times) { return times.filter(function (time) { return time !== undefined; }); }), map(function (times) { return times.length === 0 ? undefined : Math.min.apply(Math, times); }), distinctUntilChanged(), share());
}
function createCarwashMessage$(time$) {
    return time$.pipe(map(function (time) {
        if (time === 0) {
            return 'JETZT FREI';
        }
        if (time === undefined) {
            return 'Keine Verbindung';
        }
        // something like 'FREI IN 03:05'
        return "FREI IN " + ('00' + Math.trunc(time / 60)).slice(-2) + ":" + ('00' + (time % 60)).slice(-2);
    }), startWith('Verbinde...'));
}
function createCarwashBackgroundColor$(parameters$, time$) {
    return merge(
    // To have earlier a bg-color.
    parameters$.pipe(first(), map(function (parameters) { return parameters.carwash_background_color_busy; })), combineLatest([time$, parameters$]).pipe(map(function (_a) {
        var time = _a[0], parameters = _a[1];
        return time === 0 ? parameters.carwash_background_color_free : parameters.carwash_background_color_busy;
    })));
}
function createWeatherStyle$(parameters$) {
    return parameters$.pipe(map(function (parameters) { return ({
        width: '358px',
        color: parameters.color_bottom || 'white',
    }); }));
}
