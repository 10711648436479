import {APP_BASE_HREF, CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {AppComponent} from './app.component.lite';
import {BootstrapServiceBase} from './bootstrap.service.base';
import {BootstrapService} from './bootstrap.service.lite';
import {OvenConnectionManagerService} from './connect/oven-connection-manager.service';
import {BakeEndConditionService} from './event/bake-end-condition.service';
import {SIQ_EVENT_CONDITION_PROVIDER} from './event/event-trigger.service';
import {ScheduleConditionService} from './event/schedule-condition.service';
import {IntroPageComponent} from './platforms/intro-page.component';
import {AbstractPlatform} from './platforms/platform';
import {WebPlatform} from './platforms/platform-web';
import {EventSchedulerService} from './player/event-scheduler.service';
import {PlayerModule} from './player/player.module';
import {PlaylistPlayerComponent} from './player/playlist-player.component';
import {SchedulerService} from './player/scheduler.service';
import {RegisterService} from './setup/register.service';
import {SharedModule} from './shared.module.lite';
import {ActionService} from './shared/action.service';
import {ControllerClientServiceBase} from './shared/controller-client.service.base';
import {ControllerClientService} from './shared/controller-client.service.lite';
import {TimeService} from './shared/time.service';
import {FileSyncServiceBase} from './sync/file-sync.service.base';
import {FileSyncServiceLite} from './sync/file-sync.service.lite';
import {SettingsService} from './sync/settings.service';
import {SyncLoaderPageComponent} from './sync/sync-loader-page.component.lite';
import {SyncServiceBase} from './sync/sync.service.base';
import {SyncService} from './sync/sync.service.lite';

const appRoutes: Routes = [
    {path: 'init/:secret', component: IntroPageComponent},
    {path: 'sync', component: SyncLoaderPageComponent},
    {path: 'player', component: PlaylistPlayerComponent},
    {path: 'setup' , redirectTo: 'sync'},
    {path: '', component: IntroPageComponent},
    {path: '*', redirectTo: ''},
];

@NgModule({
    declarations: [
        // all declarations are sorted alphabetically
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {initialNavigation: 'enabled'}),
        SharedModule,
        PlayerModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: ErrorHandler, useValue: Sentry.createErrorHandler()},
        // Currently only the tizen and web platform is available in non-nativescript project
        {provide: AbstractPlatform, useClass: WebPlatform},
        // This is needed to circumvent circular import issues
        {provide: SyncServiceBase, useExisting: SyncService},
        {provide: SIQ_EVENT_CONDITION_PROVIDER, useClass: BakeEndConditionService, multi: true},
        {provide: SIQ_EVENT_CONDITION_PROVIDER, useClass: ScheduleConditionService, multi: true},
        {provide: BootstrapServiceBase, useExisting: BootstrapService},
        {provide: SyncServiceBase, useExisting: SyncService},
        {provide: FileSyncServiceBase, useExisting: FileSyncServiceLite},
        {provide: ControllerClientServiceBase, useExisting: ControllerClientService},

        ControllerClientService,
        // all other providers are sorted alphabetically
        ActionService,
        BootstrapService,
        EventSchedulerService,
        FileSyncServiceLite,
        OvenConnectionManagerService,
        RegisterService,
        SchedulerService,
        SettingsService,
        SyncService,
        TimeService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
