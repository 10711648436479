
        <div
            [ngStyle]="styleTop$ | async">
            <img [src]="imageTop$ | async">
            <siq-weather-forecast-landscape style="position: absolute; left: 1458px; top: 0; width: 462px;">
            </siq-weather-forecast-landscape>
        </div>

        <div style="width: 1920px;">
            <siq-full-hd-iframe [sourceUrl]="iframeUrl$ | async"
                                [reloadInterval]="iframeReloadInterval$ | async"
                                style="position: relative; float: left; width: 1458px; height: 820px">
            </siq-full-hd-iframe>

            <div style="position: relative; float: left; width: 462px; height: 820px">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
        </div>
    