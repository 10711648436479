import {Component, Input, OnInit} from '@angular/core';
import {TimeService} from '../shared/time.service';

@Component({
    selector: 'siq-clock',
    styleUrls: [
        'clock.sass',
    ],
    template: `
        <div class="clock simple" [style.border-color]="color">
            <div class="hours-container">
                <div class="hours" [ngStyle]="{transform: 'rotateZ(' + hourAngle + 'deg)'}"></div>
            </div>
            <div class="minutes-container">
                <div class="minutes" [ngStyle]="{transform: 'rotateZ(' + minuteAngle + 'deg)'}"></div>
            </div>
        </div>
    `,
})
export class ClockComponent implements OnInit {
    minuteAngle!: number;
    hourAngle!: number;
    @Input() color?: string;

    ngOnInit(): void {
        const now = TimeService.now();
        const minutes = now.minutes();
        const hours = now.hours();

        this.minuteAngle = minutes * 6;
        this.hourAngle = (hours * 30) + (minutes / 2);
    }
}
