import * as tslib_1 from "tslib";
import { captureException } from '@sentry/angular';
import * as Logger from 'js-logger';
import { Workbox } from 'workbox-window';
import { environment } from '../environments/environment';
var logger = Logger.get('service-worker-api');
var wb;
export function registerSW() {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            if ('serviceWorker' in navigator && environment.production) {
                try {
                    wb = new Workbox('service-worker.js');
                    return [2 /*return*/, wb.register()];
                }
                catch (e) {
                    wb = undefined;
                    captureException(e);
                    logger.warn('Cant register service worker');
                }
            }
            return [2 /*return*/, undefined];
        });
    });
}
export function cacheUrls(urls) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            if (wb !== undefined) {
                return [2 /*return*/, wb.messageSW({
                        type: 'CACHE_URLS',
                        payload: { urlsToCache: urls },
                    })];
            }
            if ('serviceWorker' in navigator && environment.production) {
                logger.warn('service worker not initialized');
            }
            return [2 /*return*/];
        });
    });
}
