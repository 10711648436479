/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./background.component";
var styles_BackgroundComponent = [];
var RenderType_BackgroundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackgroundComponent, data: {} });
export { RenderType_BackgroundComponent as RenderType_BackgroundComponent };
export function View_BackgroundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "shopiq-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "shopiq-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/logo_shopiq.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "shopiq-slogan"], ["src", "assets/logo_shopiq_slogan.svg"]], null, null, null, null, null))], null, null); }
export function View_BackgroundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-background", [], null, null, null, View_BackgroundComponent_0, RenderType_BackgroundComponent)), i0.ɵdid(1, 49152, null, 0, i1.BackgroundComponent, [], null, null)], null, null); }
var BackgroundComponentNgFactory = i0.ɵccf("siq-background", i1.BackgroundComponent, View_BackgroundComponent_Host_0, {}, {}, []);
export { BackgroundComponentNgFactory as BackgroundComponentNgFactory };
