import {Injectable, Injector} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {concatAll, map} from 'rxjs/operators';
import {cacheUrls} from '../../serviceworker/service-worker-api';
import {FileSyncProgress} from './file-sync.service.base';
import {SettingsService} from './settings.service';
import {SyncData, SyncServiceBase} from './sync.service.base';

@Injectable()
export class FileSyncServiceLite {

    constructor(private settings: SettingsService, private injector: Injector) {
    }

    getContentFileURL(name: string): string | undefined {
        const [id] = name.split('.');
        return this.settings.getContent(Number(id)).url;
    }

    getLayoutFileURL(name: string): string | undefined {
        const asset = this.settings.getLayoutAssets().find(a => a.id === name);
        return asset ? asset.url : undefined;
    }

    getHtmlTemplateAssetFileURL(name: string): string | undefined {
        const asset = this.injector.get(SyncServiceBase)
            .syncData.html_template_assets.find((a: { id: number; }) => a.id === Number(name));
        return asset.url;
    }

    syncContentFiles(syncData: SyncData): Observable<FileSyncProgress> {
        const usedContents = syncData.contents.filter(content => content.used);
        const totalSize = usedContents.reduce((size, content) => size + content.size, 0);
        let currentSize = 0;
        return of(...usedContents).pipe(
            map(async content => {
                await cacheUrls([content.url]);
                currentSize += content.size;
                return {currentFile: content.name, currentSize, totalSize};
            }),
            concatAll(),
        );
    }

    syncHtmlTemplateAssets(syncData: SyncData): Observable<FileSyncProgress> {
        if (!syncData.html_template_assets) {
            return from([]);
        }
        const totalSize = syncData.html_template_assets.reduce((size, asset) => size + asset.size, 0);
        let currentSize = 0;
        return of(...syncData.html_template_assets).pipe(
            map(async asset => {
                await cacheUrls([asset.url]);
                currentSize += asset.size;
                return {currentFile: asset.name, currentSize: 0, totalSize};
            }),
            concatAll(),
        );
    }

    syncLayoutFiles(syncData: SyncData): Observable<FileSyncProgress> {
        return from([]);
    }
}
