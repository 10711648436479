import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

interface Parameters {
    menu1_soup: string;
    menu1_title: string;
    menu1_description: string;
    menu1_price: string;

    menu2_soup: string;
    menu2_title: string;
    menu2_description: string;
    menu2_price: string;
}

@Component({
    selector: 'siq-stiglechner-layout',
    // language=Angular2HTML
    template: `
        <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
        <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        <div class="stiglechner-menu">
            <div>
                <span class="title-menu">Menü KLASSISCH</span>
                <span class="soup" *ngIf="(parameters$ | async)?.menu1_soup">{{ (parameters$ | async)?.menu1_soup }}</span>
                <span class="title">{{ (parameters$ | async)?.menu1_title }}</span>
                <span class="description">{{ (parameters$ | async)?.menu1_description }}</span>
                <span class="price">{{ (parameters$ | async)?.menu1_price }}</span>
            </div>
            <div>
                <span class="title-menu">Menü VEGETARISCH</span>
                <span class="soup" *ngIf="(parameters$ | async)?.menu2_soup">{{ (parameters$ | async)?.menu2_soup }}</span>
                <span class="title">{{ (parameters$ | async)?.menu2_title }}</span>
                <span class="description">{{ (parameters$ | async)?.menu2_description }}</span>
                <span class="price">{{ (parameters$ | async)?.menu2_price }}</span>
            </div>
        </div>
    `,
})
export class StiglechnerLayoutComponent extends BaseLayoutComponent {
    readonly parameters$!: Observable<Parameters>;

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}
