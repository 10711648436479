/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./day-weather-landscape.component.ngfactory";
import * as i2 from "./day-weather-landscape.component";
import * as i3 from "@angular/common";
import * as i4 from "./weather-forecast-portrait.component";
import * as i5 from "../sync/settings.service";
var styles_WeatherForecastPortraitComponent = ["[_nghost-%COMP%] {\n            display: block;\n        }"];
var RenderType_WeatherForecastPortraitComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_WeatherForecastPortraitComponent, data: {} });
export { RenderType_WeatherForecastPortraitComponent as RenderType_WeatherForecastPortraitComponent };
function View_WeatherForecastPortraitComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-day-weather-landscape", [["style", "height: 33%"]], null, null, null, i1.View_DayWeatherLandscapeComponent_0, i1.RenderType_DayWeatherLandscapeComponent)), i0.ɵdid(1, 49152, null, 0, i2.DayWeatherLandscapeComponent, [], { weatherData: [0, "weatherData"], shortTemperatureFormat: [1, "shortTemperatureFormat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.shortTemperatureFormat; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_WeatherForecastPortraitComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_WeatherForecastPortraitComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.days$)); var currVal_1 = _co.dayTrackByFn; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_WeatherForecastPortraitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-weather-forecast-portrait", [], null, null, null, View_WeatherForecastPortraitComponent_0, RenderType_WeatherForecastPortraitComponent)), i0.ɵdid(1, 180224, null, 0, i4.WeatherForecastPortraitComponent, [i5.SettingsService], null, null)], null, null); }
var WeatherForecastPortraitComponentNgFactory = i0.ɵccf("siq-weather-forecast-portrait", i4.WeatherForecastPortraitComponent, View_WeatherForecastPortraitComponent_Host_0, { shortTemperatureFormat: "shortTemperatureFormat" }, {}, []);
export { WeatherForecastPortraitComponentNgFactory as WeatherForecastPortraitComponentNgFactory };
