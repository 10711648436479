import {Injectable} from '@angular/core';
import * as moment from 'moment';

import {SettingsService} from '../sync/settings.service';
import {TimeService} from './time.service';

export interface Action {
    id: number;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;
    timeStart: moment.Moment;
    timeEnd: moment.Moment;
    weekRepeat: number;
    priority: number;
    parameters: any;
}

@Injectable()
export class ActionService {
    constructor(private settings: SettingsService) {
    }

    getActions(atTime: moment.Moment = TimeService.now()): Array<Action> {
        return this.settings.getActions().map(action => {
            const mappedAction = {...action};

            mappedAction.dateStart = moment(action.dateStart.substr(0, 10), 'YYYY-MM-DD');
            mappedAction.dateEnd = moment(action.dateEnd.substr(0, 10), 'YYYY-MM-DD');
            mappedAction.timeStart = parseTime(action.timeStart, atTime);
            mappedAction.timeEnd = parseTime(action.timeEnd, atTime);

            return mappedAction;
        });
    }
}

function parseTime(timeStr: string, atTime: moment.Moment = TimeService.now()): moment.Moment {
    const timeParts = timeStr.substr(11, 5).split(':'); // ['13', '00']
    return atTime.clone().set({hour: parseInt(timeParts[0], 10), minute: parseInt(timeParts[1], 10), second: 0});
}
