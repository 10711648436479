import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DayWeatherLandscapeComponent} from '../weather-forecast/day-weather-landscape.component';
import {DayWeatherPortraitComponent} from '../weather-forecast/day-weather-portrait.component';
import {WeatherForecastLandscapeComponent} from '../weather-forecast/weather-forecast-landscape.component';
import {WeatherForecastPortraitComponent} from '../weather-forecast/weather-forecast-portrait.component';
import {CarwashTimeComponent} from './carwash-time.component';
import {ClockComponent} from './clock.component';
import {DateComponent} from './date.component';
import {FullHdIframeComponent} from './full-hd-iframe.component';
import {ImagePlayerComponent} from './image-player.component';
import {CupcinoLayoutComponent} from './layout/cupcino-layout.component';
import {FullScreenLayoutComponent} from './layout/full-screen-layout.component';
import {HellaAccessControlLayoutComponent} from './layout/hella-access-control-layout.component';
import {HtmlOverlayLayoutComponent} from './layout/html-overlay-layout.component';
import {ShopiqStandardOverlayLayoutComponent} from './layout/shopiq-standard-overlay-layout.component';
import {ShopiqStdLayoutComponent} from './layout/shopiq-std-layout.component';
import {StiglechnerLayoutComponent} from './layout/stiglechner-layout.component';
import {WashtecNewsLayoutComponent} from './layout/washtec-news-layout.component';
import {WashtecWeatherLayoutComponent} from './layout/washtec-weather-layout.component';
import {WeatherNewsLandscapeLayoutComponent} from './layout/weather-news-landscape-layout.component';
import {WeatherNewsPortraitLayoutComponent} from './layout/weather-news-portrait-layout.component';
import {NewsTickerComponent} from './news-ticker.component';
import {PlaylistPlayerComponent} from './playlist-player.component';
import {VideoPlayerComponent} from './video-player.component';
import {VideoTagPlayerComponent} from './video-tag-player.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CarwashTimeComponent,
        ClockComponent,
        CupcinoLayoutComponent,
        DateComponent,
        DayWeatherLandscapeComponent,
        DayWeatherPortraitComponent,
        FullHdIframeComponent,
        FullScreenLayoutComponent,
        HellaAccessControlLayoutComponent,
        HtmlOverlayLayoutComponent,
        ImagePlayerComponent,
        NewsTickerComponent,
        PlaylistPlayerComponent,
        ShopiqStandardOverlayLayoutComponent,
        ShopiqStdLayoutComponent,
        StiglechnerLayoutComponent,
        VideoPlayerComponent,
        VideoTagPlayerComponent,
        WashtecNewsLayoutComponent,
        WashtecWeatherLayoutComponent,
        WeatherForecastLandscapeComponent,
        WeatherForecastPortraitComponent,
        WeatherNewsLandscapeLayoutComponent,
        WeatherNewsPortraitLayoutComponent,
    ],
    exports: [
        CarwashTimeComponent,
        ClockComponent,
        CupcinoLayoutComponent,
        DateComponent,
        DayWeatherLandscapeComponent,
        DayWeatherPortraitComponent,
        FullHdIframeComponent,
        FullScreenLayoutComponent,
        HellaAccessControlLayoutComponent,
        HtmlOverlayLayoutComponent,
        ImagePlayerComponent,
        NewsTickerComponent,
        PlaylistPlayerComponent,
        ShopiqStandardOverlayLayoutComponent,
        ShopiqStdLayoutComponent,
        StiglechnerLayoutComponent,
        VideoTagPlayerComponent,
        WashtecNewsLayoutComponent,
        WashtecWeatherLayoutComponent,
        WeatherForecastLandscapeComponent,
        WeatherForecastPortraitComponent,
        WeatherNewsLandscapeLayoutComponent,
        WeatherNewsPortraitLayoutComponent,
    ],
    entryComponents: [
        // We need all layouts to be defined as entryComponents because they are instantiated dynamically
        CupcinoLayoutComponent,
        FullScreenLayoutComponent,
        HellaAccessControlLayoutComponent,
        HtmlOverlayLayoutComponent,
        ShopiqStandardOverlayLayoutComponent,
        ShopiqStdLayoutComponent,
        StiglechnerLayoutComponent,
        WashtecNewsLayoutComponent,
        WashtecWeatherLayoutComponent,
        WeatherNewsLandscapeLayoutComponent,
        WeatherNewsPortraitLayoutComponent,
    ],
})
export class PlayerModule {
}
