/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/background.component.ngfactory";
import * as i2 from "../shared/background.component";
import * as i3 from "../shared/spinner.component.ngfactory";
import * as i4 from "../shared/spinner.component";
import * as i5 from "@angular/common";
import * as i6 from "./sync-loader-page.component.lite";
import * as i7 from "./sync.service.base";
import * as i8 from "@angular/router";
import * as i9 from "../platforms/platform";
var styles_SyncLoaderPageComponent = ["[_nghost-%COMP%] {\n            position: fixed;\n            width: 100%;\n            height: 100%;\n            font-family: 'Century Gothic', sans-serif;\n        }\n\n        .progress[_ngcontent-%COMP%] {\n            position: fixed;\n            width: 100%;\n            height: 385px;\n            bottom: 0;\n            display: -webkit-flex;\n            flex-direction: column;\n            align-items: center;\n        }\n\n        h2[_ngcontent-%COMP%] {\n            text-align: center;\n        }"];
var RenderType_SyncLoaderPageComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SyncLoaderPageComponent, data: {} });
export { RenderType_SyncLoaderPageComponent as RenderType_SyncLoaderPageComponent };
export function View_SyncLoaderPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-background", [], null, null, null, i1.View_BackgroundComponent_0, i1.RenderType_BackgroundComponent)), i0.ɵdid(1, 49152, null, 0, i2.BackgroundComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "siq-spinner", [], null, null, null, i3.View_SiqSpinnerComponent_0, i3.RenderType_SiqSpinnerComponent)), i0.ɵdid(4, 49152, null, 0, i4.SiqSpinnerComponent, [], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "#fea621"; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.progress)); _ck(_v, 5, 0, currVal_1); }); }
export function View_SyncLoaderPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-sync-loader-page", [], null, null, null, View_SyncLoaderPageComponent_0, RenderType_SyncLoaderPageComponent)), i0.ɵdid(1, 114688, null, 0, i6.SyncLoaderPageComponent, [i7.SyncServiceBase, i8.Router, i9.AbstractPlatform], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SyncLoaderPageComponentNgFactory = i0.ɵccf("siq-sync-loader-page", i6.SyncLoaderPageComponent, View_SyncLoaderPageComponent_Host_0, {}, {}, []);
export { SyncLoaderPageComponentNgFactory as SyncLoaderPageComponentNgFactory };
