
        <i class="fal" [ngClass]="weatherData.icon"></i>
        <br>
        <span>{{ weatherData.date | date:'EEEEEE dd.MM.' }}</span>
        <div>
            <span *ngIf="shortTemperatureFormat">
                {{ weatherData.maximum_temperature | number:'1.0-0' }}°C/{{ weatherData.minimum_temperature | number:'1.0-0' }}°C
            </span>
            <span *ngIf="!shortTemperatureFormat">
                {{ weatherData.maximum_temperature | number:'1.0-1' }}°C / {{ weatherData.minimum_temperature | number:'1.0-1' }}
                °C
            </span>
        </div>
    