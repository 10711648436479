import {EventEmitter, Output} from '@angular/core';

export abstract class BaseVideoPlayerComponent {
    @Output() readonly completed = new EventEmitter<void>();

    /**
     * It is allowed to call this multiple times in a row to enable pre-buffering.
     */
    abstract prepare(filename: string): Promise<boolean>;

    abstract play(): Promise<boolean>;

    abstract stop(): void;
}
