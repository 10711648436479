import {Injectable} from '@angular/core';
import {AbstractPlatform} from '../platforms/platform';
import {SettingsService} from '../sync/settings.service';
import {SyncServiceBase} from '../sync/sync.service.base';
import {ControllerClientServiceBase} from './controller-client.service.base';
import {TimeService} from './time.service';

@Injectable()
export class ControllerClientService extends ControllerClientServiceBase {

    constructor(platform: AbstractPlatform,
                settings: SettingsService,
                sync: SyncServiceBase,
                timeService: TimeService) {
        super(platform, settings, sync, timeService);
    }
}
