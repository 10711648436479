import * as tslib_1 from "tslib";
import { combineLatest, merge } from 'rxjs';
import { first, map, pluck, share, switchMap } from 'rxjs/operators';
import { WashtecClient } from '../../connect/washtec-client';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var WashtecNewsLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WashtecNewsLayoutComponent, _super);
    function WashtecNewsLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.iframeUrl$ = _this.parameters$.pipe(pluck('iframe_url'));
        _this.timeLeft$ = createCarwashRemainingTime$(_this.parameters$, 'carwash_left_ip_address');
        _this.colorLeft$ = createCarwashBackgroundColor$(_this.parameters$, _this.timeLeft$);
        _this.timeRight$ = createCarwashRemainingTime$(_this.parameters$, 'carwash_right_ip_address');
        _this.colorRight$ = createCarwashBackgroundColor$(_this.parameters$, _this.timeRight$);
        return _this;
    }
    return WashtecNewsLayoutComponent;
}(BaseLayoutComponent));
export { WashtecNewsLayoutComponent };
function createCarwashRemainingTime$(parameters$, ipKey) {
    return parameters$.pipe(pluck(ipKey), switchMap(function (ip) { return WashtecClient.getTime$(ip); }), share());
}
function createCarwashBackgroundColor$(parameters$, time$) {
    return merge(
    // To have earlier a bg-color.
    parameters$.pipe(first(), pluck('carwash_background_color_busy')), combineLatest([time$, parameters$]).pipe(map(function (_a) {
        var time = _a[0], parameters = _a[1];
        return time === 0 ? parameters.carwash_background_color_free : parameters.carwash_background_color_busy;
    })));
}
