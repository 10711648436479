
        <img style="position: absolute; left: 0; top: 0; width: 1080px; height: 1920px;" [src]="imageSrc$ | async">

        <span *ngIf="(parameters$ | async)?.show_detailed_count" class="detailed-count">
            {{ peopleCount$ | async }}
            von {{ (parameters$|async)?.people_count_legal }} + {{ (parameters$|async)?.employee_count }}
        </span>

        <span *ngIf="go$ | async" class="allowed-people-left">
            {{ allowedPeopleLeft$ | async }}
        </span>

        <div style="position: absolute; left: 0; top: 1312px; width: 1080px; height: 608px;">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>
    