import * as tslib_1 from "tslib";
import { AbstractPlatform } from '../platforms/platform';
import { SettingsService } from '../sync/settings.service';
import { SyncServiceBase } from '../sync/sync.service.base';
import { ControllerClientServiceBase } from './controller-client.service.base';
import { TimeService } from './time.service';
var ControllerClientService = /** @class */ (function (_super) {
    tslib_1.__extends(ControllerClientService, _super);
    function ControllerClientService(platform, settings, sync, timeService) {
        return _super.call(this, platform, settings, sync, timeService) || this;
    }
    return ControllerClientService;
}(ControllerClientServiceBase));
export { ControllerClientService };
