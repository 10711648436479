import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import * as Logger from 'js-logger';
import { ObservableInput } from 'observable-input/lib';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingKeys } from '../../sync/settings.service';
import { BaseVideoPlayerComponent } from '../base-video-player.component';
import { ImagePlayerComponent } from '../image-player.component';
import { ContentType } from '../scheduler.service';
var logger = Logger.get('base-layout');
var BaseLayoutComponent = /** @class */ (function () {
    function BaseLayoutComponent(settings, fileSync) {
        this.settings = settings;
        this.fileSync = fileSync;
        this.completed = new EventEmitter();
        this.parameters$ = this.layout.pipe(map(function (layout) { return layout.parameters; }));
        // Emits every time next playlist entry is played so that the layout image cache buster takes effect
        this.parametersRefreshTrigger = new Subject();
    }
    BaseLayoutComponent.prototype.play = function (element) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var playSuccess;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.video === undefined || this.image === undefined) {
                            throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
                        }
                        this.parametersRefreshTrigger.next();
                        if (!(element.type === ContentType.VIDEO || element.type === ContentType.STREAM)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.video.play()];
                    case 1:
                        playSuccess = _a.sent();
                        this.image.stop();
                        return [2 /*return*/, playSuccess];
                    case 2: return [4 /*yield*/, this.image.play()];
                    case 3:
                        _a.sent();
                        // Stop video explicitly to avoid last frame to be displayed when next video is started.
                        // (especially on tizen without video pre-buffering)
                        this.video.stop();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    BaseLayoutComponent.prototype.prepare = function (nextElement) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.video === undefined || this.image === undefined) {
                    throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
                }
                if (nextElement.type === ContentType.VIDEO || nextElement.type === ContentType.STREAM) {
                    return [2 /*return*/, this.video.prepare(nextElement.source)];
                }
                else {
                    this.image.prepare(nextElement);
                    return [2 /*return*/, true];
                }
                return [2 /*return*/];
            });
        });
    };
    BaseLayoutComponent.prototype.stop = function () {
        logger.debug('Stopping layout.');
        if (this.video === undefined || this.image === undefined) {
            throw new Error(BaseLayoutComponent.MISSING_TAG_MESSAGE);
        }
        this.video.stop();
        this.image.stop();
    };
    BaseLayoutComponent.prototype.getLayoutImage$ = function (key) {
        var _this = this;
        return combineLatest([this.parameters$, this.parametersRefreshTrigger]).pipe(map(function (_a) {
            var parameters = _a[0];
            var customerId = _this.settings.get(SettingKeys.CUSTOMER_ID);
            var name = parameters[key];
            var nameWithoutCustomerId = name.replace(new RegExp("^" + customerId + "/"), '');
            return "" + _this.fileSync.getLayoutFileURL(nameWithoutCustomerId);
        }));
    };
    BaseLayoutComponent.MISSING_TAG_MESSAGE = 'Video or image player are undefined. ' +
        'Are they tagged with #videoPlayer and #imagePlayer?';
    tslib_1.__decorate([
        ObservableInput(),
        tslib_1.__metadata("design:type", Observable)
    ], BaseLayoutComponent.prototype, "layout", void 0);
    return BaseLayoutComponent;
}());
export { BaseLayoutComponent };
