import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import { VideoTagPlayerComponent } from './video-tag-player.component';
var logger = Logger.get('video-tag-player');
var VideoPlayerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VideoPlayerComponent, _super);
    function VideoPlayerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return VideoPlayerComponent;
}(VideoTagPlayerComponent));
export { VideoPlayerComponent };
