import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {bufferCount, filter, map} from 'rxjs/operators';

import {OvenConnectionManagerService} from '../connect/oven-connection-manager.service';
import {DeckStatus} from '../connect/oven-data-types';
import {Condition, ConditionService} from './types';

interface BakeEndCondition extends Condition {
    deck_id: number;
    programs: Array<number>;
    time_offset: number;
}

@Injectable()
export class BakeEndConditionService implements ConditionService {
    constructor(private ovenManager: OvenConnectionManagerService) {}

    isAssociatedCondition(condition: BakeEndCondition): boolean {
        return condition.deck_id !== undefined;  // With deck_id it is a bake end condition.
    }

    getConditionMatch$(condition: BakeEndCondition): Observable<void> {
        return this.ovenManager.getDeckStatus$(condition.deck_id).pipe(
            bufferCount(2, 1),
            filter(deckStatus => BakeEndConditionService.checkDeck(condition, deckStatus[1], deckStatus[0])),
            map(() => undefined),
        );
    }

    private static checkDeck(condition: BakeEndCondition, deckStatus: DeckStatus, lastDeckStatus: DeckStatus): boolean {
        // Program is correct and program number didn't change.
        return condition.programs.includes(deckStatus.program)
            && deckStatus.program === lastDeckStatus.program
            // remaining bake time >= offset before, and < offset now
            && lastDeckStatus.remainingBakingTime > condition.time_offset
            && condition.time_offset >= deckStatus.remainingBakingTime;

    }
}
