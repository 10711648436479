import {Component} from '@angular/core';
import {Router} from '@angular/router';
import * as Logger from 'js-logger';

import {AbstractPlatform} from '../platforms/platform';
import {WebPlatform} from '../platforms/platform-web';
import {SyncLoaderPageBaseComponent} from './sync-loader-page.component.base';
import {SyncServiceBase} from './sync.service.base';

const logger = Logger.get('sync-loader-page');

@Component({
    selector: 'siq-sync-loader-page',
    styles: [`
        :host {
            position: fixed;
            width: 100%;
            height: 100%;
            font-family: 'Century Gothic', sans-serif;
        }

        .progress {
            position: fixed;
            width: 100%;
            height: 385px;
            bottom: 0;
            display: -webkit-flex;
            flex-direction: column;
            align-items: center;
        }

        h2 {
            text-align: center;
        }
    `],
    template: `
        <siq-background></siq-background>

        <div class="progress">
            <siq-spinner [color]="'#fea621'"></siq-spinner>
            <h2 [innerHtml]="progress | async"></h2>
        </div>
    `,
})
export class SyncLoaderPageComponent extends SyncLoaderPageBaseComponent {
    private platform: WebPlatform;

    constructor(sync: SyncServiceBase, router: Router, platform: AbstractPlatform) {
        super(sync, router);

        this.platform = platform as WebPlatform;
    }

    protected routerNavigatePlayerOnSyncError(): void {
        logger.debug('Navigating immediately to player.');
        return super.routerNavigatePlayerOnSyncError();
    }
}
