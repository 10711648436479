import * as Logger from 'js-logger';
import {Subject} from 'rxjs';
import {takeUntil, timeout} from 'rxjs/operators';

import {
    ConnectionCloseEvent,
    ConnectionOpenEvent,
    DataReceivedEvent,
    DeckStatus,
    OvenClient,
    OvenEvent,
    OvenStatus,
} from './oven-data-types';

const logger = Logger.get('wachtel-client');

export class WachtelClient implements OvenClient {
    private static RECONNECT_MAX_DELAY = 60 * 1000;
    private static RECONNECT_INITIAL_DELAY = 5 * 1000;
    private static CONNECTION_TIMEOUT = 15 * 1000;
    private static RECEIVE_TIMEOUT = 20 * 1000;

    events = new Subject<OvenEvent>();
    private socket?: WebSocket;
    private closing = false;
    private reconnectDelay = WachtelClient.RECONNECT_INITIAL_DELAY;
    private connectionTimeoutTimer?: any;
    private reconnectTimer?: any;
    private messages = new Subject<MessageEvent>();
    private closeEvents = new Subject<CloseEvent>();

    constructor(private host: string) {
        this.closeEvents.subscribe(this.onClose.bind(this));
    }

    connect(): void {
        if (this.connectionTimeoutTimer !== undefined) {
            console.warn('Already connecting.');
            return;
        }

        this.connectionTimeoutTimer = setTimeout(() => {
            logger.debug(`Timeout while connecting to ${this.host}. Closing connection.`);
            this.connectionTimeoutTimer = undefined;
            this.forceClose();
        }, WachtelClient.CONNECTION_TIMEOUT);

        this.closing = false;
        this.socket = new WebSocket(`ws://${this.host}:9080/liveTicker`);
        this.socket.onmessage = this.messages.next.bind(this.messages);
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.closeEvents.next.bind(this.closeEvents);
    }

    disconnect(): void {
        logger.debug('Disconnect called for host: ' + this.host);
        this.closing = true;

        clearTimeout(this.reconnectTimer);
        this.reconnectTimer = undefined;

        if (this.socket !== undefined) {
            this.socket.close();
            this.socket = undefined;
        }
    }

    private async onOpen(): Promise<void> {
        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;

        // Close connection when nothing received within timout.
        this.messages
            .pipe(
                timeout(WachtelClient.RECEIVE_TIMEOUT),
                takeUntil(this.closeEvents),
            )
            .subscribe(
                this.onMessage.bind(this),
                () => {
                    logger.debug(`Connection to ${this.host} timed out. Closing connection.`);
                    this.forceClose();
                },
            );
        this.events.next(new ConnectionOpenEvent());
    }

    private async onMessage(e: MessageEvent): Promise<void> {
        const oven = new OvenStatus();

        for (const wachtelDeck of JSON.parse(e.data).Herde) {
            oven.decks.push(new DeckStatus(wachtelDeck.Backzeit, wachtelDeck.BackprogrammNummer));
        }
        this.events.next(new DataReceivedEvent(oven));
    }

    private onClose(): void {
        this.socket = undefined;
        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;

        this.events.next(new ConnectionCloseEvent());

        if (!this.closing) {
            this.reconnect();
        }
    }

    private forceClose(): void {
        if (this.socket !== undefined) {
            logger.debug('Forcing WebSocket close for host: ' + this.host);
            this.socket.onmessage = () => undefined;
            this.socket.onopen = () => undefined;
            this.socket.onclose = () => undefined;

            this.socket.close();
        }

        this.closeEvents.next();
    }

    private reconnect(): void {
        if (this.reconnectTimer !== undefined) {
            logger.warn('Reconnect triggered while already reconnecting.');
            return;
        }

        this.reconnectTimer = setTimeout(() => {
            this.reconnectTimer = undefined;
            this.reconnectDelay = Math.min(WachtelClient.RECONNECT_MAX_DELAY, this.reconnectDelay * 2);
            this.connect();
        }, this.reconnectDelay);
    }
}
