import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import { Subject, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NodeClientService } from '../shared/node-client-service';
import { SettingKeys, SettingsService } from '../sync/settings.service';
var logger = Logger.get('playlist-sync');
var ScreenSyncCounter = /** @class */ (function () {
    function ScreenSyncCounter() {
        this.count = 0;
        this.lastSync = 0;
    }
    ScreenSyncCounter.prototype.incrementCount = function () {
        this.count++;
        this.lastSync = window.performance.now();
        return this;
    };
    return ScreenSyncCounter;
}());
var PlaylistSyncService = /** @class */ (function () {
    function PlaylistSyncService(settings, nodeClient) {
        this.settings = settings;
        this.nodeClient = nodeClient;
        this.receivedMissingScreen = new Subject();
        this.screenSyncCounters = new Map();
        this.missingScreens = [];
        this.nodeClient.playlistSyncEvent.subscribe(this.onSyncBroadcastReceived.bind(this));
    }
    PlaylistSyncService.prototype.waitForScreenGroup = function () {
        var _this = this;
        if (this.currentSyncWaitInfos !== undefined) {
            this.currentSyncWaitInfos.reject('Wait called during wait.');
            clearInterval(this.currentSyncWaitInfos.broadcastInterval);
            this.currentSyncWaitInfos = undefined;
        }
        // Get own screen id and screen ids of screens to sync with
        var ownScreenId = this.settings.get(SettingKeys.SCREEN_ID);
        var syncScreenIds = this.settings.get(SettingKeys.PLAYLIST_SYNCHRONIZATION_SCREENS, []);
        if (ownScreenId === undefined || syncScreenIds.length === 0) {
            logger.debug('Not member of sync screen group.');
            return Promise.resolve();
        }
        // discard outdated sync-messages
        this.removeTimedOutSyncScreens(syncScreenIds, 7);
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentSyncWaitInfos = {
                            resolve: resolve,
                            reject: reject,
                            broadcastInterval: setInterval(this.sendBroadcast.bind(this, ownScreenId), 5000),
                        };
                        return [4 /*yield*/, timer(100).pipe(first()).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.sendBroadcast(ownScreenId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    PlaylistSyncService.prototype.cancelWaitForScreenGroup = function (reason) {
        if (reason === void 0) { reason = ''; }
        if (this.currentSyncWaitInfos === undefined) {
            return;
        }
        this.currentSyncWaitInfos.reject(reason);
        clearInterval(this.currentSyncWaitInfos.broadcastInterval);
        this.currentSyncWaitInfos = undefined;
    };
    /**
     * Called every time we receive a playlist synchronization broadcast from another or our *own* screen.
     * We handle our own screen the same way as remote screens
     * to avoid timing problems that might come with special handling of our own screen.
     */
    PlaylistSyncService.prototype.onSyncBroadcastReceived = function (screenId) {
        var syncScreenIds = this.settings.get(SettingKeys.PLAYLIST_SYNCHRONIZATION_SCREENS, []);
        if (!syncScreenIds.includes(screenId)) {
            // There can be multiple synchronization groups in the same network,
            // not all broadcasts we receive are relevant for us
            return;
        }
        logger.debug('Received sync broadcast from: ' + screenId);
        // Remove screens from missingScreens that got removed from our sync group
        this.missingScreens = setIntersect(this.missingScreens, syncScreenIds);
        // if we received a broadcast from a missing screen it's no longer missing
        // and we notify the player about it
        if (this.missingScreens.includes(screenId)) {
            this.missingScreens = this.missingScreens.filter(function (id) { return id !== screenId; });
            this.receivedMissingScreen.next(screenId);
        }
        // Get or create a screen sync counter and increment its value
        var currentScreenSyncCounter = (this.screenSyncCounters.get(screenId)
            || new ScreenSyncCounter()).incrementCount();
        this.screenSyncCounters.set(screenId, currentScreenSyncCounter);
        // We are done now if we are not waiting for our screen group
        if (this.currentSyncWaitInfos === undefined) {
            return;
        }
        // If we have received a sync broadcast from each "active" screen
        // we can resolve the wait and continue playback
        var activeScreens = setSubtract(syncScreenIds, this.missingScreens);
        if (setSubtract(activeScreens, Array.from(this.screenSyncCounters.keys())).length === 0) {
            if (this.missingScreens.length === 0) {
                logger.debug('Received sync from all screens in group.');
            }
            else {
                logger.debug('Received sync from all active screens in group, missing screens: ' + this.missingScreens);
            }
            this.resolve(this.currentSyncWaitInfos);
            return;
        }
        // At this point we have not yet received a broadcast from each "active" screen
        // The only way that we can now resolve the wait if there is a screen which we haven't yet marked as missing
        // We only want to mark new screens as missing if we have received at least 2 broadcasts
        if (currentScreenSyncCounter.count < 2) {
            return;
        }
        // We only want to mark new screens as missing if there aren't other screens who are only behind by one
        // Either these screens will catch up or they won't catch up
        // and be marked as missing the next time we end up here
        var screensAvailableThatCouldCatchUp = Array
            .from(this.screenSyncCounters.values())
            .some(function (counter) { return counter.count === currentScreenSyncCounter.count - 1; });
        if (screensAvailableThatCouldCatchUp) {
            return;
        }
        // Update the missingScreens = allScreens - onlineScreens
        // onlineScreen = screens that have at least the same count as the one we just received
        var onlineScreenIds = Array
            .from(this.screenSyncCounters.entries())
            .filter(function (_a) {
            var id = _a[0], screen = _a[1];
            return screen.count >= currentScreenSyncCounter.count;
        })
            .map(function (_a) {
            var id = _a[0];
            return id;
        });
        this.missingScreens = setSubtract(syncScreenIds, onlineScreenIds);
        logger.info('Received sync not from all screens in group, missing screens: ' + this.missingScreens);
        this.resolve(this.currentSyncWaitInfos);
    };
    PlaylistSyncService.prototype.sendBroadcast = function (screenId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.nodeClient.sendUdpBroadcast(environment.nodeServerPort, { playlist_sync: { screen_id: screenId } })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        logger.debug('Failed so send sync broadcast: ' + e_1.message);
                        // Wait for screen group may have been canceled while awaiting send broadcast.
                        if (this.currentSyncWaitInfos === undefined) {
                            return [2 /*return*/];
                        }
                        // If we can't even send our own broadcast we just start playing ... no sense in waiting
                        this.resolve(this.currentSyncWaitInfos);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PlaylistSyncService.prototype.resolve = function (currentSyncWaitInfos) {
        currentSyncWaitInfos.resolve();
        this.screenSyncCounters.clear();
        clearInterval(currentSyncWaitInfos.broadcastInterval);
        this.currentSyncWaitInfos = undefined;
    };
    PlaylistSyncService.prototype.removeTimedOutSyncScreens = function (syncScreenIds, timeout) {
        // We use window.performance here because we need a always monotonic clock
        var now = window.performance.now();
        var screens = Array.from(this.screenSyncCounters.entries());
        // Clear the counters and only add back those that we received in the last timeout seconds
        this.screenSyncCounters.clear();
        for (var _i = 0, screens_1 = screens; _i < screens_1.length; _i++) {
            var _a = screens_1[_i], id = _a[0], screen_1 = _a[1];
            if (!syncScreenIds.includes(id) || now - screen_1.lastSync > timeout * 1000) {
                continue;
            }
            screen_1.count = 1;
            this.screenSyncCounters.set(id, screen_1);
        }
    };
    return PlaylistSyncService;
}());
export { PlaylistSyncService };
// returns a - b
function setSubtract(a, b) {
    return a.filter(function (x) { return !b.includes(x); });
}
// returns a ∩ b
function setIntersect(a, b) {
    return a.filter(function (x) { return b.includes(x); });
}
