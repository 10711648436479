import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgSpinKitModule} from 'ng-spin-kit';
import {IntroPageComponent} from './platforms/intro-page.component';
import {BackgroundComponent} from './shared/background.component';
import {SafeHtmlPipe} from './shared/safe-html.pipe';
import {SiqSpinnerComponent} from './shared/spinner.component';
import {SyncLoaderPageComponent} from './sync/sync-loader-page.component.lite';

@NgModule({
    imports: [
        CommonModule,
        NgSpinKitModule,
    ],
    declarations: [
        BackgroundComponent,
        SafeHtmlPipe,
        SiqSpinnerComponent,
        IntroPageComponent,
        SyncLoaderPageComponent,
    ],
    exports: [
        BackgroundComponent,
        SafeHtmlPipe,
        SiqSpinnerComponent,
        IntroPageComponent,
        SyncLoaderPageComponent,
    ],
})
export class SharedModule {}
