/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/ng-spin-kit/dist/spinner/wave.component.ngfactory";
import * as i2 from "ng-spin-kit/dist/spinner/wave.component";
import * as i3 from "./spinner.component";
var styles_SiqSpinnerComponent = ["[_nghost-%COMP%]     .wave-spinner {\n            width: 75px !important;\n            height: 60px !important;\n        }\n\n        [_nghost-%COMP%]     sk-wave .wave-spinner > div {\n            width: 9px !important;\n            margin-right: 6px !important;\n        }"];
var RenderType_SiqSpinnerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SiqSpinnerComponent, data: {} });
export { RenderType_SiqSpinnerComponent as RenderType_SiqSpinnerComponent };
export function View_SiqSpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sk-wave", [], null, null, null, i1.View_WaveComponent_0, i1.RenderType_WaveComponent)), i0.ɵdid(1, 180224, null, 0, i2.WaveComponent, [], { color: [0, "color"], isRunning: [1, "isRunning"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; var currVal_1 = _co.isRunning; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SiqSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-spinner", [], null, null, null, View_SiqSpinnerComponent_0, RenderType_SiqSpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i3.SiqSpinnerComponent, [], null, null)], null, null); }
var SiqSpinnerComponentNgFactory = i0.ɵccf("siq-spinner", i3.SiqSpinnerComponent, View_SiqSpinnerComponent_Host_0, { color: "color", isRunning: "isRunning" }, {}, []);
export { SiqSpinnerComponentNgFactory as SiqSpinnerComponentNgFactory };
