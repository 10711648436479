/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./date.component";
var styles_DateComponent = [".date[_ngcontent-%COMP%] {\n            padding: 10px 0 5px 15px;\n            margin-top: 5px;\n            color: white;\n            font-size: 24px;\n            text-align: center;\n            width: 120px;\n            float: left;\n        }\n\n        .date[_ngcontent-%COMP%]   .year[_ngcontent-%COMP%] {\n            font-size: 22px;\n        }"];
var RenderType_DateComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DateComponent, data: {} });
export { RenderType_DateComponent as RenderType_DateComponent };
export function View_DateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "weekday"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "day"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "year"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getWeekday(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getDate(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.getYear(); _ck(_v, 8, 0, currVal_2); }); }
export function View_DateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-date", [], null, null, null, View_DateComponent_0, RenderType_DateComponent)), i0.ɵdid(1, 49152, null, 0, i1.DateComponent, [], null, null)], null, null); }
var DateComponentNgFactory = i0.ɵccf("siq-date", i1.DateComponent, View_DateComponent_Host_0, {}, {}, []);
export { DateComponentNgFactory as DateComponentNgFactory };
