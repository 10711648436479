/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../video-player.component.ngfactory";
import * as i2 from "../video-player.component";
import * as i3 from "../image-player.component.ngfactory";
import * as i4 from "../image-player.component";
import * as i5 from "../full-hd-iframe.component.ngfactory";
import * as i6 from "../full-hd-iframe.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "../../weather-forecast/weather-forecast-portrait.component.ngfactory";
import * as i10 from "../../weather-forecast/weather-forecast-portrait.component";
import * as i11 from "../../sync/settings.service";
import * as i12 from "./weather-news-portrait-layout.component";
import * as i13 from "../../sync/file-sync.service.base";
var styles_WeatherNewsPortraitLayoutComponent = [".playlist[_ngcontent-%COMP%] {\n            position: relative;\n            height: 608px;\n        }\n\n        siq-full-hd-iframe[_ngcontent-%COMP%] {\n            height: 608px;\n        }\n\n        .bottom[_ngcontent-%COMP%] {\n            position: relative;\n            height: 704px;\n        }\n\n        siq-weather-forecast-portrait[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 10%;\n            width: 50%;\n            height: 80%;\n            font-size: 40px;\n        }"];
var RenderType_WeatherNewsPortraitLayoutComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_WeatherNewsPortraitLayoutComponent, data: {} });
export { RenderType_WeatherNewsPortraitLayoutComponent as RenderType_WeatherNewsPortraitLayoutComponent };
export function View_WeatherNewsPortraitLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { video: 0 }), i0.ɵqud(671088640, 2, { image: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "playlist"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "siq-video-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VideoPlayerComponent_0, i1.RenderType_VideoPlayerComponent)), i0.ɵdid(4, 49152, [[1, 4], ["videoPlayer", 4]], 0, i2.VideoPlayerComponent, [], null, { completed: "completed" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "siq-image-player", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.completed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ImagePlayerComponent_0, i3.RenderType_ImagePlayerComponent)), i0.ɵdid(6, 49152, [[2, 4], ["imagePlayer", 4]], 0, i4.ImagePlayerComponent, [i0.ChangeDetectorRef], null, { completed: "completed" }), (_l()(), i0.ɵeld(7, 0, null, null, 3, "siq-full-hd-iframe", [], null, null, null, i5.View_FullHdIframeComponent_0, i5.RenderType_FullHdIframeComponent)), i0.ɵdid(8, 245760, null, 0, i6.FullHdIframeComponent, [i0.ElementRef, i7.DomSanitizer], { sourceUrl: [0, "sourceUrl"], reloadInterval: [1, "reloadInterval"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 2, "siq-weather-forecast-portrait", [], [[4, "color", null]], null, null, i9.View_WeatherForecastPortraitComponent_0, i9.RenderType_WeatherForecastPortraitComponent)), i0.ɵdid(15, 180224, null, 0, i10.WeatherForecastPortraitComponent, [i11.SettingsService], { shortTemperatureFormat: [0, "shortTemperatureFormat"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.iframeUrl$)); var currVal_1 = i0.ɵunv(_v, 8, 1, i0.ɵnov(_v, 10).transform(_co.iframeReloadInterval$)); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_4 = true; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.imageBottom$)); _ck(_v, 12, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 16).transform(_co.weatherColor$)); _ck(_v, 14, 0, currVal_3); }); }
export function View_WeatherNewsPortraitLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-weather-news-portrait-layout", [], null, null, null, View_WeatherNewsPortraitLayoutComponent_0, RenderType_WeatherNewsPortraitLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i12.WeatherNewsPortraitLayoutComponent, [i11.SettingsService, i13.FileSyncServiceBase], null, null)], null, null); }
var WeatherNewsPortraitLayoutComponentNgFactory = i0.ɵccf("siq-weather-news-portrait-layout", i12.WeatherNewsPortraitLayoutComponent, View_WeatherNewsPortraitLayoutComponent_Host_0, { layout: "layout" }, { completed: "completed" }, []);
export { WeatherNewsPortraitLayoutComponentNgFactory as WeatherNewsPortraitLayoutComponentNgFactory };
