/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./carwash-time.component";
var styles_CarwashTimeComponent = ["[_nghost-%COMP%] {\n            color: white;\n            text-align: center;\n            font-size: 112px;\n            font-weight: bold;\n            line-height: 85%;\n        }\n\n        .unit[_ngcontent-%COMP%] {\n            font-size: 80px;\n            vertical-align: baseline;\n        }\n\n        span[_ngcontent-%COMP%] {\n            vertical-align: middle;\n        }"];
var RenderType_CarwashTimeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CarwashTimeComponent, data: {} });
export { RenderType_CarwashTimeComponent as RenderType_CarwashTimeComponent };
function View_CarwashTimeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["FREI IN"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", ":", " "])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(7, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "unit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["min"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.timeMinutes$)), "2.0-0")); var currVal_1 = i0.ɵunv(_v, 5, 1, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), (i0.ɵunv(_v, 5, 1, i0.ɵnov(_v, 8).transform(_co.remainingTime)) % 60), "2.0-0")); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
function View_CarwashTimeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["JETZT FREI"]))], null, null); }
function View_CarwashTimeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Verbinde..."]))], null, null); }
function View_CarwashTimeComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fal fa-exclamation-triangle"]], null, null, null, null, null))], null, null); }
export function View_CarwashTimeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["style", "display: inline-block; height: 100%; vertical-align: middle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "span", [["style", "display: inline-block; vertical-align: middle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CarwashTimeComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CarwashTimeComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CarwashTimeComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CarwashTimeComponent_4)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.status$)) === "busy"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.status$)) === "free"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.status$)) === "connecting"); _ck(_v, 10, 0, currVal_2); var currVal_3 = (i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform(_co.status$)) === "connection_lost"); _ck(_v, 13, 0, currVal_3); }, null); }
export function View_CarwashTimeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-carwash-time-component", [], null, null, null, View_CarwashTimeComponent_0, RenderType_CarwashTimeComponent)), i0.ɵdid(1, 49152, null, 0, i2.CarwashTimeComponent, [], null, null)], null, null); }
var CarwashTimeComponentNgFactory = i0.ɵccf("siq-carwash-time-component", i2.CarwashTimeComponent, View_CarwashTimeComponent_Host_0, { remainingTime: "remainingTime" }, {}, []);
export { CarwashTimeComponentNgFactory as CarwashTimeComponentNgFactory };
