import {Component} from '@angular/core';
import {WeatherForecastBaseComponent} from './base-weather-forecast.component';
import {SettingsService} from '../sync/settings.service';

@Component({
    selector: 'siq-weather-forecast-portrait',
    styles: [`
        :host {
            display: block;
        }
    `],
    // language=Angular2HTML
    template: `
        <siq-day-weather-landscape *ngFor="let day of days$ | async; trackBy: dayTrackByFn"
                                   [weatherData]="day"
                                   [shortTemperatureFormat]="shortTemperatureFormat"
                                   style="height: 33%">
        </siq-day-weather-landscape>
    `,
})
export class WeatherForecastPortraitComponent extends WeatherForecastBaseComponent {
    constructor(settings: SettingsService) {
        super(settings);
    }
}
