import {Component, Input} from '@angular/core';

@Component({
    selector: 'siq-spinner',
    styles: [`
        :host /deep/ .wave-spinner {
            width: 75px !important;
            height: 60px !important;
        }

        :host /deep/ sk-wave .wave-spinner > div {
            width: 9px !important;
            margin-right: 6px !important;
        }
    `],
    template: `
        <sk-wave [color]="color" [isRunning]="isRunning"></sk-wave>
    `,
})
export class SiqSpinnerComponent {
    @Input() color?: string;
    @Input() isRunning = true;
}
