import {Component} from '@angular/core';
import {BootstrapServiceBase} from '../bootstrap.service.base';

@Component({
    selector: 'siq-intro-page',
    styles: [`
        :host {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #313131;
            font-family: 'Century Gothic', sans-serif;
        }

        .progress {
            position: fixed;
            width: 100%;
            height: 38%;
            bottom: 0;
            display: -webkit-flex;
            flex-direction: column;
            align-items: center;
        }

        h2 {
            text-align: center;
        }
    `],
    template: `
        <siq-background></siq-background>

        <div class="progress">
            <siq-spinner [color]="'#fea621'"></siq-spinner>
            <h2>{{ (bootstrapService.messageText$ | async)[0] }}</h2>
        </div>
    `,
})
export class IntroPageComponent {
    constructor(public bootstrapService: BootstrapServiceBase) {}
}
