import * as tslib_1 from "tslib";
import { NgComponentOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, ComponentRef, OnDestroy, } from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractPlatform } from '../platforms/platform';
import { ControllerClientServiceBase } from '../shared/controller-client.service.base';
import { SettingKeys, SettingsService } from '../sync/settings.service';
import { SyncServiceBase } from '../sync/sync.service.base';
import { EventSchedulerService } from './event-scheduler.service';
import { layoutClasses } from './layout/layout-classes';
import { PlaylistSyncService } from './playlist-sync.service';
import { SchedulerService } from './scheduler.service';
var logger = Logger.get('playlist-player');
var errorMessagePrepare = 'ERROR: Failed to prepare any playlist entry.';
var errorMessagePlay = 'ERROR: Failed to play any playlist entry.';
/**
 * The playlist player takes the current playlist from the {@link SchedulerService}
 * and displays it using layout components defined in {@link layoutClasses}.
 *
 * High level features are:
 * - displaying playlist entries using layouts defined in {@link layoutClasses}.
 * - displaying events by implementing the {@link EventPlayer} interface.
 * - displaying playlist entries synchronized using the {@link PlaylistSyncService}.
 * - controlling panel state depending on playlist (empty playlist -> panel off).
 */
var PlaylistPlayerComponent = /** @class */ (function () {
    function PlaylistPlayerComponent(cdr, platform, sync, settings, scheduler, eventScheduler, playlistSync, controller) {
        this.cdr = cdr;
        this.platform = platform;
        this.sync = sync;
        this.settings = settings;
        this.scheduler = scheduler;
        this.eventScheduler = eventScheduler;
        this.playlistSync = playlistSync;
        this.controller = controller;
        this.ngUnsubscribe = new Subject();
        this.playlist = [];
        /** Ignore scheduler playlist events while trying to update playlist on content end. */
        this.tryingPlaylistUpdate = false;
        /** playlistIdx shows which playlist entry is currently being played. undefined means nothing is played. */
        this.playlistIdx = undefined;
        /** playlistPrepareIdx shows which playlist entry is currently prepared. undefined means nothing is prepared. */
        this.playlistPrepareIdx = undefined;
        /**
         * eventState signals that event is playing or going to play
         * An event can not be interrupted except from another event.
         */
        this.eventState = 'NONE';
        this.restartingForMissingSyncScreen = false;
        /** playFailCount counts the number of consecutive errors in layout.play() */
        this.playFailCount = 0;
        this.layoutComponentLock = new Lock();
    }
    PlaylistPlayerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.scheduler.getPlaylist$()
            .pipe(map(function (playlist, index) { return [playlist, index]; }), takeUntil(this.ngUnsubscribe))
            .subscribe(function (_a) {
            var playlist = _a[0], index = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            logger.info('Playlist changed. New Length: ' + playlist.length, { playlist: playlist });
                            // Do not trigger playing by scheduler while playing an event.
                            if (this.eventState !== 'NONE') {
                                logger.info('Ignoring new playlist while playing event.');
                                return [2 /*return*/];
                            }
                            // Call onPlaylistChange() the first time we receive a playlist change
                            // and every time we change from an empty playlist to a non-empty one
                            if (!(index === 0 || (this.playlist.length === 0 && playlist.length > 0))) {
                                return [2 /*return*/];
                            }
                            if (this.tryingPlaylistUpdate) {
                                logger.info('Ignoring new playlist while trying to update playlist on content end.');
                                return [2 /*return*/];
                            }
                            this.playlist = playlist;
                            return [4 /*yield*/, this.onPlaylistChange()];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        });
        if (this.playlistSync) {
            this.playlistSync.receivedMissingScreen
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (screen) { return _this.onReceivedMissingSyncScreen(screen); });
        }
        // Register ourselves as the current EventPlayer.
        this.eventScheduler.start(this);
    };
    PlaylistPlayerComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearTimeout(this.errorRetryTimeout);
        if (this.playlistSync) {
            this.playlistSync.cancelWaitForScreenGroup('ngOnDestroy');
        }
        this.eventScheduler.stop();
    };
    PlaylistPlayerComponent.prototype.prepareEvent = function (entry) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.leaveErrorMode();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.platform.getPanelEnabled()];
                    case 2:
                        if (!!(_a.sent())) return [3 /*break*/, 5];
                        if (!(this.settings.get(SettingKeys.PANEL_TURN_ON_DELAY, 0) === 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.platform.setPanelEnabled(true)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.setPanelEnabledSoon(true);
                        _a.label = 5;
                    case 5:
                        if (this.playlistSync) {
                            this.playlistSync.cancelWaitForScreenGroup('prepareEvent');
                        }
                        return [4 /*yield*/, this.layoutComponentLock.dispatch(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            this.playlistPrepareIdx = undefined;
                                            if (this.layoutComponent !== undefined) {
                                                this.layoutComponent.stop();
                                            }
                                            if (entry.layout !== undefined) {
                                                this.useLayoutFromEvent(entry.layout);
                                            }
                                            else if (this.layoutComponent === undefined) {
                                                // We might not have loaded a layout currently,
                                                // if no layout is loaded use the default layout template.
                                                this.layout = { template: 'default' };
                                                this.changeLayoutTemplate('default');
                                            }
                                            // Set a flag indicating that the currently playing entry
                                            // cannot be synchronized using the PlaylistSyncService
                                            this.eventState = 'PREPARING';
                                            _a = !this.layoutComponent;
                                            if (_a) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.layoutComponent.prepare(entry)];
                                        case 1:
                                            _a = !(_b.sent());
                                            _b.label = 2;
                                        case 2:
                                            if (_a) {
                                                throw new Error('Failed to prepare layout component.');
                                            }
                                            this.eventState = 'PREPARED';
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        e_1 = _a.sent();
                        logger.warn("Failed to prepare event: " + e_1.message);
                        this.onContentEnd();
                        return [2 /*return*/, false];
                    case 8: return [2 /*return*/, true];
                }
            });
        });
    };
    PlaylistPlayerComponent.prototype.playEvent = function (entry) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var continueWithoutEvent;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.layoutComponentLock.dispatch(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (this.eventState !== 'PREPARED') {
                                            logger.warn("Cannot play event in event-state: " + this.eventState);
                                            return [2 /*return*/, false];
                                        }
                                        this.eventState = 'PLAYING';
                                        _a = !this.layoutComponent;
                                        if (_a) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.layoutComponent.play(entry)];
                                    case 1:
                                        _a = !(_b.sent());
                                        _b.label = 2;
                                    case 2:
                                        if (_a) {
                                            logger.warn('Failed to play event.');
                                            return [2 /*return*/, true];
                                        }
                                        this.controller.sendContentChange(entry.id);
                                        if (!(this.playlist.length > 0)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, this.prepareNextEntry()];
                                    case 3:
                                        _b.sent();
                                        _b.label = 4;
                                    case 4: return [2 /*return*/, false];
                                }
                            });
                        }); })];
                    case 1:
                        continueWithoutEvent = _a.sent();
                        if (continueWithoutEvent) {
                            this.onContentEnd();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PlaylistPlayerComponent.prototype.useLayoutFromEvent = function (layout) {
        this.layout = layout;
        this.changeLayoutTemplate(layout.template);
    };
    PlaylistPlayerComponent.prototype.onReceivedMissingSyncScreen = function (screenId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.eventState !== 'NONE' || this.layoutComponent === undefined) {
                            return [2 /*return*/];
                        }
                        if (this.restartingForMissingSyncScreen) {
                            // Possibly playing is being started at this time from previous missing screen event.
                            // But we accept start playing a little early to prevent trouble with multiple start and abort playing.
                            logger.info("Ignoring sync from another missing screen: " + screenId);
                            return [2 /*return*/];
                        }
                        logger.info("Restarting playlist after receiving missing screen " + screenId + " from playlist synchronization.");
                        this.restartingForMissingSyncScreen = true;
                        return [4 /*yield*/, this.onPlaylistChange()];
                    case 1:
                        _a.sent();
                        this.restartingForMissingSyncScreen = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PlaylistPlayerComponent.prototype.getNextPlaylistIdx = function () {
        return this.playlistIdx === undefined ? 0 : (this.playlistIdx + 1) % this.playlist.length;
    };
    PlaylistPlayerComponent.prototype.getLayoutIdFromPlaylistEntry = function (id) {
        if (!this.playlist[id] || !this.playlist[id].layout || !this.playlist[id].layout.id) {
            return;
        }
        return this.playlist[id].layout.id;
    };
    /**
     * Called every time a item from the playlist has finished.
     */
    PlaylistPlayerComponent.prototype.onContentEnd = function () {
        // The only case where we aren't playing a syncable entry is events
        // so we reset syncable to true after every content (currently events only one content).
        this.eventState = 'NONE';
        // Trigger a playlist update and restart playlist if it changed
        if (this.tryUpdatePlaylist()) {
            this.onPlaylistChange();
            return;
        }
        // The playlist may be empty when for example we played an event when the playlist was empty
        if (this.playlist.length === 0) {
            this.onPlaylistChange();
            return;
        }
        // Continue on to the next entry that was prepared
        // but check if the layout changed and apply that changed if needed
        var currentLayoutId = this.layout.id;
        var nextIdx = this.playlistPrepareIdx !== undefined ? this.playlistPrepareIdx : this.getNextPlaylistIdx();
        var nextLayoutId = this.getLayoutIdFromPlaylistEntry(nextIdx);
        if (currentLayoutId !== nextLayoutId) {
            this.onLayoutChange(nextIdx);
        }
        this.playAndPrepareNextLocked();
    };
    PlaylistPlayerComponent.prototype.tryUpdatePlaylist = function () {
        try {
            this.tryingPlaylistUpdate = true;
            // Do not apply a new playlist during sync as content may not have been downloaded yet.
            if (this.sync.isSyncing) {
                return false;
            }
            this.scheduler.triggerPlaylistUpdate();
            if (!_.isEqual(this.playlist, this.scheduler.getPlaylist())) {
                var newPlaylist = this.scheduler.getPlaylist();
                this.playlist = newPlaylist || [];
                return true;
            }
            return false;
        }
        finally {
            this.tryingPlaylistUpdate = false;
        }
    };
    PlaylistPlayerComponent.prototype.prepareNextEntry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var startIdx, i, currentPrepareIdx, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        startIdx = this.getNextPlaylistIdx();
                        i = 0;
                        _b.label = 1;
                    case 1:
                        if (!(i < this.playlist.length)) return [3 /*break*/, 5];
                        currentPrepareIdx = (startIdx + i) % this.playlist.length;
                        _a = this.layoutComponent;
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.layoutComponent.prepare(this.playlist[currentPrepareIdx])];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a) {
                            logger.debug('Successfully prepared playlist entry: ' + currentPrepareIdx);
                            this.playlistPrepareIdx = currentPrepareIdx;
                            return [2 /*return*/, true];
                        }
                        _b.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 1];
                    case 5:
                        this.playlistPrepareIdx = undefined;
                        return [2 /*return*/, false];
                }
            });
        });
    };
    PlaylistPlayerComponent.prototype.playAndPrepareNextLocked = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.layoutComponentLock.dispatch(this.playAndPrepareNext.bind(this))];
            });
        });
    };
    PlaylistPlayerComponent.prototype.playAndPrepareNext = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var entry, e_2, _a, nextIdx, currentLayoutId, nextLayoutId;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.prepareNextEntry()];
                    case 1:
                        if (!(_b.sent())) {
                            logger.error('Could not prepare any playlist entry. Showing error text on display.');
                            this.enterErrorMode(errorMessagePrepare);
                            return [2 /*return*/];
                        }
                        // Set playlistIdx to the currently prepared entry to show which entry we are currently trying to play
                        // playlistPrepareIdx can not be undefined as this point.
                        this.playlistIdx = this.playlistPrepareIdx;
                        entry = this.playlist[this.playlistIdx];
                        logger.debug('Playing playlist entry: ' + this.playlistIdx);
                        if (!(this.playlistIdx === 0 && this.playlistSync)) return [3 /*break*/, 5];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.playlistSync.waitForScreenGroup()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _b.sent();
                        logger.info("Error on waiting for screen group: " + e_2);
                        return [2 /*return*/];
                    case 5:
                        _a = !this.layoutComponent;
                        if (_a) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.layoutComponent.play(entry)];
                    case 6:
                        _a = !(_b.sent());
                        _b.label = 7;
                    case 7:
                        if (_a) {
                            this.playFailCount += 1;
                            if (this.playFailCount >= this.playlist.length) {
                                // It seems we failed to play any entry in our playlist.
                                // We show a error text on the display and try again when the playlist changes
                                logger.error('Could not play any playlist entry. Showing error text on display.');
                                this.enterErrorMode(errorMessagePlay);
                                return [2 /*return*/];
                            }
                            logger.warn('Failed to play playlist entry. Continuing with next one.');
                            // reset to undefined to force prepare the next one
                            this.playlistPrepareIdx = undefined;
                            nextIdx = this.getNextPlaylistIdx();
                            currentLayoutId = this.layout.id;
                            nextLayoutId = this.getLayoutIdFromPlaylistEntry(nextIdx);
                            if (currentLayoutId !== nextLayoutId) {
                                this.onLayoutChange(nextIdx, true);
                            }
                            // play() failed to display the currently prepared element, try to play the next one instead ...
                            return [2 /*return*/, this.playAndPrepareNext()];
                        }
                        this.leaveErrorMode();
                        this.playFailCount = 0;
                        this.controller.sendContentChange(entry.id);
                        return [4 /*yield*/, this.prepareNextEntry()];
                    case 8:
                        if (!(_b.sent())) {
                            logger.warn('Could not pre-prepare any playlist entry.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PlaylistPlayerComponent.prototype.onPlaylistChange = function () {
        var _this = this;
        logger.debug('playlist-player:onPlaylistChange()');
        return this.layoutComponentLock.dispatch(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug('playlist-player:onPlaylistChange got lock');
                        this.leaveErrorMode();
                        this.playFailCount = 0;
                        this.playlistIdx = undefined;
                        this.onLayoutChange(0, true);
                        // Enable / disable panel depending on whether we have a playlist
                        this.setPanelEnabledSoon(this.playlist.length > 0);
                        if (!(this.playlist.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.playAndPrepareNext()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    PlaylistPlayerComponent.prototype.onLayoutChange = function (playlistIdx, forceStop) {
        if (forceStop === void 0) { forceStop = false; }
        logger.debug('playlist-player:onLayoutChange()');
        // If only the layout changes but not the template
        var isSameLayoutComponent = this.layoutComponentClass !== undefined
            && this.playlist[playlistIdx] && this.playlist[playlistIdx].layout
            && this.layoutComponentClass === layoutClasses[this.playlist[playlistIdx].layout.template];
        if (forceStop || !isSameLayoutComponent) {
            // Always reset prepareIdx to signal that the next content is not yet prepared
            this.playlistPrepareIdx = undefined;
            if (this.layoutComponent !== undefined) {
                try {
                    this.layoutComponent.stop();
                }
                catch (e) {
                    logger.warn("Failed to stop layout component for layout change: " + e.message);
                }
            }
        }
        if (isSameLayoutComponent) {
            logger.debug('Using quick layout change because layout component is the same');
            this.layout = this.playlist[playlistIdx].layout;
            this.layoutComponent.layout = this.layout;
            return;
        }
        if (this.layoutCompletedSubscription !== undefined) {
            this.layoutCompletedSubscription.unsubscribe();
            this.layoutCompletedSubscription = undefined;
        }
        if (this.playlist.length === 0) {
            this.layout = undefined;
            this.layoutComponent = undefined;
            this.layoutComponentClass = undefined;
            return;
        }
        this.layout = this.playlist[playlistIdx].layout;
        // Set the correct layout class
        if (this.layout) {
            this.changeLayoutTemplate(this.layout.template);
        }
    };
    PlaylistPlayerComponent.prototype.changeLayoutTemplate = function (layoutTemplate) {
        if (this.layoutCompletedSubscription !== undefined) {
            this.layoutCompletedSubscription.unsubscribe();
            this.layoutCompletedSubscription = undefined;
        }
        this.layoutComponentClass = layoutClasses[layoutTemplate];
        if (this.layoutComponentClass === undefined) {
            logger.error("LayoutComponentClass for template '" + layoutTemplate + "' not found. Using default layout instead.");
            this.layoutComponentClass = layoutClasses.default;
        }
        // Update the view after the layout has changed
        this.cdr.detectChanges();
        // And set the layoutComponent
        var componentRef = this.componentOutlet._componentRef;
        this.layoutComponent = componentRef.instance;
        this.layoutComponent.layout = this.layout; // @ObservableInput() generates wrong types.
        this.layoutCompletedSubscription = this.layoutComponent.completed.subscribe(this.onContentEnd.bind(this));
        // Update the view after we set the layout on the layout component
        this.cdr.detectChanges();
    };
    PlaylistPlayerComponent.prototype.setPanelEnabledSoon = function (enabled) {
        var _this = this;
        // This delay is needed in some constellations with setBackBoxes and third party displays to wake up the display.
        var delay = enabled ? this.settings.get(SettingKeys.PANEL_TURN_ON_DELAY, 0) : 0;
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug("Executing panel turn " + (enabled ? 'on' : 'off') + " after waited for " + delay + " ms.");
                        return [4 /*yield*/, this.platform.setPanelEnabled(enabled)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, delay);
    };
    PlaylistPlayerComponent.prototype.enterErrorMode = function (errorText) {
        var _this = this;
        this.errorText = errorText;
        if (this.errorRetryTimeout !== undefined) {
            logger.warn('Error mode retry timer is already active, clearing it.');
            clearTimeout(this.errorRetryTimeout);
            this.errorRetryTimeout = undefined;
        }
        this.errorRetryTimeout = setTimeout(function () {
            logger.debug('Retrying to play from error mode.');
            _this.playFailCount = 0;
            _this.errorRetryTimeout = undefined;
            // Set the player state so that we can call onContentEnd even though we haven't played anything.
            _this.playlistPrepareIdx = undefined;
            if (_this.playlistIdx === undefined) {
                _this.playlistIdx = 0;
            }
            // Start from a clean state.
            if (_this.layoutComponent !== undefined) {
                try {
                    _this.layoutComponent.stop();
                }
                catch (e) {
                    logger.warn("Failed to stop layout component for retry: " + e.message);
                }
            }
            _this.onContentEnd();
        }, 15000);
        // Enable the panel to ensure that the error text can be read
        this.setPanelEnabledSoon(true);
    };
    PlaylistPlayerComponent.prototype.leaveErrorMode = function () {
        if (this.errorText === undefined && this.errorRetryTimeout === undefined) {
            return;
        }
        clearTimeout(this.errorRetryTimeout);
        this.errorRetryTimeout = undefined;
        this.errorText = undefined;
        logger.info('Left error mode.');
    };
    return PlaylistPlayerComponent;
}());
export { PlaylistPlayerComponent };
// Adapted from https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
var Lock = /** @class */ (function () {
    function Lock() {
        this.mutex = Promise.resolve();
    }
    Lock.prototype.lock = function () {
        var begin = function () { return undefined; };
        this.mutex = this.mutex.then(function () { return new Promise(begin); });
        return new Promise(function (res) {
            begin = res;
        });
    };
    Lock.prototype.dispatch = function (fn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unlock;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lock()];
                    case 1:
                        unlock = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, , 4, 5]);
                        return [4 /*yield*/, fn()];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4:
                        unlock();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return Lock;
}());
