import * as Logger from 'js-logger';
import { merge, of, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, distinctUntilChanged, exhaustMap, filter, first, map, mapTo, publish, scan, share, tap, timestamp, withLatestFrom, } from 'rxjs/operators';
var logger = Logger.get('washtec-client');
var WashtecClient = /** @class */ (function () {
    function WashtecClient() {
    }
    /**
     * @return An observable that emits every second remaining time or undefined, but only on change.
     *         If getTime$ is called several times, all observables are emitting synchronized.
     *         If interrupted connection is detected, the observable emits undefined.
     *         Emission starts on first successful request to host or when interruption is detected.
     */
    WashtecClient.getTime$ = function (host) {
        var http$ = ajax("http://" + host + "/rest/SoftCareRest.cgi?cmd=remainingWashingTime").pipe(map(function (r) { return r.response; }));
        logger.debug("Creating washtec client to host: " + host);
        var status$ = timer(0, WashtecClient.POLL_INTERVAL).pipe(exhaustMap(function () {
            logger.debug("Requesting status from: " + host);
            return (WashtecClient.EMULATE_WASH_LINK ? WashtecClient.getHttpStatusEmulator() : http$)
                .pipe(catchError(function (error) {
                logger.debug("Status request for " + host + " failed: " + error.message + ": " + (error.xhr ? error.xhr.errorString : ''));
                return of(undefined);
            }));
        }), share());
        // Emits false if once received status, emits true if ${interruptionThreshold} times status request failed (undefined)
        var interruptionThreshold = 3;
        var connectionInterrupted$ = status$.pipe(scan(function (count, status) { return status === undefined ? count + 1 : 0; }, 0), tap(function (c) {
            if (c % 10 === interruptionThreshold) { // Log when connectionInterrupted is set and then every 10th time
                logger.info("Connection to carwash with address " + host + " interrupted.");
            }
        }), map(function (c) { return c >= interruptionThreshold; }));
        var time$ = status$.pipe(filter(function (s) { return s !== undefined; }), map(function (s) { return s.seconds; }), tap(function (t) { return logger.debug("Received new time " + t + " from " + host); }), timestamp(), scan(function (ref, act) {
            var timeRef = Math.max(0, ref.value * 1000 - (act.timestamp - ref.timestamp));
            if (Math.abs(timeRef - act.value * 1000) <= WashtecClient.TIME_JITTER_THRESHOLD) {
                return ref;
            }
            logger.debug("Time deviation larger than threshold, continuing with actual value for: " + host);
            return act;
        }));
        // Emits remaining time or undefined if connection is interrupted every second synchronously to other clients if it changes.
        return WashtecClient.seconds$.pipe(withLatestFrom(connectionInterrupted$), map(function (_a) {
            var connectionInterrupted = _a[1];
            return connectionInterrupted;
        }), branch(function (connectionInterrupted) { return connectionInterrupted; }, function (interrupted) { return interrupted.pipe(mapTo(undefined)); }, function (connected) { return connected.pipe(timestamp(), withLatestFrom(time$), map(function (_a) {
            var tick = _a[0], time = _a[1];
            return Math.max(0, time.value - Math.trunc((tick.timestamp - time.timestamp) / 1000));
        })); }), distinctUntilChanged());
    };
    WashtecClient.getStatusEmulator$ = function () {
        WashtecClient.statusEmulator$.connect();
        return WashtecClient.statusEmulator$;
    };
    WashtecClient.getHttpStatusEmulator = function () {
        return WashtecClient.getStatusEmulator$().pipe(delay(500), tap(function (s) {
            if (s.seconds > 30 && s.seconds <= 50) {
                throw new Error('500');
            }
        }), first());
    };
    WashtecClient.POLL_INTERVAL = 10 * 1000;
    WashtecClient.TIME_JITTER_THRESHOLD = 1000;
    WashtecClient.seconds$ = timer(0, 1000).pipe(share());
    // For testing purpose only.
    // tslint:disable:member-ordering
    WashtecClient.EMULATE_WASH_LINK = false;
    WashtecClient.statusEmulator$ = timer(0, 950).pipe(map(function (i) { return ({
        seconds: Math.max(0, 75 - i % 90),
        name: 'Car wash emulator',
    }); }), publish());
    return WashtecClient;
}());
export { WashtecClient };
// My first custom pipe operator :)
// Splits the source observable by selector into 2 observables, that can be piped by injector callbacks.
// The observables returned by injector callbacks are merged to have at the end only one observable.
// If source observable emits multiple events on one tick, the order might be changed in resulting observable.
function branch(selector, injectorTrue, injectorFalse) {
    if (injectorFalse === void 0) { injectorFalse = function (o) { return o; }; }
    return function (source) {
        var sharedSource = source.pipe(share());
        var filteredTrue = sharedSource.pipe(filter(selector));
        var filteredFalse = sharedSource.pipe(filter(function (value, index) { return !selector(value, index); }));
        return merge(injectorTrue(filteredTrue), injectorFalse(filteredFalse));
    };
}
