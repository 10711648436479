import {Component} from '@angular/core';
import {map} from 'rxjs/operators';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

@Component({
    selector: 'siq-standard-overlay-layout',
    styles: [`
        .player {
            position: relative;
            width: 1600px;
            height: 900px
        }

        .newsticker {
            position: absolute;
            top: 1020px;
            left: 0;
            width: 1920px;
            height: 60px;
            background-color: #ebebeb;
        }

        .newsticker img {
            position: absolute;
            left: 2px;
            top: 15px;
            width: 100px;
        }

        .newsticker siq-news-ticker {
            margin-left: 120px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
        }

        .date_and_clock {
            position: absolute;
            top: 200px;
            left: 1600px;
            width: 320px;
            z-index: 101;
        }
    `],
    // language=Angular2HTML
    template: `
        <div class="player">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>

        <div class="newsticker">
            <img src="assets/logo_n24.png">
            <siq-news-ticker></siq-news-ticker>
        </div>

        <img class="overlay" [src]="imageOverlay$ | async">

        <div class="date_and_clock">
            <siq-date></siq-date>
            <siq-clock [color]="clockColor$ | async"></siq-clock>
        </div>
    `,
})
export class ShopiqStandardOverlayLayoutComponent extends BaseLayoutComponent {
    readonly imageOverlay$ = this.getLayoutImage$('image_overlay');
    readonly clockColor$ = this.layout.pipe(map((layout): string => layout ? layout.parameters.clock_color : 'red'));

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}
