import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import * as Logger from 'js-logger';
import { BaseVideoPlayerComponent } from './base-video-player.component';
var logger = Logger.get('video-tag-player');
var VideoTagPlayerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VideoTagPlayerComponent, _super);
    function VideoTagPlayerComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hidden = true;
        return _this;
    }
    Object.defineProperty(VideoTagPlayerComponent.prototype, "videoRef", {
        set: function (ref) {
            this.video = ref.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    VideoTagPlayerComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug('Playing video: ' + this.prepareFile);
                        if (!(this.videoSrc === this.prepareFile)) return [3 /*break*/, 2];
                        // onCanplay is only called if videoSrc changes. So start player manually if no change.
                        this.hidden = false;
                        return [4 /*yield*/, this.video.play()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.videoSrc = this.prepareFile;
                        _a.label = 3;
                    case 3: return [2 /*return*/, true];
                }
            });
        });
    };
    VideoTagPlayerComponent.prototype.prepare = function (filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                logger.debug('Preparing new video: ' + filename);
                // TODO Set videoSrc here and return promise that resolves when onCanplay is called.
                //  Furthermore start video always in play method.
                this.prepareFile = filename;
                return [2 /*return*/, true];
            });
        });
    };
    VideoTagPlayerComponent.prototype.stop = function () {
        return;
    };
    VideoTagPlayerComponent.prototype.onEnded = function () {
        this.hidden = true;
        this.completed.emit();
    };
    VideoTagPlayerComponent.prototype.onCanplay = function () {
        logger.debug('onCanplay called.');
        this.video.muted = true;
        this.hidden = false;
        this.video.play();
    };
    return VideoTagPlayerComponent;
}(BaseVideoPlayerComponent));
export { VideoTagPlayerComponent };
