import {CupcinoLayoutComponent} from './cupcino-layout.component';
import {FullScreenLayoutComponent} from './full-screen-layout.component';
import {HellaAccessControlLayoutComponent} from './hella-access-control-layout.component';
import {HtmlOverlayLayoutComponent} from './html-overlay-layout.component';
import {ShopiqStandardOverlayLayoutComponent} from './shopiq-standard-overlay-layout.component';
import {ShopiqStdLayoutComponent} from './shopiq-std-layout.component';
import {StiglechnerLayoutComponent} from './stiglechner-layout.component';
import {WashtecNewsLayoutComponent} from './washtec-news-layout.component';
import {WashtecWeatherLayoutComponent} from './washtec-weather-layout.component';
import {WeatherNewsLandscapeLayoutComponent} from './weather-news-landscape-layout.component';
import {WeatherNewsPortraitLayoutComponent} from './weather-news-portrait-layout.component';

export const layoutClasses: { [key: string]: any; } = {
    default: HtmlOverlayLayoutComponent,
    Stiglechner: StiglechnerLayoutComponent,
    CupCino: CupcinoLayoutComponent,
    'Shop-IQ Standard': ShopiqStdLayoutComponent,
    'Shop-IQ Standard Overlay': ShopiqStandardOverlayLayoutComponent,
    'Wetter Nachrichten': WeatherNewsLandscapeLayoutComponent,
    'Wetter Nachrichten Hochformat': WeatherNewsPortraitLayoutComponent,
    'WashTec Nachrichten': WashtecNewsLayoutComponent,
    'WashTec Wetter': WashtecWeatherLayoutComponent,
    Kundenstromsteuerung: HellaAccessControlLayoutComponent,
    'HTML-Overlay': HtmlOverlayLayoutComponent,
};
