/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./full-hd-iframe.component";
import * as i3 from "@angular/platform-browser";
var styles_FullHdIframeComponent = ["[_nghost-%COMP%] {\n            display: block;\n            overflow: hidden;\n        }\n\n        iframe[_ngcontent-%COMP%] {\n            width: 1920px;\n            height: 1080px;\n            border: none;\n            -webkit-transform-origin: 0 0;\n        }"];
var RenderType_FullHdIframeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FullHdIframeComponent, data: {} });
export { RenderType_FullHdIframeComponent as RenderType_FullHdIframeComponent };
export function View_FullHdIframeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["iframe", 1]], null, 1, "iframe", [], [[8, "src", 5], [4, "-webkit-transform", null]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.sourceUrlSafe$)); var currVal_1 = (("scale(" + (_co.elementRef.nativeElement.clientWidth / 1920)) + ")"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FullHdIframeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-full-hd-iframe", [], null, null, null, View_FullHdIframeComponent_0, RenderType_FullHdIframeComponent)), i0.ɵdid(1, 245760, null, 0, i2.FullHdIframeComponent, [i0.ElementRef, i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullHdIframeComponentNgFactory = i0.ɵccf("siq-full-hd-iframe", i2.FullHdIframeComponent, View_FullHdIframeComponent_Host_0, { sourceUrl: "sourceUrl", reloadInterval: "reloadInterval" }, {}, []);
export { FullHdIframeComponentNgFactory as FullHdIframeComponentNgFactory };
