/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clock.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./clock.component";
var styles_ClockComponent = [i0.styles];
var RenderType_ClockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClockComponent, data: {} });
export { RenderType_ClockComponent as RenderType_ClockComponent };
export function View_ClockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "clock simple"]], [[4, "border-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "hours-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "hours"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { transform: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "minutes-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "minutes"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { transform: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, (("rotateZ(" + _co.hourAngle) + "deg)")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, (("rotateZ(" + _co.minuteAngle) + "deg)")); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 0, 0, currVal_0); }); }
export function View_ClockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "siq-clock", [], null, null, null, View_ClockComponent_0, RenderType_ClockComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClockComponentNgFactory = i1.ɵccf("siq-clock", i3.ClockComponent, View_ClockComponent_Host_0, { color: "color" }, {}, []);
export { ClockComponentNgFactory as ClockComponentNgFactory };
