import {Component, Input} from '@angular/core';
import { DayWeatherData } from '../sync/settings.service';

@Component({
    selector: 'siq-day-weather-landscape',
    styles: [`
        :host {
            display: block;
            text-align: center;
        }

        .fal {
            font-size: 3em;
            margin-right: 0.5em;
        }
    `],
    // language=Angular2HTML
    template: `
        <div style="height: 100%;">
            <div style="display: inline-block; position: relative; top: 50%; transform: translateY(-50%);">
                <i class="fal" [ngClass]="weatherData.icon"></i>
            </div>

            <div style="display: inline-block; height: inherit; vertical-align: top;">
                <div style="position: relative; top: 50%; transform: translateY(-50%);">
                    <div>{{ weatherData.date | date:'EEEEEE dd.MM.' }}</div>

                    <div *ngIf="shortTemperatureFormat">
                        {{ weatherData.maximum_temperature | number:'1.0-0' }}°C/{{ weatherData.minimum_temperature | number:'1.0-0' }}°C
                    </div>

                    <div *ngIf="!shortTemperatureFormat">
                        {{ weatherData.maximum_temperature | number:'1.0-1' }}°C / {{ weatherData.minimum_temperature | number:'1.0-1' }}°C
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class DayWeatherLandscapeComponent {
    /** required */
    @Input() weatherData!: DayWeatherData;
    @Input() shortTemperatureFormat = false;
}
