import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BootstrapServiceBase } from './bootstrap.service.base';
var AppComponent = /** @class */ (function () {
    function AppComponent(bootstrap) {
        this.bootstrap = bootstrap;
    }
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.bootstrap.bootstrap()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
