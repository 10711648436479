import * as tslib_1 from "tslib";
import { pluck } from 'rxjs/operators';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var WeatherNewsPortraitLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WeatherNewsPortraitLayoutComponent, _super);
    function WeatherNewsPortraitLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.iframeUrl$ = _this.parameters$.pipe(pluck('iframe_url'));
        _this.iframeReloadInterval$ = _this.parameters$.pipe(pluck('iframe_reload_interval'));
        _this.imageBottom$ = _this.getLayoutImage$('image_bottom');
        _this.weatherColor$ = _this.parameters$.pipe(pluck('color_bottom'));
        return _this;
    }
    return WeatherNewsPortraitLayoutComponent;
}(BaseLayoutComponent));
export { WeatherNewsPortraitLayoutComponent };
