import {Component} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as _ from 'lodash';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {delayWhen, map, skip} from 'rxjs/operators';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';
import {createIframeSrcDoc} from './iframe-utils';
import {ContentType, PlaylistEntry} from "../scheduler.service.base";

interface Parameters {
    overlay_html: string;
    placeholder_settings: string;
    template: number;
    // if undefined then fullscreen is used
    content_dimensions?: {
        posX: number,
        posY: number,
        width: number,
        height: number,
    };
}

@Component({
    selector: 'siq-html-layout',
    // language=Angular2HTML
    styles: [`
        iframe {
            border: none;
            -webkit-transform-origin: 0 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
        }
    `],
    template: `
        <div [ngStyle]="contentStyle$ | async">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>
        <ng-container *ngIf="htmlCode$ | async as htmlCode">
            <iframe [srcdoc]="htmlCode"></iframe>
        </ng-container>
    `,
})
export class HtmlOverlayLayoutComponent extends BaseLayoutComponent {

    readonly parameters$!: Observable<Parameters>;

    // Counts the images played in a row.
    private readonly imagePlayCount$ = new BehaviorSubject(0);

    readonly htmlCode$ = createHtmlCode$(
        this.fileSync,
        this.settings,
        this.sanitizer,
        this.parameters$,
        this.imagePlayCount$,
    );
    readonly contentStyle$ = createContentStyle$(this.parameters$);

    constructor(settings: SettingsService,
                fileSync: FileSyncServiceBase,
                private sanitizer: DomSanitizer) {
        super(settings, fileSync);
    }

    play(element: PlaylistEntry): Promise<boolean> {
        if (element.type === ContentType.PICTURE) {
            this.imagePlayCount$.next(this.imagePlayCount$.value + 1);
        } else {
            this.imagePlayCount$.next(0);
        }

        return super.play(element)
    }

    stop() {
        super.stop();

        this.imagePlayCount$.next(0);
    }
}

function createHtmlCode$(
    fileSync: FileSyncServiceBase,
    settings: SettingsService,
    sanitizer: DomSanitizer,
    parameters$: Observable<Parameters | undefined>,
    imagePlayCount$: BehaviorSubject<number>,
): Observable<SafeHtml> {
    const htmlCodeDelay$ = imagePlayCount$.pipe(
        // Skip the current value.
        skip(1),
        // Delay html overlay by half of duration of image player fading between images
        delayWhen(count => count > 1 ? interval(500) : of(0)),
    )

    return parameters$.pipe(
        map(parameters => {
            if (parameters === undefined) {
                return '';
            }
            const assets = settings.getHtmlTemplateAssetsForTemplate(parameters.template);
            const source = createIframeSrcDoc(parameters.overlay_html, assets, fileSync);
            return sanitizer.bypassSecurityTrustHtml(source);
        }),
        delayWhen(() => htmlCodeDelay$),
    );
}

function createContentStyle$(
    parameters$: Observable<Parameters>): Observable<SafeHtml> {
    const addPx = (pixel?: number) => pixel ? `${pixel}px` : undefined;

    return parameters$.pipe(map(parameters => ({
        zIndex: '0',
        position: 'absolute',
        left: addPx(_.get(parameters, 'content_dimensions.posX', 0)),
        top: addPx(_.get(parameters, 'content_dimensions.posY', 0)),
        width: addPx(_.get(parameters, 'content_dimensions.width')) || '100%',
        height: addPx(_.get(parameters, 'content_dimensions.height')) || '100%',
    })));
}
