import {Injectable} from '@angular/core';
import * as Logger from 'js-logger';

import {AbstractPlatform} from '../platforms/platform';
import {EventEntityLayoutType, SettingKeys, SettingsService} from '../sync/settings.service';
import {ContentType, PlaylistEntry} from './scheduler.service';
import {Layout} from './scheduler.service.base';

const logger = Logger.get('event-scheduler');

export interface EventPlayer {
    prepareEvent(entry: PlaylistEntry): Promise<boolean>;

    playEvent(entry: PlaylistEntry): Promise<void>;
}

interface SyncEvent {
    id: number;
    syncToken: any;
    playlistEntry: PlaylistEntry;
    timeoutTimer: any;
}

/**
 * The event scheduler service receives events and plays them on the local {@link EventPlayer}.
 * To send events use the {@link EventTriggerService}.
 */
@Injectable()
export class EventSchedulerService {
    private static readonly EVENT_SYNC_TIMEOUT = 6 * 1000;

    private player?: EventPlayer;
    private currentSyncEvent?: SyncEvent;

    constructor(private platform: AbstractPlatform,
                private settings: SettingsService) {
    }

    start(player: EventPlayer): void {
        if (this.player !== undefined) {
            logger.warn('Event scheduler started twice!');
            return;
        }

        this.player = player;
    }

    stop(): void {
        if (this.player === undefined) {
            logger.warn('Event scheduler stopped without previous start()!');
            return;
        }

        this.player = undefined;
        if (this.currentSyncEvent !== undefined) {
            this.stopCurrentSyncEvent();
        }
    }

    /**
     * Play the provided event with the currently set {@link EventPlayer}.
     * If syncToken is provided we will wait for {@link syncEvent}
     * to be called before actually starting to play the event.
     */
    async playEvent(id: number, syncToken?: any): Promise<void> {
        logger.debug(`event-scheduler:playEvent(${id})`);
        if (this.player === undefined) {
            logger.warn(`Received event(${id}) before event scheduler was started.`);
            return;
        }

        // If we are currently waiting for synchronization of another event cancel waiting
        if (this.currentSyncEvent !== undefined) {
            this.stopCurrentSyncEvent();
        }

        const playlistEntry = this.getPlaylistEntryFromEvent(id);
        if (playlistEntry === undefined) {
            return;
        }

        if (!await this.player.prepareEvent(playlistEntry)) {
            return;
        }

        // If syncToken is undefined we don't have to synchronize event playing with other screens
        if (syncToken === undefined) {
            await this.player.playEvent(playlistEntry);
            return;
        }

        const timeoutTimer = setTimeout(() => {
            if (this.currentSyncEvent === undefined) {
                logger.warn('Ignoring event timeout cause current sync event is missing.');
                return;
            }
            logger.warn(`Timeout while waiting for event sync from other screens. Playing event(${id}) now.`);
            this.currentSyncEvent = undefined;
            if (this.player !== undefined) {
                this.player.playEvent(playlistEntry);
            }
        }, EventSchedulerService.EVENT_SYNC_TIMEOUT);

        // Save the event infos until syncEvent() gets called.
        this.currentSyncEvent = {
            id,
            syncToken,
            playlistEntry,
            timeoutTimer,
        };
    }

    /**
     * Called by node-client when an event sync token is received.
     */
    syncEvent(syncToken: any): void {
        if (this.currentSyncEvent === undefined || this.player === undefined) {
            logger.debug(`syncEvent(${syncToken}) called but we're not expecting any!`);
            return;
        }

        if (this.currentSyncEvent.syncToken !== syncToken) {
            logger.warn(`syncEvent(${syncToken}) called but we're expecting `
                + `a different syncToken: ${this.currentSyncEvent.syncToken}`);
            return;
        }

        logger.debug('Calling playEvent() after receiving syncToken for event: ' + this.currentSyncEvent.id);
        this.player.playEvent(this.currentSyncEvent.playlistEntry);
        this.stopCurrentSyncEvent();
    }

    async playStream(url: string): Promise<void> {
        if (this.player === undefined) {
            logger.warn(`Received stream (${url}) before event scheduler was started.`);
            return;
        }

        const playlistEntry = {
            id: 0,
            type: ContentType.STREAM,
            source: url,
        };
        await this.player.prepareEvent(playlistEntry);
        await this.player.playEvent(playlistEntry);
    }

    private stopCurrentSyncEvent(): void {
        if (this.currentSyncEvent === undefined) {
            return;
        }

        clearTimeout(this.currentSyncEvent.timeoutTimer);
        this.currentSyncEvent = undefined;
    }

    private getPlaylistEntryFromEvent(eventId: number): PlaylistEntry | undefined {
        const event = this.settings.getEvent(eventId);
        if (event === undefined) {
            logger.warn('Unknown event: ' + eventId);
            return undefined;
        }

        const myScreenId = this.settings.get(SettingKeys.SCREEN_ID);
        const eventContent = event.contents.find((c: any) => c.screen === myScreenId);
        if (eventContent === undefined || eventContent.contents.length === 0) {
            logger.warn(`Event(${eventId}) has no contents for screen(${myScreenId}).`);
            return undefined;
        }

        const eventContentId = eventContent.contents[0].id;
        const content = this.settings.getContent(eventContentId);
        if (content === undefined) {
            logger.warn('Unknown content: ' + eventContentId);
            return undefined;
        }

        const source = this.settings.getContentFileURL(eventContentId);
        if (source === undefined) {
            logger.warn('Failed to generate file url for content: ' + eventContentId);
            return undefined;
        }

        return {
            id: content.id,
            type: content.type === 'picture' ? ContentType.PICTURE : ContentType.VIDEO,
            layout: this.getLayoutByLayoutId(eventContent.layout),
            source,
            period: eventContent.contents[0].period,
        };
    }

    private getLayoutByLayoutId(layoutId: EventEntityLayoutType): Layout | undefined {
        // no layout
        if (layoutId === 'default') {
            return {
                template: 'default',
            };
        }
        // on selected layout
        if (typeof layoutId === 'number') {
            // error case: layout is undefined
            // then it should be used: current layout
            return this.settings.getLayout(layoutId);
        }
        // on current layout = do nothing
        return undefined;
    }
}
