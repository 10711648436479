import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { from, of } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { cacheUrls } from '../../serviceworker/service-worker-api';
import { SettingsService } from './settings.service';
import { SyncServiceBase } from './sync.service.base';
var FileSyncServiceLite = /** @class */ (function () {
    function FileSyncServiceLite(settings, injector) {
        this.settings = settings;
        this.injector = injector;
    }
    FileSyncServiceLite.prototype.getContentFileURL = function (name) {
        var id = name.split('.')[0];
        return this.settings.getContent(Number(id)).url;
    };
    FileSyncServiceLite.prototype.getLayoutFileURL = function (name) {
        var asset = this.settings.getLayoutAssets().find(function (a) { return a.id === name; });
        return asset ? asset.url : undefined;
    };
    FileSyncServiceLite.prototype.getHtmlTemplateAssetFileURL = function (name) {
        var asset = this.injector.get(SyncServiceBase)
            .syncData.html_template_assets.find(function (a) { return a.id === Number(name); });
        return asset.url;
    };
    FileSyncServiceLite.prototype.syncContentFiles = function (syncData) {
        var _this = this;
        var usedContents = syncData.contents.filter(function (content) { return content.used; });
        var totalSize = usedContents.reduce(function (size, content) { return size + content.size; }, 0);
        var currentSize = 0;
        return of.apply(void 0, usedContents).pipe(map(function (content) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, cacheUrls([content.url])];
                    case 1:
                        _a.sent();
                        currentSize += content.size;
                        return [2 /*return*/, { currentFile: content.name, currentSize: currentSize, totalSize: totalSize }];
                }
            });
        }); }), concatAll());
    };
    FileSyncServiceLite.prototype.syncHtmlTemplateAssets = function (syncData) {
        var _this = this;
        if (!syncData.html_template_assets) {
            return from([]);
        }
        var totalSize = syncData.html_template_assets.reduce(function (size, asset) { return size + asset.size; }, 0);
        var currentSize = 0;
        return of.apply(void 0, syncData.html_template_assets).pipe(map(function (asset) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, cacheUrls([asset.url])];
                    case 1:
                        _a.sent();
                        currentSize += asset.size;
                        return [2 /*return*/, { currentFile: asset.name, currentSize: 0, totalSize: totalSize }];
                }
            });
        }); }), concatAll());
    };
    FileSyncServiceLite.prototype.syncLayoutFiles = function (syncData) {
        return from([]);
    };
    return FileSyncServiceLite;
}());
export { FileSyncServiceLite };
