import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import {concat, from, merge, Observable, of, Subject} from 'rxjs';
import {
    catchError,
    concatMap,
    delay,
    filter,
    finalize,
    map,
    retryWhen,
    share,
    shareReplay,
    startWith,
    switchMap,
    timeout,
} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AbstractPlatform} from '../platforms/platform';
import {
    FileSyncError,
    FileSyncErrorType,
    FileSyncProgress,
    FileSyncServiceBase,
    mapFileSyncProgressToFormattedProgress,
} from './file-sync.service.base';

export enum ProgressAction {
    DOWNLOAD_CONFIG,
    DOWNLOAD_CONTENTS,
    DOWNLOAD_LAYOUTS,
    DOWNLOAD_HTML_TEMPLATE_ASSETS,
}

export enum SyncErrorType {
    DOWNLOAD_CONFIG_DENIED,
    DOWNLOAD_CONFIG_CLIENT,
    DOWNLOAD_CONFIG_SERVER,
    DOWNLOAD_CONFIG_GENERIC,
    DOWNLOAD_FILES,
    GENERIC,
}

export class SyncEvent {}

export class SyncStartEvent extends SyncEvent {}

export class SyncEndEvent extends SyncEvent {
    constructor(readonly success: boolean = true) {
        super();
    }
}

export class SyncConfigDownloadEvent extends SyncEvent {
    constructor(readonly syncData: SyncData, readonly modified: boolean, readonly initial: boolean) {
        super();
    }
}

export class SyncProgressEvent extends SyncEvent {
    constructor(readonly action: ProgressAction,
                readonly formattedProgress?: string) {
        super();
    }

    get formattedAction(): string {
        switch (this.action) {
            case ProgressAction.DOWNLOAD_CONFIG:
                return 'Verbinde zum Shop-IQ Server';
            case ProgressAction.DOWNLOAD_CONTENTS:
                return 'Herunterladen des Contents';
            case ProgressAction.DOWNLOAD_LAYOUTS:
                return 'Herunterladen der Layouts';
            case ProgressAction.DOWNLOAD_HTML_TEMPLATE_ASSETS:
                return 'Herunterladen der HTML Vorlagen';
            default:
                return '';
        }
    }
}

export class SyncErrorEvent extends SyncEvent {
    constructor(readonly type: SyncErrorType, readonly message?: string, readonly extra?: any) {
        super();
    }
}

export class FileSyncErrorEvent extends SyncErrorEvent {
    constructor(readonly fileSyncErrorType: FileSyncErrorType, readonly message?: string, readonly extra?: any) {
        super(SyncErrorType.DOWNLOAD_FILES, message, extra);
    }
}

export interface SyncData {
    settings: any;
    actions: Array<any>;
    cameras: Array<any>;
    contents: Array<any>;
    events: Array<any>;
    holidays: Array<any>;
    html_contents: Array<any>;
    html_template_assets: Array<any>;
    layouts: Array<any>;
    layout_assets: Array<any>;
    oven_decks: Array<any>;
    smart_screens: Array<any>;
}

/**
 * The standard sync workflow is as following:
 *
 * - We request the device configuration from the server
 *   + Authentication uses a single secret provided once on device setup from the server
 */
export abstract class SyncServiceBase {
    private static readonly SYNC_INTERVAL = 10 * 60 * 1000;
    readonly events = new Subject<SyncEvent>();
    protected readonly logger = Logger.get('sync');
    protected secret?: string;
    private _isSyncing = false;
    private scheduleTimer?: any;
    private lastSuccessfulConfigDownloadResponse?: HttpResponse<any>;
    private lastStoredConfig: any;

    protected constructor(protected http: HttpClient,
                          protected router: Router,
                          protected fileSync: FileSyncServiceBase,
                          protected platform: AbstractPlatform) {
        // Load the last stored config and remember it
        this.loadConfig().then(config => this.lastStoredConfig = config);

        // Also store the config after a successful config download
        this.events.pipe(filter((event: SyncEvent): event is SyncConfigDownloadEvent =>
            event instanceof SyncConfigDownloadEvent && event.modified,
        )).subscribe(event => this.storeConfig(event.syncData));

        this.events.subscribe(event => {
            if (event instanceof SyncErrorEvent) {
                this.logger.warn(`SyncErrorEvent: ${SyncErrorType[event.type]}: ${event.message}`);
            } else if (event instanceof SyncConfigDownloadEvent) {
                // Logging the entire config sometimes blocks forever on android.
                const {syncData, ...e} = event;
                this.logger.debug('SyncEvent: ' + event.constructor.name, e);
            } else {
                this.logger.debug('SyncEvent: ' + event.constructor.name, event);
            }
        });
    }

    // TODO: Remove when refactor is finished
    get syncData(): SyncData | undefined {
        if (this.lastSuccessfulConfigDownloadResponse) {
            return this.lastSuccessfulConfigDownloadResponse.body;
        }

        if (this.lastStoredConfig) {
            return this.lastStoredConfig;
        }

        return undefined;
    }

    get isSyncing(): boolean {
        return this._isSyncing;
    }

    start(): Observable<SyncEvent> | undefined {
        if (this.isSyncing) {
            this.logger.warn('Sync already running, exiting.');
            return undefined;
        }

        // Stop any scheduled syncs
        clearTimeout(this.scheduleTimer);
        this.scheduleTimer = undefined;

        // Create the whole sync pipeline / workflow here
        // Sync always starts with downloading the config
        const downloadConfig$ = this.downloadConfig().pipe(share());

        // Download files is triggered by a successful config download
        const downloadFiles$ = downloadConfig$.pipe(
            filter((event: SyncEvent): event is SyncConfigDownloadEvent => event instanceof SyncConfigDownloadEvent),
            switchMap(event => this.downloadFiles(event)),
        );

        const pipeline$ = merge(downloadConfig$, downloadFiles$).pipe(
            finalize(() => {
                this._isSyncing = false;
                this.scheduleTimer = setTimeout(this.start.bind(this), SyncServiceBase.SYNC_INTERVAL);
            }),
            // As we subscribe internally, we need replay to ensure that external subscribers get all events.
            shareReplay(),
        );

        // Subscribe and redirect to events (but only next, not complete or error)
        this._isSyncing = true;
        this.events.next(new SyncStartEvent());
        pipeline$.subscribe({
            next: event => this.events.next(event),
            complete: () => this.events.next(new SyncEndEvent()),
            error: error => {
                if (error instanceof SyncErrorEvent) {
                    this.events.next(error);
                } else {
                    this.events.next(new SyncErrorEvent(SyncErrorType.GENERIC, error.toString(), error));
                }
                this.events.next(new SyncEndEvent(false));
            },
        });

        return pipeline$;
    }

    set lockedContents(ids: Array<number>) {
        this.fileSync.lockedContents = ids;
    }

    hasSecret(): boolean {
        return this.secret !== undefined;
    }

    setSecret(secret: string | undefined): Promise<void> {
        this.secret = secret;
        return this.storeSecret();
    }

    /**
     * Loads secret and sets this.secret to the loaded secret.
     */
    abstract loadSecret(): Promise<void>;

    abstract storeSecret(): Promise<void>;

    protected abstract loadConfig(): Promise<any>;

    protected abstract storeConfig(config: any): Promise<void>;

    protected downloadConfig(): Observable<SyncEvent> {
        const url = environment.serverAddress + '/api/smartscreen/sync';
        const headers = new HttpHeaders({'If-None-Match': this.getLastConfigHash() || ''});
        const http$ = from(this.prepareSyncRequestBody()).pipe(
            switchMap(body => this.http.post(url, body, {
                params: {secret: this.secret as string},
                observe: 'response',
                headers,
            })),
        );

        return http$.pipe(
            timeout(60_000),
            catchError((response: HttpErrorResponse) => {
                if (response.status === 304) {
                    // server returned "not modified" response so we just continue using our last saved response
                    return of(new SyncConfigDownloadEvent(
                        (this.lastSuccessfulConfigDownloadResponse as HttpResponse<any>).body,
                        false,
                        false,
                    ));
                }

                if (response.status === 403) {
                    // Access denied (403) gets its own error type because it needs special handling
                    // (normally we return to the setup screen when this happens)
                    throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_DENIED);
                }

                // Everything else (e.g. server error 500, ...) gets rethrown (and retried)
                throw response;
            }),
            map((response: HttpResponse<any> | SyncConfigDownloadEvent) => {
                // If we previously converted a 304 to a sync event we just pass it through
                if (response instanceof SyncConfigDownloadEvent) {
                    return response;
                }

                // We only continue without error if the response is a HTTP 200
                // and the body is an object (parsed from json)
                if (response.status !== 200) {
                    throw new SyncErrorEvent(
                        SyncErrorType.DOWNLOAD_CONFIG_SERVER,
                        `${response.status}: ${response.statusText}`,
                    );
                }

                if (!(response.body instanceof Object)) {
                    throw new SyncErrorEvent(
                        SyncErrorType.DOWNLOAD_CONFIG_SERVER,
                        'Config download response is invalid JSON.',
                    );
                }

                const isInitialConfigDownload = this.lastSuccessfulConfigDownloadResponse === undefined;
                this.lastSuccessfulConfigDownloadResponse = response;
                return new SyncConfigDownloadEvent(response.body, true, isInitialConfigDownload);
            }),
            retryWhen(errors => errors.pipe(
                filter((error, index) => {
                    if (index >= 2  // Try it up to 3 times.
                        || this.lastSuccessfulConfigDownloadResponse === undefined  // no retry to speedup app startup
                        || (error instanceof SyncErrorEvent
                            && error.type === SyncErrorType.DOWNLOAD_CONFIG_DENIED) // no retry on access denied
                    ) {
                        throw error;
                    }
                    return true;
                }),
                concatMap((error, index) => {
                    const delayTime = index === 0 ? 30_000 : 120_000;
                    return of(error).pipe(delay(delayTime + ((Math.random() - 0.5) * delayTime)));
                }),
            )),
            // Convert any remaining errors to SyncErrorEvents
            catchError((error: any) => {
                if (error instanceof SyncErrorEvent) {
                    throw error;
                }

                // If it's a error response and the status isn't set or 0 it's a client side error
                if (error instanceof HttpErrorResponse && (!error.status || error.status === 0)) {
                    if (error.status === 0 && error.statusText === 'Unknown Error') {
                        // for net::ERR_CONNECTION_REFUSED,net::ERR_NAME_RESOLUTION_FAILED etc.
                        throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_CLIENT, 'Network Error', error.error);
                    }
                    throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_CLIENT, error.statusText, error.error);
                }

                // If it's a error response and we have a status code it's a server error
                if (error instanceof HttpErrorResponse && error.status) {
                    throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_SERVER, error.statusText, error);
                }

                throw new SyncErrorEvent(SyncErrorType.DOWNLOAD_CONFIG_GENERIC, error.toString(), error);
            }),
            startWith(new SyncProgressEvent(ProgressAction.DOWNLOAD_CONFIG, '')),
        );
    }

    protected async prepareSyncRequestBody(): Promise<any> {
        // Do all api calls simultaneously and with error logging.
        const deviceInfoPromises = this.getDeviceInfoPairs().map(async ([key, apiFunc]) => {
            try {
                return [key, await Promise.resolve(apiFunc())];
            } catch (e) {
                this.logger.debug(`Failed to get ${key} for device info: ${e.message}`);
                return undefined;
            }
        });

        const deviceInformation = _(await Promise.all(deviceInfoPromises))
            .filter((item): item is [string, any] => item !== undefined)
            // Replace undefined values by null as undefined is removed on serialization
            // tslint:disable-next-line:no-null-keyword
            .map(([key, value]) => [key, value !== undefined ? value : null])
            .fromPairs()
            .value();

        return {device_information: deviceInformation};
    }

    protected getDeviceInfoPairs(): Array<[string, () => any | Promise<any>]> {
        return [
            ['mac_address', this.platform.getMacAddress.bind(this.platform)],
            ['model', () => this.platform.model],
            ['app_update_url', () => this.platform.appUpdateUrl],
            ['app_version', _.constant(environment.release)],
            ['ip_address', this.platform.getIpAddress.bind(this.platform)],
            ['connected_wifi_ssid', this.platform.getConnectedWifiSsid.bind(this.platform)],
            ['connected_wifi_signal_strength', this.platform.getConnectedWifiSignalStrength.bind(this.platform)],
            [
                'internal_storage_available_capacity',
                this.platform.getInternalStorageAvailableCapacity.bind(this.platform),
            ],
        ];
    }

    protected downloadFiles(event: SyncConfigDownloadEvent): Observable<SyncEvent> {
        const downloadLayoutFiles$ = this.fileSync.syncLayoutFiles(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_LAYOUTS));
        const downloadContentFiles$ = this.fileSync.syncContentFiles(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_CONTENTS));
        const downloadHtmlTemplateAssetFiles$ = this.fileSync.syncHtmlTemplateAssets(event.syncData)
            .pipe(this.mapFileSyncProgressToSyncEventsOperator(ProgressAction.DOWNLOAD_HTML_TEMPLATE_ASSETS));

        return concat(downloadLayoutFiles$, downloadContentFiles$, downloadHtmlTemplateAssetFiles$)
            .pipe(catchError(this.fileSyncErrorHandler));
    }

    protected mapFileSyncProgressToSyncEventsOperator(
        action: ProgressAction,
    ): (source: Observable<FileSyncProgress>) => Observable<SyncProgressEvent> {

        return (source: Observable<FileSyncProgress>) =>
            source.pipe(
                mapFileSyncProgressToFormattedProgress(),
                startWith(''),
                map(formattedProgress => new SyncProgressEvent(action, formattedProgress)),
            );
    }

    // Method is not static to allow overriding in subclasses
    protected fileSyncErrorHandler(error: any): Observable<any> {
        if (error instanceof Error) {
            Sentry.captureException(error);
        }

        if (error instanceof SyncErrorEvent) {
            if (error.extra instanceof Error) {
                Sentry.captureException(error.extra);
            }

            throw error;
        }

        if (error instanceof FileSyncError) {
            throw new FileSyncErrorEvent(error.type, error.message, error.extra);
        }

        throw new FileSyncErrorEvent(
            FileSyncErrorType.GENERIC,
            error.message || error.toString(),
            error,
        );
    }

    private getLastConfigHash(): string | undefined {
        if (this.lastSuccessfulConfigDownloadResponse === undefined) {
            return undefined;
        }

        let hash = this.lastSuccessfulConfigDownloadResponse.headers.get('etag');
        if (!hash) {
            return undefined;
        }

        if (hash.startsWith('W/')) {
            hash = hash.substring(2);
        }

        if (hash.startsWith('"')) {
            hash = hash.substring(1, hash.length - 1);
        }

        return hash;
    }
}
