import * as tslib_1 from "tslib";
import { Type } from '@angular/core';
import * as Logger from 'js-logger';
import { merge, Observable, Subject } from 'rxjs';
import { filter, finalize, first, map, share, takeUntil, tap } from 'rxjs/operators';
import { SyncServiceBase } from '../sync/sync.service.base';
import { ConnectionCloseEvent, ConnectionEvent, ConnectionOpenEvent, DataReceivedEvent, } from './oven-data-types';
import { WachtelClient } from './wachtel-client';
var logger = Logger.get('oven-data-manager');
var UnsupportedTypeError = /** @class */ (function (_super) {
    tslib_1.__extends(UnsupportedTypeError, _super);
    function UnsupportedTypeError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UnsupportedTypeError;
}(Error));
export { UnsupportedTypeError };
var NotFoundError = /** @class */ (function (_super) {
    tslib_1.__extends(NotFoundError, _super);
    function NotFoundError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NotFoundError;
}(Error));
export { NotFoundError };
var OvenConnectionManagerService = /** @class */ (function () {
    function OvenConnectionManagerService(sync) {
        this.sync = sync;
        this.connections = new Map();
    }
    OvenConnectionManagerService.prototype.getDeckStatus$ = function (deckId) {
        var deckConfig = (this.sync.syncData || { oven_decks: [] }).oven_decks.find(function (d) { return d.id === deckId; });
        if (deckConfig === undefined) {
            throw new NotFoundError("Deck with id " + deckId + " not found.");
        }
        var deck$ = this.getOvenConnection(deckConfig)
            .pipe(filter(function (e) { return e instanceof DataReceivedEvent; }), map(function (e) { return e.data.decks[deckConfig.deck_number - 1]; }));
        // Log this only at first receive.
        var missingDeck$ = deck$.pipe(first(), filter(function (deck) { return deck === undefined; }), tap(function () { return logger.warn("There is no deck in status for deck number " + deckConfig.deck_number + " of deck with id " + deckId + "."); }));
        // Merge missingDeck$, although its single event will be filtered out,
        // because we want to inject logging without affecting teardown of deck$ and its parents.
        // This happens if we subscribe to deck$ instead and no data is received before external unsubscribe.
        return merge(deck$, missingDeck$).pipe(filter(function (deck) { return deck !== undefined; }));
    };
    /**
     * @return A shared stream of OvenEvents (connection status and data receive)
     *         which holds only one connection to each host for all subscribers.
     *         The connection is automatically opened of first subscribe and closed on unsubscribe of last subscriber.
     */
    OvenConnectionManagerService.prototype.getOvenConnection = function (deckConfig) {
        var _this = this;
        var host = deckConfig.address;
        // If we want one connection per oven/deck instance, we need a id based key.
        var connection = this.connections.get(host);
        if (connection !== undefined) {
            return connection;
        }
        // Don't throw exceptions in subscribe callback!
        var clientClass = OvenConnectionManagerService.getOvenClientClass(deckConfig.type);
        // One connection per host and automatic disconnect from client on last unsubscribe.
        connection = new Observable(function (subscriber) {
            logger.debug('Creating oven client to host: ' + host);
            var client = new clientClass(host);
            client.connect();
            var teardown = new Subject();
            var untilTeardownEvents = client.events.pipe(takeUntil(teardown));
            untilTeardownEvents.subscribe(subscriber.next.bind(subscriber));
            OvenConnectionManagerService.logConnectionEvents(untilTeardownEvents, deckConfig);
            return function () {
                teardown.next();
                client.disconnect();
                _this.connections.delete(host);
            };
        }).pipe(share());
        this.connections.set(host, connection);
        return connection;
    };
    OvenConnectionManagerService.logConnectionEvents = function (untilTeardownEvents, deckConfig) {
        var id = deckConfig.address_entity === 'Oven' ? deckConfig.oven_id : deckConfig.id;
        var prefix = "Connection of " + deckConfig.address_entity + " with id " + id + " to host " + deckConfig.address;
        untilTeardownEvents
            .pipe(filter(function (e) { return e instanceof ConnectionEvent; }))
            .pipe(finalize(function () { return logger.info(prefix + ' terminated.'); }))
            .subscribe(function (connectionEvent) {
            if (connectionEvent instanceof ConnectionOpenEvent) {
                logger.info(prefix + ' established.');
            }
            if (connectionEvent instanceof ConnectionCloseEvent) {
                // Because we unsubscribe before disconnect, close is here always an interruption.
                logger.warn(prefix + ' interrupted.');
            }
        });
    };
    OvenConnectionManagerService.getOvenClientClass = function (ovenType) {
        var type = OvenConnectionManagerService.ovenTypes.get(ovenType);
        if (type === undefined) {
            throw new UnsupportedTypeError("Type of oven " + ovenType + " is not supported");
        }
        return type;
    };
    OvenConnectionManagerService.ovenTypes = new Map([
        ['WACHTEL', WachtelClient],
    ]);
    return OvenConnectionManagerService;
}());
export { OvenConnectionManagerService };
