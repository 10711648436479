
        <div class="player">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>

        <div class="newsticker">
            <img src="assets/logo_n24.png">
            <siq-news-ticker></siq-news-ticker>
        </div>

        <img class="overlay" [src]="imageOverlay$ | async">

        <div class="date_and_clock">
            <siq-date></siq-date>
            <siq-clock [color]="clockColor$ | async"></siq-clock>
        </div>
    