import {captureException} from '@sentry/angular';
import * as Logger from 'js-logger';
import {Workbox} from 'workbox-window';
import {environment} from '../environments/environment';

const logger = Logger.get('service-worker-api');

let wb: Workbox | undefined;

export async function registerSW(): Promise<ServiceWorkerRegistration | undefined> {
    if ('serviceWorker' in navigator && environment.production) {
        try {
            wb = new Workbox('service-worker.js');
            return wb.register();
        } catch (e) {
            wb = undefined;
            captureException(e);
            logger.warn('Cant register service worker');
        }
    }
    return undefined;
}

export async function cacheUrls(urls: Array<string>): Promise<any> {
    if (wb !== undefined) {
        return wb.messageSW({
            type: 'CACHE_URLS',
            payload: {urlsToCache: urls},
        });
    }
    if ('serviceWorker' in navigator && environment.production) {
        logger.warn('service worker not initialized');
    }
}
