import * as tslib_1 from "tslib";
var SMPException = /** @class */ (function (_super) {
    tslib_1.__extends(SMPException, _super);
    function SMPException(message) {
        var _this = _super.call(this, message) || this;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, SMPException.prototype);
        return _this;
    }
    return SMPException;
}(Error));
export { SMPException };
var SMPDisconnectedException = /** @class */ (function (_super) {
    tslib_1.__extends(SMPDisconnectedException, _super);
    function SMPDisconnectedException() {
        var _this = _super.call(this, 'disconnected') || this;
        Object.setPrototypeOf(_this, SMPDisconnectedException.prototype);
        return _this;
    }
    return SMPDisconnectedException;
}(SMPException));
export { SMPDisconnectedException };
var SMPSendNotConnectedException = /** @class */ (function (_super) {
    tslib_1.__extends(SMPSendNotConnectedException, _super);
    function SMPSendNotConnectedException() {
        var _this = _super.call(this, 'Cannot send while not connected') || this;
        Object.setPrototypeOf(_this, SMPSendNotConnectedException.prototype);
        return _this;
    }
    return SMPSendNotConnectedException;
}(SMPException));
export { SMPSendNotConnectedException };
var SMPRemoteErrorException = /** @class */ (function (_super) {
    tslib_1.__extends(SMPRemoteErrorException, _super);
    function SMPRemoteErrorException(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, SMPRemoteErrorException.prototype);
        return _this;
    }
    return SMPRemoteErrorException;
}(SMPException));
export { SMPRemoteErrorException };
var SMPEndpointAlreadyRegisteredException = /** @class */ (function (_super) {
    tslib_1.__extends(SMPEndpointAlreadyRegisteredException, _super);
    function SMPEndpointAlreadyRegisteredException() {
        var _this = _super.call(this, 'endpoint_already_registered') || this;
        Object.setPrototypeOf(_this, SMPEndpointAlreadyRegisteredException.prototype);
        return _this;
    }
    return SMPEndpointAlreadyRegisteredException;
}(SMPException));
export { SMPEndpointAlreadyRegisteredException };
export var SMPMessageType;
(function (SMPMessageType) {
    SMPMessageType[SMPMessageType["SUCCESS"] = 1] = "SUCCESS";
    SMPMessageType[SMPMessageType["ERROR"] = 2] = "ERROR";
    SMPMessageType[SMPMessageType["AUTH"] = 3] = "AUTH";
    SMPMessageType[SMPMessageType["PING"] = 4] = "PING";
    SMPMessageType[SMPMessageType["REGISTER"] = 10] = "REGISTER";
    SMPMessageType[SMPMessageType["CALL"] = 11] = "CALL";
    SMPMessageType[SMPMessageType["SUBSCRIBE"] = 20] = "SUBSCRIBE";
    SMPMessageType[SMPMessageType["SUBSCRIBE_REPLAY"] = 24] = "SUBSCRIBE_REPLAY";
    SMPMessageType[SMPMessageType["UNSUBSCRIBE"] = 21] = "UNSUBSCRIBE";
    SMPMessageType[SMPMessageType["PUBLISH"] = 22] = "PUBLISH";
    SMPMessageType[SMPMessageType["EVENT"] = 23] = "EVENT";
})(SMPMessageType || (SMPMessageType = {}));
var SMPOpenEvent = /** @class */ (function () {
    /**
     * @param isReconnect If true then this connection was already open once and is now reconnecting.
     */
    function SMPOpenEvent(isReconnect) {
        if (isReconnect === void 0) { isReconnect = false; }
        this.isReconnect = isReconnect;
    }
    return SMPOpenEvent;
}());
export { SMPOpenEvent };
var SMPCloseEvent = /** @class */ (function () {
    /**
     * @param isClosing If true then .close() was called and the connection will not reconnect.
     */
    function SMPCloseEvent(isClosing) {
        if (isClosing === void 0) { isClosing = false; }
        this.isClosing = isClosing;
    }
    return SMPCloseEvent;
}());
export { SMPCloseEvent };
var SMPMessageEvent = /** @class */ (function () {
    function SMPMessageEvent(message) {
        this.message = message;
    }
    return SMPMessageEvent;
}());
export { SMPMessageEvent };
