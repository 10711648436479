import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { BehaviorSubject, interval, of } from 'rxjs';
import { delayWhen, map, skip } from 'rxjs/operators';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
import { createIframeSrcDoc } from './iframe-utils';
import { ContentType } from "../scheduler.service.base";
var HtmlOverlayLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HtmlOverlayLayoutComponent, _super);
    function HtmlOverlayLayoutComponent(settings, fileSync, sanitizer) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.sanitizer = sanitizer;
        // Counts the images played in a row.
        _this.imagePlayCount$ = new BehaviorSubject(0);
        _this.htmlCode$ = createHtmlCode$(_this.fileSync, _this.settings, _this.sanitizer, _this.parameters$, _this.imagePlayCount$);
        _this.contentStyle$ = createContentStyle$(_this.parameters$);
        return _this;
    }
    HtmlOverlayLayoutComponent.prototype.play = function (element) {
        if (element.type === ContentType.PICTURE) {
            this.imagePlayCount$.next(this.imagePlayCount$.value + 1);
        }
        else {
            this.imagePlayCount$.next(0);
        }
        return _super.prototype.play.call(this, element);
    };
    HtmlOverlayLayoutComponent.prototype.stop = function () {
        _super.prototype.stop.call(this);
        this.imagePlayCount$.next(0);
    };
    return HtmlOverlayLayoutComponent;
}(BaseLayoutComponent));
export { HtmlOverlayLayoutComponent };
function createHtmlCode$(fileSync, settings, sanitizer, parameters$, imagePlayCount$) {
    var htmlCodeDelay$ = imagePlayCount$.pipe(
    // Skip the current value.
    skip(1), 
    // Delay html overlay by half of duration of image player fading between images
    delayWhen(function (count) { return count > 1 ? interval(500) : of(0); }));
    return parameters$.pipe(map(function (parameters) {
        if (parameters === undefined) {
            return '';
        }
        var assets = settings.getHtmlTemplateAssetsForTemplate(parameters.template);
        var source = createIframeSrcDoc(parameters.overlay_html, assets, fileSync);
        return sanitizer.bypassSecurityTrustHtml(source);
    }), delayWhen(function () { return htmlCodeDelay$; }));
}
function createContentStyle$(parameters$) {
    var addPx = function (pixel) { return pixel ? pixel + "px" : undefined; };
    return parameters$.pipe(map(function (parameters) { return ({
        zIndex: '0',
        position: 'absolute',
        left: addPx(_.get(parameters, 'content_dimensions.posX', 0)),
        top: addPx(_.get(parameters, 'content_dimensions.posY', 0)),
        width: addPx(_.get(parameters, 'content_dimensions.width')) || '100%',
        height: addPx(_.get(parameters, 'content_dimensions.height')) || '100%',
    }); }));
}
