import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {HtmlTemplateAsset} from '../../sync/settings.service';

export function createIframeSrcDoc(
    source: string,
    assets: Array<HtmlTemplateAsset>,
    fileSync: FileSyncServiceBase): string {
    let srcDoc = source;
    for (const asset of assets) {
        const assetUrl = fileSync.getHtmlTemplateAssetFileURL(asset.id.toString());
        if (assetUrl) {
            srcDoc = srcDoc.replace(new RegExp(asset.name, 'g'), assetUrl);
        }
    }

    return srcDoc;
}
