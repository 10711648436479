import {OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as Logger from 'js-logger';
import {EMPTY, Observable} from 'rxjs';
import {catchError, endWith, filter, map} from 'rxjs/operators';

import {SyncErrorEvent, SyncErrorType, SyncEvent, SyncProgressEvent, SyncServiceBase,} from './sync.service.base';

const logger = Logger.get('sync-loader');

export class SyncLoaderPageBaseComponent implements OnInit {
    progress!: Observable<string>;

    constructor(private sync: SyncServiceBase, private router: Router) {}

    ngOnInit(): void {
        logger.info('Entered sync-loader page. Starting sync and displaying progress');
        const syncEvents$ = this.sync.start() as Observable<SyncEvent>;

        // Navigate to player after sync ends
        // (with the exception of 403 on config download, which navigates back to setup)
        syncEvents$.subscribe({
            complete: () => {
                logger.info('Sync completed successfully. Navigating to player.');

                // Wait a tick before navigating to the player
                // Otherwise the last text ('Player wird gestartet') never shows
                setTimeout(() => this.router.navigate(['player']), 0);
            },
            error: error => {
                if (error instanceof SyncErrorEvent && error.type === SyncErrorType.DOWNLOAD_CONFIG_DENIED) {
                    logger.info('Access denied while downloading config. Navigating back to setup page.');
                    return this.router.navigate(['setup']);
                }

                logger.warn('Error while syncing. Navigating to player and trying to continue as normal.');
                return this.routerNavigatePlayerOnSyncError();
            },
        });

        this.progress = syncEvents$.pipe(
            // ignore errors on this observable (these get already handled above)
            catchError(() => EMPTY),
            filter((event => event instanceof SyncProgressEvent) as (e: SyncEvent) => e is SyncProgressEvent),
            map(event => SyncLoaderPageBaseComponent.formatProgressEvent(event)),
            endWith('Player wird gestartet'),
        );
    }

    protected routerNavigatePlayerOnSyncError(): void {
        this.router.navigate(['player']);
    }

    protected static formatProgressEvent(event: SyncProgressEvent): string {
        return `${event.formattedAction}<br/>${event.formattedProgress}`;
    }
}
