import {Component} from '@angular/core';
import {WeatherForecastBaseComponent} from './base-weather-forecast.component';
import {SettingsService} from '../sync/settings.service';

@Component({
    selector: 'siq-weather-forecast-landscape',
    styles: [`
        :host {
            display: block;
            height: inherit;
        }
    `],
    // language=Angular2HTML
    template: `
        <table style="width: 100%; height: 100%">
            <tr>
                <td *ngFor="let day of days$ | async; trackBy: dayTrackByFn">
                    <siq-day-weather-portrait [weatherData]="day"
                                              [shortTemperatureFormat]="shortTemperatureFormat">
                    </siq-day-weather-portrait>
                </td>
            </tr>
        </table>
    `,
})
export class WeatherForecastLandscapeComponent extends WeatherForecastBaseComponent {
    constructor(settings: SettingsService) {
        super(settings);
    }
}
