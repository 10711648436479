import * as tslib_1 from "tslib";
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import { defer, forkJoin, of } from 'rxjs';
import { catchError, concatMap, delay, distinctUntilChanged, expand, first, map, retry, retryWhen, shareReplay, switchMap, takeWhile, timeout, } from 'rxjs/operators';
import { NodeClientService } from '../shared/node-client-service';
import { TimeService } from '../shared/time.service';
import { SettingsService } from '../sync/settings.service';
var logger = Logger.get('hella-aps');
var HellaApsService = /** @class */ (function () {
    function HellaApsService(settings, nodeClient) {
        this.settings = settings;
        this.nodeClient = nodeClient;
        this.cameras$ = createApsCameras$(this.settings);
        this.peopleCount$ = this.createPeopleCount(this.cameras$);
    }
    HellaApsService.prototype.start = function () {
        var _this = this;
        // Schedule daily reset of line counts of all cameras.
        var startTime = TimeService.now();
        var todayResetTime = startTime.clone().set(HellaApsService.RESET_TIME);
        var diff = startTime.diff(todayResetTime);
        var initialDelay = diff > 0 && diff < HellaApsService.RESET_CATCHUP_TIME
            ? 0
            : todayResetTime.clone().add(diff >= 0 ? 1 : 0, HellaApsService.RESET_PERIOD).diff(startTime);
        var resetTimer$ = of(undefined).pipe(delay(initialDelay), expand(function () {
            var now = TimeService.now();
            return of(undefined).pipe(delay(now.clone()
                .set(HellaApsService.RESET_TIME)
                .add(1, HellaApsService.RESET_PERIOD)
                .diff(now)));
        }));
        resetTimer$.subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var cameras;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cameras$.pipe(first()).toPromise()];
                    case 1:
                        cameras = _a.sent();
                        return [4 /*yield*/, Promise.all(cameras.map(function (camera) {
                                return _this.resetCountsWithRetry$(camera.ip_address, camera.password).toPromise();
                            }))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    HellaApsService.prototype.resetCounts = function (ip, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.debug('Resetting line counts of Hella APS with ip: ' + ip);
                        return [4 /*yield*/, this.getToken(ip, password)];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, this.nodeClient.sendHttpsRequest({
                                requestOptions: {
                                    hostname: ip,
                                    port: HellaApsService.API_PORT,
                                    path: HellaApsService.COUNTS_PATH,
                                    method: 'PUT',
                                    headers: {
                                        Authorization: "Bearer " + token,
                                    },
                                },
                                rejectUnauthorized: false,
                            })];
                    case 2:
                        _a.sent();
                        logger.info('Successfully reset line counts of Hella APS with ip: ' + ip);
                        return [2 /*return*/];
                }
            });
        });
    };
    HellaApsService.prototype.resetCountsWithRetry$ = function (ip, password) {
        var _this = this;
        return defer(function () { return _this.resetCounts(ip, password); }).pipe(timeout(HellaApsService.RESET_TIMEOUT), retryWhen(function (errors) { return errors.pipe(takeWhile(function (e) {
            var maxTimeDiff = HellaApsService.RESET_CATCHUP_TIME - HellaApsService.RESET_RETRY_DELAY;
            var now = TimeService.now();
            if (now.diff(now.clone().set(HellaApsService.RESET_TIME)) <= maxTimeDiff) {
                logger.warn("Failed to reset Hella APS with ip " + ip + ": " + e.message);
                return true;
            }
            logger.error("Finally failed to reset Hella APS with ip " + ip + ": " + e.message);
            return false;
        }), delay(HellaApsService.RESET_RETRY_DELAY)); }));
    };
    HellaApsService.prototype.getToken = function (ip, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.nodeClient.sendHttpsRequest({
                            requestOptions: {
                                hostname: ip,
                                port: HellaApsService.API_PORT,
                                path: '/auth',
                                method: 'POST',
                            },
                            body: { username: 'user-role-edit', password: password },
                            rejectUnauthorized: false,
                        })];
                    case 1: return [2 /*return*/, (_a.sent()).access_token];
                }
            });
        });
    };
    HellaApsService.prototype.pollCounts = function (ip, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken(ip, password)];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, this.nodeClient.sendHttpsRequest({
                                requestOptions: {
                                    hostname: ip,
                                    port: HellaApsService.API_PORT,
                                    path: HellaApsService.COUNTS_PATH,
                                    method: 'GET',
                                    headers: {
                                        Authorization: "Bearer " + token,
                                    },
                                },
                                rejectUnauthorized: false,
                            })];
                    case 2: return [2 /*return*/, (_a.sent()).counts];
                }
            });
        });
    };
    HellaApsService.prototype.pollCountsWithRetry$ = function (ip, password) {
        var _this = this;
        return defer(function () { return _this.pollCounts(ip, password); }).pipe(timeout(HellaApsService.POLL_TIMEOUT), catchError(function (e) {
            logger.debug("Error while polling counts of Hella APS with ip " + ip + ": " + e.message);
            throw e;
        }), retry(2));
    };
    HellaApsService.prototype.createPeopleCount = function (cameras$) {
        var _this = this;
        return cameras$.pipe(switchMap(function (cameras) { return cameras.length > 0
            ? forkJoin(cameras.map(function (camera) { return _this.pollCountsWithRetry$(camera.ip_address, camera.password); })).pipe(
            // Combine cameras, lines and age groups
            map(function (counts) { return _(counts)
                .flatten()
                .map('data')
                .flatten()
                .value(); }), map(function (counts) { return counts.length > 0
                ? _.sumBy(counts, function (count) { return count.in - count.out; })
                : undefined; }), catchError(function () { return of(undefined); }), loop(HellaApsService.POLL_DELAY))
            : of(undefined); }), shareReplay({ bufferSize: 1, refCount: true }));
    };
    HellaApsService.API_PORT = 8091;
    HellaApsService.COUNTS_PATH = '/apiv1/sensorData/counts';
    HellaApsService.POLL_TIMEOUT = 3000;
    HellaApsService.POLL_DELAY = 3000;
    HellaApsService.RESET_TIME = { hour: 3, minute: 0, second: 0, millisecond: 0 };
    HellaApsService.RESET_PERIOD = 'days';
    HellaApsService.RESET_CATCHUP_TIME = 60 * 60 * 1000;
    HellaApsService.RESET_TIMEOUT = 30000;
    HellaApsService.RESET_RETRY_DELAY = 60000;
    return HellaApsService;
}());
export { HellaApsService };
function createApsCameras$(settings) {
    return settings.getCameras$().pipe(map(function (cameras) { return cameras.filter(function (camera) { return camera.type === 'HELLA_AGLAIA_APS'; }); }), distinctUntilChanged());
}
function loop(sleep) {
    return function (source) {
        return source.pipe(expand(function () { return source.pipe(subscribeDelayed(sleep)); }));
    };
}
function subscribeDelayed(sleep) {
    return function (source) { return of(undefined).pipe(delay(sleep), concatMap(function () { return source; })); };
}
