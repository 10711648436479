import {ChangeDetectorRef, Component, EventEmitter, Output} from '@angular/core';
import * as Logger from 'js-logger';

import {PlaylistEntry} from './scheduler.service';

const logger = Logger.get('image-player');

@Component({
    selector: 'siq-image-player',
    styles: [`
        :host {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        :host img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .hidden {
            z-index: 10;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 1.5s, opacity 1s linear;
        }
    `],
    template: `
        <img *ngIf="entries[0] != null" [hidden]="hidden" [ngClass]="{hidden: entryIdx != 1}" [src]="entrySources[0]">
        <img *ngIf="entries[1] != null" [hidden]="hidden" [ngClass]="{hidden: entryIdx != 2}" [src]="entrySources[1]">
        <img *ngIf="entries[2] != null" [hidden]="hidden" [ngClass]="{hidden: entryIdx != 0}" [src]="entrySources[2]">
    `,
})
export class ImagePlayerComponent {
    @Output() readonly completed: EventEmitter<any> = new EventEmitter();
    entries: Array<PlaylistEntry> = [];
    entrySources: Array<string> = [];
    entryIdx = 0;
    hidden = true;

    private playTimer?: any;

    constructor(private cda: ChangeDetectorRef) {}

    prepare(entry: PlaylistEntry): void {
        logger.debug('preparing image for display ' + entry.source);
        this.entries[this.entryIdx] = entry;
        this.entrySources[this.entryIdx] = `${entry.source}`;
    }

    async play(): Promise<void> {
        logger.debug('playing image: ' + this.entries[this.entryIdx].source);

        this.playTimer = setTimeout(() => {
            logger.debug('finished play image');
            this.playTimer = undefined;
            this.completed.emit();
        }, (this.entries[this.entryIdx].period || 10) * 1000);

        this.hidden = false;
        this.entryIdx = (this.entryIdx + 1) % 3;

        // Ensure that image is really displayed on screen when this play-method returns.
        this.cda.detectChanges();
        await new Promise(requestAnimationFrame);
        await new Promise(requestAnimationFrame);
    }

    stop(): void {
        if (this.playTimer === undefined) {
            this.hidden = true;
            return;
        }
        clearTimeout(this.playTimer);
        this.playTimer = undefined;
        this.hidden = true;
    }
}
