import {Component, Input} from '@angular/core';
import {DayWeatherData} from '../sync/settings.service';

@Component({
    selector: 'siq-day-weather-portrait',
    styles: [`
        :host {
            display: block;
            text-align: center;
            font-size: 20px;
        }

        .fal {
            font-size: 70px;
            line-height: 120%;
        }
    `],
    // language=Angular2HTML
    template: `
        <i class="fal" [ngClass]="weatherData.icon"></i>
        <br>
        <span>{{ weatherData.date | date:'EEEEEE dd.MM.' }}</span>
        <div>
            <span *ngIf="shortTemperatureFormat">
                {{ weatherData.maximum_temperature | number:'1.0-0' }}°C/{{ weatherData.minimum_temperature | number:'1.0-0' }}°C
            </span>
            <span *ngIf="!shortTemperatureFormat">
                {{ weatherData.maximum_temperature | number:'1.0-1' }}°C / {{ weatherData.minimum_temperature | number:'1.0-1' }}
                °C
            </span>
        </div>
    `,
})
export class DayWeatherPortraitComponent {
    /** required */
    @Input() weatherData!: DayWeatherData;
    @Input() shortTemperatureFormat = false;
}
