/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./playlist-player.component";
import * as i3 from "../platforms/platform";
import * as i4 from "../sync/sync.service.base";
import * as i5 from "../sync/settings.service";
import * as i6 from "./scheduler.service";
import * as i7 from "./event-scheduler.service";
import * as i8 from "./playlist-sync.service";
import * as i9 from "../shared/controller-client.service.base";
var styles_PlaylistPlayerComponent = ["[_nghost-%COMP%] {\n            width: 100%;\n            height: 100%;\n            position: fixed;\n        }\n\n        .error-text[_ngcontent-%COMP%] {\n            position: absolute;\n            bottom: 10px;\n            width: 100%;\n            font-size: 20px;\n            color: #F9BC15;\n            background-color: black;\n            text-align: center;\n            z-index: 100;\n        }\n\n        Label[_ngcontent-%COMP%] {\n            margin-top: 140px;\n            font-size: 20px;\n            color: #F9BC15;\n            text-align: center;\n        }"];
var RenderType_PlaylistPlayerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PlaylistPlayerComponent, data: {} });
export { RenderType_PlaylistPlayerComponent as RenderType_PlaylistPlayerComponent };
function View_PlaylistPlayerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_PlaylistPlayerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { componentOutlet: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistPlayerComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i1.NgComponentOutlet, [i0.ViewContainerRef], { ngComponentOutlet: [0, "ngComponentOutlet"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layoutComponentClass; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorText; _ck(_v, 4, 0, currVal_1); }); }
export function View_PlaylistPlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-playlist-player", [], null, null, null, View_PlaylistPlayerComponent_0, RenderType_PlaylistPlayerComponent)), i0.ɵdid(1, 4374528, null, 0, i2.PlaylistPlayerComponent, [i0.ChangeDetectorRef, i3.AbstractPlatform, i4.SyncServiceBase, i5.SettingsService, i6.SchedulerService, i7.EventSchedulerService, [2, i8.PlaylistSyncService], i9.ControllerClientServiceBase], null, null)], null, null); }
var PlaylistPlayerComponentNgFactory = i0.ɵccf("siq-playlist-player", i2.PlaylistPlayerComponent, View_PlaylistPlayerComponent_Host_0, {}, {}, []);
export { PlaylistPlayerComponentNgFactory as PlaylistPlayerComponentNgFactory };
