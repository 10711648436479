import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import * as Logger from 'js-logger';
import { combineLatest, concat, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, last, map, pairwise, pluck, takeUntil, withLatestFrom, } from 'rxjs/operators';
import { HellaApsService } from '../../connect/hella-aps.service';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var logger = Logger.get('hella-access-control-layout');
var HellaAccessControlLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HellaAccessControlLayoutComponent, _super);
    function HellaAccessControlLayoutComponent(settings, fileSync, hellaAps) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.hellaAps = hellaAps;
        _this.imageStop$ = _this.getLayoutImage$('image_stop');
        _this.imageGo$ = _this.getLayoutImage$('image_go');
        _this.peopleCount$ = _this.hellaAps.peopleCount$;
        _this.allowedPeopleLeft$ = createAllowedPeopleLeft$(_this.peopleCount$, _this.parameters$);
        _this.go$ = _this.allowedPeopleLeft$.pipe(map(function (allowedPeopleLeft) { return (allowedPeopleLeft || 0) > 0; }));
        _this.imageSrc$ = createImageSrc$(_this.go$, _this.imageStop$, _this.imageGo$);
        _this.ngUnsubscribe = new Subject();
        _this.audio = new Audio();
        _this.audioPlaying = false;
        return _this;
    }
    HellaAccessControlLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.peopleCount$.pipe(first()).subscribe(function (count) {
            return logger.info("Starting with people count " + count + ".");
        });
        this.peopleCount$.pipe(takeUntil(this.ngUnsubscribe), last()).subscribe(function (count) {
            return logger.info("Ending with people count " + count + ".");
        });
        // Play stop sound when traffic light switches to stop.
        this.go$.pipe(distinctUntilChanged(), filter(function (go) { return !go && !_this.audioPlaying; }), takeUntil(this.ngUnsubscribe)).subscribe(function () { return _this.playAudio('assets/stop.mp3'); });
        // Play alarm when next person enters if limit is already reached.
        this.peopleCount$.pipe(pairwise(), filter(function (counts) { return !counts.includes(undefined); }), filter(function (_a) {
            var oldCount = _a[0], actCount = _a[1];
            return actCount > oldCount;
        }), pluck('1'), withLatestFrom(this.parameters$), filter(function (_a) {
            var count = _a[0], parameters = _a[1];
            return count > parameters.people_count_legal + (parameters.employee_count || 0);
        }), filter(function () { return !_this.audioPlaying; }), takeUntil(this.ngUnsubscribe)).subscribe(
        // Duration of alarm.mp3 is about 0.85s. Play it 17 times to get alarm duration nearly 15s.
        function () { return _this.playAudio('assets/alarm.mp3', 17); });
    };
    HellaAccessControlLayoutComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    HellaAccessControlLayoutComponent.prototype.playAudio = function (src, repeat) {
        var _this = this;
        if (repeat === void 0) { repeat = 1; }
        return new Promise(function (resolve, reject) {
            if (_this.audioPlaying) {
                reject();
            }
            var endedCount = 0;
            _this.audioPlaying = true;
            _this.audio.src = src;
            _this.audio.onended = function () {
                endedCount += 1;
                if (endedCount < repeat) {
                    _this.audio.play();
                    return;
                }
                _this.audioPlaying = false;
                resolve();
            };
            _this.audio.load();
            _this.audio.play();
        });
    };
    return HellaAccessControlLayoutComponent;
}(BaseLayoutComponent));
export { HellaAccessControlLayoutComponent };
function createAllowedPeopleLeft$(peopleCount$, parameters$) {
    return combineLatest([
        peopleCount$,
        parameters$
    ]).pipe(map(function (_a) {
        var peopleCount = _a[0], parameters = _a[1];
        if (peopleCount === undefined) {
            return undefined;
        }
        var visitorCount = Math.max(0, peopleCount - (parameters.employee_count || 0));
        return Math.max(0, parameters.people_count_legal - visitorCount);
    }));
}
function createImageSrc$(go$, imageStop$, imageGo$) {
    return concat(imageStop$.pipe(first()), combineLatest([go$, imageStop$, imageGo$]).pipe(map(function (_a) {
        var go = _a[0], imageStop = _a[1], imageGo = _a[2];
        return go ? imageGo : imageStop;
    })));
}
