import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

interface Parameters {
    iframe_url: string;
    iframe_reload_interval?: number;  // in minutes
    image_bottom: string;
    color_bottom: string;
}

@Component({
    selector: 'siq-weather-news-portrait-layout',
    styles: [`
        .playlist {
            position: relative;
            height: 608px;
        }

        siq-full-hd-iframe {
            height: 608px;
        }

        .bottom {
            position: relative;
            height: 704px;
        }

        siq-weather-forecast-portrait {
            position: absolute;
            top: 10%;
            width: 50%;
            height: 80%;
            font-size: 40px;
        }
    `],
    // language=Angular2HTML
    template: `
        <div class="playlist">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>

        <siq-full-hd-iframe [sourceUrl]="iframeUrl$ | async"
                            [reloadInterval]="iframeReloadInterval$ | async">
        </siq-full-hd-iframe>

        <div class="bottom">
            <img [src]="imageBottom$ | async" alt="">

            <siq-weather-forecast-portrait [style.color]="weatherColor$ | async"
                                           [shortTemperatureFormat]="true">
            </siq-weather-forecast-portrait>

        </div>
    `,
})
export class WeatherNewsPortraitLayoutComponent extends BaseLayoutComponent {
    readonly parameters$!: Observable<Parameters>;
    readonly iframeUrl$ = this.parameters$.pipe(pluck('iframe_url'));
    readonly iframeReloadInterval$ = this.parameters$.pipe(pluck('iframe_reload_interval'));
    readonly imageBottom$ = this.getLayoutImage$('image_bottom');
    readonly weatherColor$ = this.parameters$.pipe(pluck('color_bottom'));

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}
