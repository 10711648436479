import {SMPCloseEvent, SMPConnection, SMPEvent, SMPOpenEvent, WebsocketTransport} from '@shopiq/smp';
import * as Logger from 'js-logger';
import {ILogLevel} from 'js-logger/src/types';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, of, Subject} from 'rxjs';
import {filter, first, map, shareReplay, startWith} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {AbstractPlatform} from '../platforms/platform';
import {SettingKeys, SettingsService} from '../sync/settings.service';
import {SyncEndEvent, SyncErrorEvent, SyncServiceBase, SyncStartEvent} from '../sync/sync.service.base';
import {addLogHandler, removeLogHandler} from './logging';
import {TimeService} from './time.service';

export type ControllerEvent = SMPEvent | ControllerConnectingEvent;

export class ControllerConnectingEvent {}

export interface ScreenInfo {
    // general app information
    app_version: string;
    app_server: string;

    // information about the current device
    model?: string;
    serial?: string;
    firmware_version?: string;

    // network information
    mac_address?: string;
    ip_address?: string;
    connected_wifi_ssid?: string;
    connected_wifi_signal_strength?: number;

    // system state information
    current_time: number;
    panel_status: boolean;
    system_up_time?: number;
    cpu_load?: number;
    internal_storage_available_capacity?: number;

    // Tizen specific information
    url_launcher_address?: string;
    magicinfo_server_url?: string;
    infrared_remote_control_lock?: boolean;
    current_temperature?: number;
}

export abstract class ControllerClientServiceBase {
    protected logger = Logger.get('controller');
    protected connection?: SMPConnection;
    private eventsSubject = new Subject<ControllerEvent>();
    private screenCaptureState$: Observable<boolean> = of(false);
    private screenCaptureTimer?: any;

    get events(): Observable<ControllerEvent> {
        return this.eventsSubject.asObservable();
    }

    get isConnectionStarted(): boolean {
        return this.connection !== undefined;
    }

    get connected(): boolean {
        return this.connection !== undefined && this.connection.connected;
    }

    protected get url(): string {
        return 'ssc.screen.' + this.settings.get(SettingKeys.SCREEN_ID);
    }

    protected constructor(protected platform: AbstractPlatform,
                          protected settings: SettingsService,
                          protected sync: SyncServiceBase,
                          protected timeService: TimeService,
                          private readonly patchZone: boolean = false,
                          private readonly transportClass: typeof WebsocketTransport = WebsocketTransport) {
        this.sync.events.subscribe(event => {
            if (this.connection === undefined || !this.connection.connected) {
                return;
            }

            if (event instanceof SyncErrorEvent) {
                this.connection.publish(this.url + '.sync_error', `${event.type.toString()}: ${event.message}`)
                    .catch(error => this.logger.debug(`Failed to publish sync error: ${error.message}`));
            }
            if (event instanceof SyncStartEvent) {
                this.connection.publishIfSubscribed(this.url + '.sync', true);
            }
            if (event instanceof SyncEndEvent) {
                this.connection.publishIfSubscribed(this.url + '.sync', false);
            }
        });
    }

    connect(): void {
        this.eventsSubject.next(new ControllerConnectingEvent());

        // Create connection
        this.connection = new SMPConnection(
            new this.transportClass(environment.controllerAddress, false, this.patchZone),
            () => Promise.resolve(this.settings.get(SettingKeys.CONTROLLER_TOKEN)),
        );
        this.connection.connect();

        // Setup log handler when connected & forward smp events
        this.connection.events.subscribe(e => {
            if (e instanceof SMPOpenEvent) {
                // ensure the previous logger handler is removed
                removeLogHandler('controller');
                addLogHandler('controller', this.remoteLogHandler.bind(this));
            }
            if (e instanceof SMPCloseEvent) {
                removeLogHandler('controller');
            }
            this.eventsSubject.next(e);
        });

        // Register our endpoints
        this.connection.register(this.url + '.info', this.getScreenInfo.bind(this));
        this.connection.register(this.url + '.reboot', () => {
            this.platform.reboot();
        });
        this.connection.register(this.url + '.sync', () => {
            this.sync.start();
        });
        this.connection.register(this.url + '.debug_eval', this.debugEval.bind(this));

        this.platform.panelEnabled$.subscribe(panelOnStatus => {
            if (this.connection !== undefined) {
                this.connection.publishIfSubscribed(this.url + '.panel', panelOnStatus);
            }
        });

        // Send screen captures if someone subscribes to them
        this.screenCaptureState$ = this.connection
            .subscribe$(`meta.topic_changed.${this.url}.screen_capture`)
            .pipe(
                map(change => change === 'CREATED'),
                // Ensure one value is always present when subscribing,
                // will be overridden by shareReplay() as soon as the first value arrives
                startWith(false),
                shareReplay(1),
            );
        this.screenCaptureState$
            .pipe(filter(state => state))
            .subscribe(() => this.sendScreenCapture());
    }

    sendContentChange(content: number): void {
        if (this.connection !== undefined) {
            this.connection.publishIfSubscribed(this.url + '.content', content);
        }
    }

    protected getScreenInfoPairs(): Array<[string, () => any | Promise<any>]> {
        return [
            ['app_update_url', () => this.platform.appUpdateUrl],
            ['app_version', _.constant(environment.release)],
            ['app_server', _.constant(environment.serverAddress)],
            ['serial', this.platform.getSerialNumber.bind(this.platform)],
            ['firmware_version', this.platform.getFirmwareVersion.bind(this.platform)],
            ['model', () => this.platform.model],
            ['mac_address', this.platform.getMacAddress.bind(this.platform)],
            ['ip_address', this.platform.getIpAddress.bind(this.platform)],
            ['connected_wifi_ssid', this.platform.getConnectedWifiSsid.bind(this.platform)],
            ['connected_wifi_signal_strength', this.platform.getConnectedWifiSignalStrength.bind(this.platform)],
            ['current_time', () => TimeService.now().unix()],
            ['panel_status', this.platform.getPanelEnabled.bind(this.platform)],
            ['system_up_time', () => this.platform.getSystemUptime() / 1000],
            ['cpu_load', this.platform.getCpuLoad.bind(this.platform)],
            [
                'internal_storage_available_capacity',
                this.platform.getInternalStorageAvailableCapacity.bind(this.platform),
            ],
        ];
    }

    protected async getScreenInfo(): Promise<ScreenInfo> {
        // Do all api calls simultaneously and with error logging.
        const infoPromises = this.getScreenInfoPairs().map(async ([key, apiFunc]) => {
            try {
                return [key, await Promise.resolve(apiFunc())];
            } catch (e) {
                this.logger.debug(`Failed to get ${key} for screen info: ${e.message}`);
                return undefined;
            }
        });

        return _(await Promise.all(infoPromises))
            .filter((item): item is [string, any] => item !== undefined)
            .fromPairs()
            .value() as ScreenInfo;
    }

    protected createDebugEvalContext(): any {
        return {
            TimeService,
            timeService: this.timeService,
            moment,
            environment,
            Logger,
            platform: this.platform,
            syncService: this.sync,
            settings: this.settings,
        };
    }

    private debugEval(cmd: string): void {
        try {
            // Make some variables accessible
            const context = this.createDebugEvalContext();

            return eval(cmd);
        } catch (e) {
            this.logger.error('Error while executing command: ' + e.message);
        }
    }

    private async remoteLogHandler(level: ILogLevel, name: string, messages: Array<string>): Promise<void> {
        if (!this.connection) {
             console.warn('Could not send log message to server. Dropping message.', messages);
             return;
        }
        try {
            await this.connection.call(
                'ssc.server.log',
                TimeService.nowMillis(false),
                name,
                level.name,
                messages,
            );
        } catch (e) {
            console.warn('Could not send log message to server. Dropping message.', e, messages);
        }
    }

    private async sendScreenCapture(interval: number = 5000): Promise<void> {
        if(!this.connection) {
            this.logger.debug('Not connected. Waiting before sending screen capture.');
            this.scheduleSendScreenCapture(interval);
            return;
        }
        // Don't send another screen capture as long as we're still sending data
        if (this.connection.bufferedAmount > 100) {
            this.logger.debug('Connection still has a send buffer > 100 bytes. Waiting before sending another screen capture.');
            this.scheduleSendScreenCapture(interval);
            return;
        }

        try {
            this.logger.trace('Capturing screen and publishing.');
            const imageBuffer = await this.platform.captureScreen();
            await this.connection.publish(this.url + '.screen_capture', imageBuffer);
        } catch (error) {
            this.logger.warn(`Could not capture screen: ${error.message}`);
        }

        this.scheduleSendScreenCapture(interval);
    }

    private scheduleSendScreenCapture(interval: number): void {
        if (this.screenCaptureTimer !== undefined) {
            this.logger.warn('scheduleSendScreenCapture() called while screen capture already scheduled');
            return;
        }

        this.screenCaptureTimer = setTimeout(async () => {
            this.screenCaptureTimer = undefined;

            // Stop sending if no one is subscribed anymore or we are disconnected
            const screenCaptureState = await this.screenCaptureState$.pipe(first()).toPromise();
            if (!this.connected || !screenCaptureState) {
                this.logger.debug('Stop sending screen captures.');
                return;
            }

            this.sendScreenCapture(interval);
        }, interval);
    }
}
