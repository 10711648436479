import * as Logger from 'js-logger';
import { EMPTY } from 'rxjs';
import { catchError, endWith, filter, map } from 'rxjs/operators';
import { SyncErrorEvent, SyncErrorType, SyncProgressEvent, } from './sync.service.base';
var logger = Logger.get('sync-loader');
var SyncLoaderPageBaseComponent = /** @class */ (function () {
    function SyncLoaderPageBaseComponent(sync, router) {
        this.sync = sync;
        this.router = router;
    }
    SyncLoaderPageBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        logger.info('Entered sync-loader page. Starting sync and displaying progress');
        var syncEvents$ = this.sync.start();
        // Navigate to player after sync ends
        // (with the exception of 403 on config download, which navigates back to setup)
        syncEvents$.subscribe({
            complete: function () {
                logger.info('Sync completed successfully. Navigating to player.');
                // Wait a tick before navigating to the player
                // Otherwise the last text ('Player wird gestartet') never shows
                setTimeout(function () { return _this.router.navigate(['player']); }, 0);
            },
            error: function (error) {
                if (error instanceof SyncErrorEvent && error.type === SyncErrorType.DOWNLOAD_CONFIG_DENIED) {
                    logger.info('Access denied while downloading config. Navigating back to setup page.');
                    return _this.router.navigate(['setup']);
                }
                logger.warn('Error while syncing. Navigating to player and trying to continue as normal.');
                return _this.routerNavigatePlayerOnSyncError();
            },
        });
        this.progress = syncEvents$.pipe(
        // ignore errors on this observable (these get already handled above)
        catchError(function () { return EMPTY; }), filter((function (event) { return event instanceof SyncProgressEvent; })), map(function (event) { return SyncLoaderPageBaseComponent.formatProgressEvent(event); }), endWith('Player wird gestartet'));
    };
    SyncLoaderPageBaseComponent.prototype.routerNavigatePlayerOnSyncError = function () {
        this.router.navigate(['player']);
    };
    SyncLoaderPageBaseComponent.formatProgressEvent = function (event) {
        return event.formattedAction + "<br/>" + event.formattedProgress;
    };
    return SyncLoaderPageBaseComponent;
}());
export { SyncLoaderPageBaseComponent };
