import {Component, Input} from '@angular/core';
import {ObservableInput} from 'observable-input/lib';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';

@Component({
    selector: 'siq-carwash-time-component',
    styles: [`
        :host {
            color: white;
            text-align: center;
            font-size: 112px;
            font-weight: bold;
            line-height: 85%;
        }

        .unit {
            font-size: 80px;
            vertical-align: baseline;
        }

        span {
            vertical-align: middle;
        }
    `],
    // language=Angular2HTML
    template: `
        <span style="display: inline-block; height: 100%; vertical-align: middle"></span>
        <span style="display: inline-block; vertical-align: middle">
            <span *ngIf="(status$ | async) === 'busy'">
                <span>FREI IN</span>
                <br>
                <span>
                    {{ timeMinutes$ | async | number:'2.0-0' }}:{{ (remainingTime | async) % 60 | number:'2.0-0' }}
                    <span class="unit">min</span>
                </span>
            </span>
            <span *ngIf="(status$ | async) === 'free'">JETZT FREI</span>
            <span *ngIf="(status$ | async) === 'connecting'">Verbinde...</span>
            <span *ngIf="(status$ | async) === 'connection_lost'">
                <i class="fal fa-exclamation-triangle"></i>
            </span>
        </span>
    `,
})
export class CarwashTimeComponent {
    @Input() @ObservableInput() remainingTime!: Observable<number>;

    readonly status$: Observable<string>;
    readonly timeMinutes$: Observable<number>;

    constructor() {
        this.status$ = CarwashTimeComponent.createStatus$(this.remainingTime);
        this.timeMinutes$ = this.remainingTime.pipe(map(time => Math.trunc(time / 60)));
    }

    private static createStatus$(remainingTime: Observable<number>): Observable<string> {
        return remainingTime.pipe(
            filter(time => time !== null),
            map(time => {
                if (time === undefined) {
                    return 'connection_lost';
                }
                return time === 0 ? 'free' : 'busy';
            }),
            startWith('connecting'),
        );
    }
}
