import { Injector } from '@angular/core';
import * as _ from 'lodash';
import { concat, defer, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FileSyncServiceBase } from './file-sync.service.base';
import { SyncConfigDownloadEvent, SyncServiceBase } from './sync.service.base';
export var SettingKeys;
(function (SettingKeys) {
    // sorted alphabetically
    SettingKeys["AMBRIGHT_IP_ADDRESS"] = "ambright.ip_address";
    SettingKeys["AMBRIGHT_LINE"] = "ambright.line";
    SettingKeys["AMBRIGHT_SEGMENT"] = "ambright.segment";
    SettingKeys["ANDROID_WIFI_NAME"] = "android.wifi_name";
    SettingKeys["ANDROID_WIFI_PASSWORD"] = "android.wifi_password";
    SettingKeys["AUTO_PARAMETRIZATION_ECO"] = "system_settings.auto_parametrization.eco_solution";
    SettingKeys["CONTROLLER_CLIENT_ENABLED"] = "controller_client_enabled";
    SettingKeys["CONTROLLER_TOKEN"] = "controller_token";
    SettingKeys["CUSTOMER_ID"] = "customer_id";
    SettingKeys["CUSTOMER_NAME"] = "customer_name";
    SettingKeys["IR_REMOTE_CONTROL_LOCK"] = "system_settings.infrared_remote_control_lock";
    SettingKeys["OFFLINE_REBOOT_DELAY_MIN"] = "offline_reboot_delay_min";
    SettingKeys["OFFLINE_REBOOT_TIME"] = "offline_reboot_time";
    SettingKeys["ORIENTATION"] = "screen_orientation";
    SettingKeys["PANEL_TURN_ON_DELAY"] = "panel_turn_on_delay";
    SettingKeys["PLAYLIST_SYNCHRONIZATION_SCREENS"] = "playlist_synchronization.screen_ids";
    SettingKeys["POWER_BUTTON_LOCK"] = "system_settings.panel_key_lock";
    SettingKeys["REBOOT_INTERVAL"] = "reboot_interval";
    SettingKeys["SCREEN_ID"] = "screen_id";
    SettingKeys["SCREEN_NAME"] = "screen_name";
    SettingKeys["SELECTIVE_FILE_SYNC"] = "selective_file_sync";
    SettingKeys["SHOP_NAME"] = "shop_name";
    SettingKeys["SHOP_ZIP_CODE"] = "shop_zip_code";
    SettingKeys["TIME_ZONE"] = "system_settings.time_zone";
})(SettingKeys || (SettingKeys = {}));
var SettingsService = /** @class */ (function () {
    function SettingsService(injector) {
        this.injector = injector;
    }
    SettingsService.prototype.get = function (key, defaultValue) {
        return _.get(this.getSyncData(), "settings." + key, defaultValue);
    };
    SettingsService.prototype.get$ = function (key, defaultValue) {
        return this._get$("settings." + key, defaultValue);
    };
    SettingsService.prototype.getActions = function () {
        return _.get(this.getSyncData(), 'actions', []);
    };
    SettingsService.prototype.getEvents = function () {
        return _.get(this.getSyncData(), 'events', []);
    };
    SettingsService.prototype.getEvent = function (id) {
        return this.getEvents().find(function (event) { return event.id === id; });
    };
    SettingsService.prototype.getContents = function () {
        return _.get(this.getSyncData(), 'contents', []);
    };
    SettingsService.prototype.getContent = function (id) {
        return this.getContents().find(function (content) { return content.id === id; });
    };
    SettingsService.prototype.getContentFileURL = function (id) {
        var fileSync = this.injector.get(FileSyncServiceBase);
        var content = this.getContent(id);
        var fileName = id + "." + (content.type === 'picture' ? 'png' : 'mp4');
        return fileSync.getContentFileURL(fileName);
    };
    SettingsService.prototype.getLayouts = function () {
        return _.get(this.getSyncData(), 'layouts', []);
    };
    SettingsService.prototype.getLayout = function (id) {
        return this.getLayouts().find(function (layout) { return layout.id === id; });
    };
    SettingsService.prototype.getLayoutAssets = function () {
        return _.get(this.getSyncData(), 'layout_assets', []);
    };
    SettingsService.prototype.getHolidays = function () {
        return _.get(this.getSyncData(), 'holidays', []);
    };
    SettingsService.prototype.getHtmlContents$ = function () {
        return this._get$('html_contents', []);
    };
    SettingsService.prototype.getHtmlContent$ = function (id) {
        return this.getHtmlContents$().pipe(map(function (contents) { return contents.find(function (content) { return content.id === id; }); }), distinctUntilChanged());
    };
    SettingsService.prototype.getHtmlTemplateAssetsForTemplate = function (id) {
        var allAssets = _.get(this.getSyncData(), 'html_template_assets', []);
        return allAssets.filter(function (asset) { return asset.template === id; });
    };
    SettingsService.prototype.getCameras$ = function () {
        return this._get$('cameras', []);
    };
    SettingsService.prototype.getWeather$ = function () {
        return this._get$('weather', []);
    };
    SettingsService.prototype._get$ = function (key, defaultValue) {
        var _this = this;
        return this.getSyncService().events.pipe(filter(function (event) {
            return event instanceof SyncConfigDownloadEvent && event.modified;
        }), map(function (event) { return _.get(event.syncData, key, defaultValue); }), 
        // Fetch current value on subscribe
        startWithFactory(function () { return _.get(_this.getSyncData(), key, defaultValue); }), distinctUntilChanged());
    };
    SettingsService.prototype.getSyncData = function () {
        return this.getSyncService().syncData;
    };
    SettingsService.prototype.getSyncService = function () {
        // We don't directly use syncService because it creates circular dependencies in some cases
        // this way the settings service can always safely be used
        return this.injector.get(SyncServiceBase);
    };
    return SettingsService;
}());
export { SettingsService };
export function startWithFactory(valueFactory) {
    return function (source) {
        return concat(defer(function () { return of(valueFactory()); }), source);
    };
}
