
        <table style="width: 100%; height: 100%">
            <tr>
                <td *ngFor="let day of days$ | async; trackBy: dayTrackByFn">
                    <siq-day-weather-portrait [weatherData]="day"
                                              [shortTemperatureFormat]="shortTemperatureFormat">
                    </siq-day-weather-portrait>
                </td>
            </tr>
        </table>
    