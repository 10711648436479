
        <div style="height: 100%;">
            <div style="display: inline-block; position: relative; top: 50%; transform: translateY(-50%);">
                <i class="fal" [ngClass]="weatherData.icon"></i>
            </div>

            <div style="display: inline-block; height: inherit; vertical-align: top;">
                <div style="position: relative; top: 50%; transform: translateY(-50%);">
                    <div>{{ weatherData.date | date:'EEEEEE dd.MM.' }}</div>

                    <div *ngIf="shortTemperatureFormat">
                        {{ weatherData.maximum_temperature | number:'1.0-0' }}°C/{{ weatherData.minimum_temperature | number:'1.0-0' }}°C
                    </div>

                    <div *ngIf="!shortTemperatureFormat">
                        {{ weatherData.maximum_temperature | number:'1.0-1' }}°C / {{ weatherData.minimum_temperature | number:'1.0-1' }}°C
                    </div>
                </div>
            </div>
        </div>
    