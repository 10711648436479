import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BootstrapServiceBase} from './bootstrap.service.base';
import {AbstractPlatform} from './platforms/platform';
import {ControllerClientServiceBase} from './shared/controller-client.service.base';
import {SettingsService} from './sync/settings.service';
import {SyncServiceBase} from './sync/sync.service.base';

@Injectable()
export class BootstrapService extends BootstrapServiceBase {

    constructor(
        sync: SyncServiceBase,
        settings: SettingsService,
        platform: AbstractPlatform,
        private router: Router,
        controllerClient: ControllerClientServiceBase,
    ) {
        super(sync, settings, platform, controllerClient);
    }

    async bootstrap(): Promise<void> {
        await super.bootstrap();
        this.logger.debug('Executing bootstrap.service.lite.ts:bootstrap()');
        await this.router.navigate(['sync']);
    }

    async onAfterFirstSync(): Promise<void> {
        await super.onAfterFirstSync();
    }

    async onConfigDownloaded(): Promise<void> {
        return;
    }
}
