/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./video-player.component";
var styles_VideoPlayerComponent = ["video[_ngcontent-%COMP%] {\n            width: 100%;\n            height: 100%;\n        }"];
var RenderType_VideoPlayerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
export function View_VideoPlayerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { videoRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["video", 1]], null, 0, "video", [], [[8, "src", 4], [4, "visibility", null]], [[null, "ended"], [null, "canplay"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ended" === en)) {
        var pd_0 = (_co.onEnded() !== false);
        ad = (pd_0 && ad);
    } if (("canplay" === en)) {
        var pd_1 = (_co.onCanplay() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoSrc; var currVal_1 = (_co.hidden ? "hidden" : "visible"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_VideoPlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-video-player", [], null, null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i0.ɵdid(1, 49152, null, 0, i1.VideoPlayerComponent, [], null, null)], null, null); }
var VideoPlayerComponentNgFactory = i0.ɵccf("siq-video-player", i1.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, {}, { completed: "completed" }, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };
