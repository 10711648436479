import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AbstractPlatform} from '../platforms/platform';
import {WebPlatform} from '../platforms/platform-web';
import {FileSyncServiceBase} from './file-sync.service.base';
import {SyncServiceBase} from './sync.service.base';

@Injectable()
export class SyncService extends SyncServiceBase {

    // Overwrite type of base class
    protected platform!: WebPlatform;
    private route: Observable<ActivatedRouteSnapshot | null>;

    constructor(http: HttpClient,
                router: Router,
                fileSync: FileSyncServiceBase,
                platform: AbstractPlatform) {
        super(http, router, fileSync, platform);

        this.route = this.router.events.pipe(
            filter((event: any) => event instanceof NavigationEnd),
            map((event: NavigationEnd) => this.router.routerState.snapshot.root.firstChild));

        this.route.subscribe(async route => {
            if (localStorage && route && route.queryParamMap) {
                const timeZone = route.queryParamMap.get('timeZone');
                if (timeZone) {
                    localStorage.setItem('timeZone', timeZone);
                }
            }
            if (route && route.paramMap) {
                const hasSecretInUrl = route.paramMap.has('secret');
                if (hasSecretInUrl) {
                    this.secret = route.paramMap.get('secret') as string;
                    await this.storeSecret();
                    await this.router.navigate(['sync'], {});
                }
            }
        });

    }

    async loadSecret(): Promise<void> {
        if (localStorage) {
            this.secret = localStorage.getItem('secret') || undefined;
        }
    }

    async storeSecret(): Promise<void> {
        if (this.secret) {
            localStorage ?
                localStorage.setItem('secret', this.secret as string)
                : this.logger.warn('cant save secret (local storage is undefined)');
            // Update secret in sentry
            Sentry.configureScope(scope => {
                scope.setTag('server_name', this.secret as string);
            });
        } else {
            if (localStorage) {
                localStorage.removeItem('secret');
            }
        }
    }

    protected async loadConfig(): Promise<any> {
        const configJson = localStorage ? localStorage.getItem('sync') : undefined;
        return configJson ? JSON.parse(configJson) : undefined;
    }

    protected async storeConfig(config: any): Promise<void> {
        localStorage ? localStorage.setItem('sync', JSON.stringify(config)) : this.logger.warn('cant save sync data (local storage is undefined)');
    }

    protected getDeviceInfoPairs(): Array<[string, () => any | Promise<any>]> {
        return [
            ...super.getDeviceInfoPairs(),
        ];
    }
}
