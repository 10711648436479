import * as tslib_1 from "tslib";
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
var ShopiqStdLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShopiqStdLayoutComponent, _super);
    function ShopiqStdLayoutComponent(settings, fileSync) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.imageRight$ = _this.getLayoutImage$('image_right');
        _this.imageBottom$ = _this.getLayoutImage$('image_bottom');
        return _this;
    }
    return ShopiqStdLayoutComponent;
}(BaseLayoutComponent));
export { ShopiqStdLayoutComponent };
