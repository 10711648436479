import {Component} from '@angular/core';
import {combineLatest, merge, Observable} from 'rxjs';
import {first, map, pluck, share, switchMap} from 'rxjs/operators';
import {WashtecClient} from '../../connect/washtec-client';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';

interface Parameters {
    iframe_url: string;
    carwash_background_color_free: string;
    carwash_background_color_busy: string;
    carwash_left_ip_address: string;
    carwash_right_ip_address: string;
}

@Component({
    selector: 'siq-washtec-news-layout',
    styles: [`
        .top {
            width: 1920px;
        }

        .float-left {
            position: relative;
            float: left;
        }

        .float-right {
            position: relative;
            float: right;
        }

        .top .float-left {
            height: 820px;
        }

        /* https://stackoverflow.com/questions/16568272/why-doesnt-the-height-of-a-container-element-increase-if-it-contains-floated-el */
        .clear:after {
            clear: both;
            content: "";
            display: table;
        }

        .bottom {
            width: 1920px;
        }

        .carwash {
            width: 50%;
            height: 260px;
        }

        siq-carwash-time-component {
            display: block;
            width: 640px;
            height: 100%;
        }
    `],
    template: `
        <div class="top clear">
            <siq-full-hd-iframe class="float-left" style="width: 1458px" [sourceUrl]="iframeUrl$ | async">
            </siq-full-hd-iframe>

            <div class="float-left" style="width: 462px">
                <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
                <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
            </div>
        </div>

        <div class="bottom clear">
            <div class="float-left carwash" [style.background-color]="colorLeft$ | async">
                <img src="assets/washtec_arrow.png" class="float-left">
                <siq-carwash-time-component class="float-right" [remainingTime]="timeLeft$ | async">
                </siq-carwash-time-component>
            </div>
            <div class="float-right carwash" [style.background-color]="colorRight$ | async">
                <img src="assets/washtec_arrow.png" class="float-right" style="-webkit-transform: rotate(180deg)">
                <siq-carwash-time-component class="float-left" [remainingTime]="timeRight$ | async">
                </siq-carwash-time-component>
            </div>
        </div>
    `,
})
export class WashtecNewsLayoutComponent extends BaseLayoutComponent {
    readonly parameters$!: Observable<Parameters>;
    readonly iframeUrl$ = this.parameters$.pipe(pluck('iframe_url'));

    readonly timeLeft$ = createCarwashRemainingTime$(this.parameters$, 'carwash_left_ip_address');
    readonly colorLeft$ = createCarwashBackgroundColor$(this.parameters$, this.timeLeft$);

    readonly timeRight$ = createCarwashRemainingTime$(this.parameters$, 'carwash_right_ip_address');
    readonly colorRight$ = createCarwashBackgroundColor$(this.parameters$, this.timeRight$);

    constructor(settings: SettingsService, fileSync: FileSyncServiceBase) {
        super(settings, fileSync);
    }
}

function createCarwashRemainingTime$(parameters$: Observable<Parameters>, ipKey: string): Observable<number> {
    return parameters$.pipe(
        pluck<Parameters, string>(ipKey),
        switchMap(ip => WashtecClient.getTime$(ip)),
        share(),
    );
}

function createCarwashBackgroundColor$(parameters$: Observable<Parameters>,
                                       time$: Observable<number>): Observable<string> {
    return merge(
        // To have earlier a bg-color.
        parameters$.pipe(first(), pluck('carwash_background_color_busy')),

        combineLatest([time$, parameters$]).pipe(
            map(([time, parameters]) =>
                time === 0 ? parameters.carwash_background_color_free : parameters.carwash_background_color_busy,
            ),
        ),
    );
}
