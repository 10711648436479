import * as msgpack from 'msgpack-lite';
import {Observable, Subject} from 'rxjs';
import {
    SMPCloseEvent,
    SMPDisconnectedException,
    SMPEvent,
    SMPMessage,
    SMPMessageEvent,
    SMPMessageType,
    SMPOpenEvent,
    SMPRemoteErrorException,
    SMPSendNotConnectedException,
} from './types';

declare const Zone: any;

export abstract class BaseTransport {
    private static RECONNECT_MAX_DELAY = 60_000;
    private static RECONNECT_INITIAL_DELAY = 1_000;
    private static CONNECTION_TIMEOUT = 15_000;
    private static PING_INTERVAL = 60_000;
    private static PING_TIMEOUT = 15_000;

    abstract get connected(): boolean;

    abstract get bufferedAmount(): number;

    get events(): Observable<SMPEvent> {
        return this.eventsSubject.asObservable();
    }

    private reconnecting = false;
    private closing = false;  // set to true when close() is called
    private reconnectDelay = BaseTransport.RECONNECT_INITIAL_DELAY;
    private connectionTimeoutTimer?: any;
    private reconnectTimer?: any;

    // The timers for scheduling and timeouting ping messages
    private pingTimer?: any;
    private pingTimeoutTimer?: any;

    // low level request management
    // every request in SMP has a request id and gets a response from the other side
    private requests = new Map<number, any>();
    private requestId = 1;

    private eventsSubject = new Subject<SMPEvent>();

    connect(): void {
        if (this.connectionTimeoutTimer !== undefined) {
            console.warn('Already connecting.');
            return;
        }

        this.connectionTimeoutTimer = setTimeout(() => {
            console.warn('Timeout while connecting. Closing connection.');
            this.connectionTimeoutTimer = undefined;
            this.forceClose();
        }, BaseTransport.CONNECTION_TIMEOUT);

        this.closing = false;
        this.connectInternal();
    }

    close(): void {
        this.closing = true;
        this.forceClose();
    }

    execute(msgType: SMPMessageType, ...args: Array<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestId = this.requestId++;
            this.requests.set(requestId, {resolve, reject});
            this.send([msgType, requestId, ...args]);
        });
    }

    abstract send(message: SMPMessage): void;

    /**
     * This method must close the underlying connection and ensure that onClose() gets called.
     * This method, unlike close(), triggers a reconnect.
     */
    abstract forceClose(): void;

    protected async onOpen(): Promise<void> {
        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;

        this.reconnectDelay = BaseTransport.RECONNECT_INITIAL_DELAY;
        this.schedulePing();

        this.eventsSubject.next(new SMPOpenEvent(this.reconnecting));
        this.reconnecting = false;
    }

    protected onClose(): void {
        this.requestId = 1;

        clearTimeout(this.connectionTimeoutTimer);
        this.connectionTimeoutTimer = undefined;
        clearTimeout(this.pingTimer);
        clearTimeout(this.pingTimeoutTimer);

        // Reject all in-flight requests (Better as leaving them hanging without any response).
        for (const {reject} of Array.from(this.requests.values())) {
            reject(new SMPDisconnectedException());
        }
        this.requests.clear();

        // Trigger a reconnect if close() wasn't called.
        if (!this.closing) {
            // close() wasn't called, try to reconnect
            this.reconnect();
        }

        this.eventsSubject.next(new SMPCloseEvent(this.closing));
    }

    protected async onMessage(message: SMPMessage): Promise<void> {
        const [msgType, requestId, ...msgArgs] = message;

        if (msgType === SMPMessageType.SUCCESS) {
            if (!this.requests.has(requestId)) {
                // logger.debug('Received success msg for non-existent request');
            } else {
                this.requests.get(requestId).resolve(msgArgs[0]);
                this.requests.delete(requestId);
            }
            return;
        }

        if (msgType === SMPMessageType.ERROR) {
            if (!this.requests.has(requestId)) {
                // logger.debug('Received error msg for non-existent request');
            } else {
                this.requests.get(requestId).reject(new SMPRemoteErrorException(msgArgs[0]));
                this.requests.delete(requestId);
            }
            return;
        }

        this.eventsSubject.next(new SMPMessageEvent(message));
    }

    protected reconnect(): void {
        if (this.reconnectTimer !== undefined) {
            console.warn('Reconnect triggered while already scheduled for reconnect.');
            return;
        }

        this.reconnecting = true;
        this.reconnectTimer = setTimeout(() => {
            this.reconnectTimer = undefined;
            this.reconnectDelay = Math.min(BaseTransport.RECONNECT_MAX_DELAY, this.reconnectDelay * 2);
            this.connect();
        }, this.reconnectDelay);
    }

    protected schedulePing(): void {
        clearTimeout(this.pingTimer);
        this.pingTimer = setTimeout(async () => {
            await this.sendPing();

            // Only continue scheduling pings if we are connected
            if (this.connected) {
                this.schedulePing();
            }
        }, BaseTransport.PING_INTERVAL);
    }

    protected async sendPing(): Promise<void> {
        try {
            const requestId = this.requestId++;
            const promise = new Promise<void>((resolve, reject) => {
                this.requests.set(requestId, {resolve, reject});
                this.send([SMPMessageType.PING, requestId]);
            });

            this.pingTimeoutTimer = setTimeout(() => {
                console.warn('Connection timed out. Closing connection.');

                const {reject} = this.requests.get(requestId);
                this.requests.delete(requestId);

                // This triggers the try catch surrounding this, which triggers forceClose()
                reject();
            }, BaseTransport.PING_TIMEOUT);

            return await promise;
        } catch (e) {
            this.forceClose();
        } finally {
            clearTimeout(this.pingTimeoutTimer);
        }
    }

    protected abstract connectInternal(): void;
}

export class WebsocketTransport extends BaseTransport {
    protected socket?: WebSocket;

    constructor(protected url: string | (() => string),
                protected readonly useJSON: boolean = false,
                protected readonly zonePatch: boolean = false) {
        super();
    }

    get connected(): boolean {
        return this.socket !== undefined && this.socket.readyState === WebSocket.OPEN;
    }

    get bufferedAmount(): number {
        return this.socket !== undefined ? this.socket.bufferedAmount : 0;
    }

    send(msg: Array<any>): void {
        if (this.socket === undefined || !this.connected) {
            throw new SMPSendNotConnectedException();
        }

        if (this.useJSON) {
            this.socket.send(JSON.stringify(msg));
        } else {
            this.socket.send(msgpack.encode(msg));
        }
    }

    forceClose(): void {
        if (this.socket !== undefined) {
            this.cleanupSocket();
        }

        this.onClose();
    }

    protected connectInternal(): void {
        const url = typeof this.url === 'string' ? this.url  : this.url();
        this.socket = new WebSocket(url);
        this.socket.binaryType = 'arraybuffer';

        const onOpen = this.onOpen.bind(this);
        const onClose = () => {
            this.cleanupSocket();
            this.onClose();
        };
        const onMessage = async (e: MessageEvent) => {
            const message = await deserializeMessage(e.data);
            await this.onMessage(message);
        };

        // We need to patch zone.js for nativescript manually
        this.socket.onopen = this.zonePatch ? Zone.current.wrap(onOpen, 'websocket-onopen') : onOpen;
        this.socket.onclose = this.zonePatch ? Zone.current.wrap(onClose, 'websocket-onclose') : onClose;
        this.socket.onmessage = this.zonePatch ? Zone.current.wrap(onMessage, 'websocket-onmessage') : onMessage;
    }

    private cleanupSocket(): void {
        if (this.socket === undefined) {
            return;
        }

        this.socket.onmessage = () => undefined;
        this.socket.onopen = () => undefined;
        this.socket.onclose = () => undefined;
        this.socket.onerror = () => undefined;
        this.socket.close();
        this.socket = undefined;
    }
}

async function deserializeMessage(msg: any): Promise<SMPMessage> {
    if (typeof msg === 'string') {
        return JSON.parse(msg);
    }

    const arrayBuffer = msg instanceof ArrayBuffer ? msg : await readBlobAsArrayBuffer(msg);
    return msgpack.decode(new Uint8Array(arrayBuffer));
}

function readBlobAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = () => reject(reader.error);
        reader.readAsArrayBuffer(blob);
    });
}
